import { useState, useContext, useEffect, useCallback} from "react";
import { AppContext } from "../../utils/stateManagement/appContext";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'; 
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import axios from "axios";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { usePalette } from "react-palette"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import style from "./mainstyle.module.scss"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from "@mui/material";
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import debounce from "lodash.debounce";
import { useAuth0 } from '@auth0/auth0-react';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { faker } from '@faker-js/faker';
// Chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "black",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  cursor: "pointer"
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  border : "1px solid #6747c7",
  borderRadius : "10px",
  backgroundColor : "#6747c796" ,
  textAlign: 'center',
  
  fontSize: "12px",
  padding: "2px",
  color: theme.palette.text.secondary,
}));

export default function AdminMain() {
  const { enqueueSnackbar } = useSnackbar();
    const AppState = useContext(AppContext)
    const [loading, setloading] = useState(true)
    const [isValid, setisValid] = useState(false)
    const [imageSelect, setimageSelect] = useState(require("../../utils/stateManagement/No-album-art.png"))
    const [loadingsubmit, setloadingsubmit] = useState(false)
    const [loadingnow, setloadingnow] = useState('')
    const [modalmainLoading, setmodalmainLoading] = useState(false)
    const [taginput, settaginput] = useState('')
    const [splittag, setsplittag] = useState([])
    const [openUploadBeat, setopenUploadBeat] = useState(false)

    const [snakbarOpen, setsnakbarOpen] = useState({
      open : false,
      color : "success",
      message : ""
    })
    const [errMessage, seterrMessage] = useState({
        status : false,
        message : ""
    })
    const [formDataT, setformData] = useState({
        beatName : "",
        beatBPM : "",
        beatTags : "",
    })
    const [beatData, setbeatData] = useState({
        taggedbeat : "",
        untaggedbeat : "",
        beatImage: ""
    })
    const [Mode, setMode] = useState(false)
    const {getAccessTokenSilently} = useAuth0();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const [beatdeleteloader, setbeatdeleteloader] = useState(false)
    const [expanded, setExpanded] = useState(false);
    const [beatlist, setbeatlist] = useState([])
    const [currentpaginate, setcurrentpaginate] = useState(0)
    const [open, setOpen] = useState(
        {
            value : false,
            beatID : ""
        }
    );
    const [Childexpanded, setChildExpanded] = useState(false);
    const [searchBeatlist, setsearchBeatlist] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [UserList, setUserList] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    
  
    // useEffect to update filteredUsers when UserList changes
    useEffect(() => {
      if (selectedRole === '') {
        setFilteredUsers(UserList); // Show all users if no role is selected
      } else {
        setFilteredUsers(UserList.filter(user => user.userRole === selectedRole));
      }
    }, [UserList, selectedRole]); // Dependency array includes selectedRole to handle role changes as well
  
    const handleRoleChange = (event) => {
      setSelectedRole(event.target.value);
    };
    const handleChildChange = (panel) => (event, isExpanded) => {
      setChildExpanded(isExpanded ? panel : false);
    };
    const handleOpen = (value) => setOpen({value : true, beatID : value});
    const handleClose = () => setOpen({value : false, beatID : ""});

    const handleEChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const Modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color: "white"
    }

    const Input = styled('input')({
        display: 'none',
      });



      const [playlistLoading, setplaylistLoading] = useState(true)
      const [playlistbeatCollection, setplaylistbeatCollection] = useState({})
      const { data, loadingimg, error } = usePalette(imageSelect)

    // useEffect(() => {
    //   console.log(data)
    //   var aColl = document.getElementsByClassName('MainContent');
    //   changeColor(aColl, data.darkVibrant)
    // }, [data])

    useEffect(() => {
      if(taginput !== "") {
        const tagsplitdet = taginput.split(", ")
        setsplittag(tagsplitdet)
      }
    }, [taginput])

    

    useEffect(() => {
      console.log("newlogs")
      // Function to fetch user data
      const fetchUserData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const formattoken = "Bearer " + token;
    
          // Make API request to retrieve user data
          const response = await axios.post(`/adminconsole/actions/listusersm`,        
          {
            authenticate: "Admin Access Request",
          },
          {
            withCredentials: true,
            credentials: 'include',
            headers: { 'Authorization': formattoken },
          }
        );
          // Set the user data in the state
          setUserList(response.data);
          console.log(response.data)
        } catch (error) {
          console.log(error);
        }
      };
    
      // Call the fetchUserData function on component mount
      fetchUserData();
    }, []);
    

    const [graphdata, setgraphdata] = useState(null)
    const [transaction, settransactions] = useState([])
    useEffect(() => {
      // Function to access the graph data
      const accessGraph = async () => {
        try {
          const token = await getAccessTokenSilently();
          const formattoken = "Bearer " + token;
    
          // Make API request to retrieve transactions data
          const response = await axios.post(
            `/adminconsole/actions/listtransactions`,
            {
              authenticate: "Admin Access Request",
            },
            {
              withCredentials: true,
              credentials: 'include',
              headers: { 'Authorization': formattoken },
            }
          );
          
          console.log(response.data);
          settransactions(response.data);
    
          const currentDate = new Date();
    
          // Generate labels array for the last 30 days
          const labels = Array.from({ length: 30 }, (_, i) => {
            const date = new Date();
            date.setDate(currentDate.getDate() - i);
            return date.toISOString().split('T')[0];
          });
    
          // Initialize an array to store transaction amounts for each label
          const pddata = Array(labels.length).fill(0);
    
          // Process transactions data and update pddata array
          response.data.reverse().forEach((transaction) => {
            const date = transaction.transaction_info.transaction_initiation_date.split('T')[0];
            const index = labels.indexOf(date);
            if (index !== -1) {
              pddata[index] += parseFloat(transaction.transaction_info.transaction_amount.value);
            } else {
              labels.unshift(date);
              pddata.unshift(parseFloat(transaction.transaction_info.transaction_amount.value));
            }
          });
    
          // Create data object for the graph
          const data = {
            labels,
            datasets: [
              {
                label: 'Transactions',
                data: pddata,
                backgroundColor: 'rgb(127, 83, 172)',
              },
            ],
          };
    
          setgraphdata(data)
        } catch (error) {
          console.log(error);
        }
      };
    
      // Call the accessGraph function on component mount
      accessGraph();
    }, []);
    

  function changeColor(coll, color){
  for(var i=0, len=coll.length; i<len; i++)
  {
      coll[i].style["background-color"] = color
  }
      
  }
  
  async function paginateData(data, pageSize = 10) {
    const reversedData = [...data].reverse();
    const paginatedData = [];
    for (let i = 0; i < reversedData.length; i += pageSize) {
        const chunk = reversedData.slice(i, i + pageSize);
        paginatedData.push(chunk);
    }
    return paginatedData;
}

const fetchDataAndPaginate = async () => {
  try {
      const data = AppState.beatContext; // Assuming this is the source of your data
      const paginatedData = await paginateData(data);
      setbeatlist(paginatedData); // Assuming setBeatList updates state with paginated data
  } catch (error) {
      console.error("Failed to paginate data:", error);
  }
};

    useEffect(() => {
      fetchDataAndPaginate();
    },[])

    useEffect(() => {

        async function AccessRequest() {
          const token = await getAccessTokenSilently();
            const formattoken = "Bearer " + token
            await axios.post( `/adminconsole/actions/authenticateadmin`, {
                authenticate : "Admin Access Request"
                }, {withCredentials: true, credentials: 'include', headers: {'Authorization' : formattoken }})
                .then(() => {
                    setisValid(true)
                    setloading(false)
                })
                .catch(() => {
                  setisValid(false)
                  setloading(false) 
                });
        }
        AccessRequest();
    }, [])

    //generate blob
    function handleChange(info) {
        console.log(info)
        setbeatData({...beatData, beatImage : info.target.files[0]})
        getBase64(info.target.files[0]).then( imageUrl =>
            setimageSelect(imageUrl)
              )
              .catch(err => {
                  console.log(err)
                  return
              })
        
      };

      function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function() {
               resolve(reader.result)
               console.log(reader.result)
            }
                reader.onerror = error => reject(error);
              })
      }
    
      async function Beatupload (value) {

        const token = await getAccessTokenSilently();
        const formattoken = "Bearer " + token
        setloadingsubmit(true)
        if (beatData.beatImage === "") {
            seterrMessage({status : true, message : "Beat Image is Required"})
            setloadingsubmit(false)
        }else{
          if (beatData.taggedbeat === "") {
            seterrMessage({status : true, message : "Tagged Beat is Required"})
            setloadingsubmit(false)
          }else{
            if(beatData.untaggedbeat === "") {
                seterrMessage({status : true, message : "UnTagged Beat is Required"})
                setloadingsubmit(false)
            } else{
                if (formDataT.beatBPM === "") {
                    seterrMessage({status : true, message : "Beat Bpm Required"})
                    setloadingsubmit(false)
                }
                else if (formDataT.beatName === "") {
                    seterrMessage({status : true, message : "Beat Name Required"})
                    setloadingsubmit(false)
                }
                else if (formDataT.beatTags === "") {
                    seterrMessage({status : true, message : "Beat Tags Required"})
                    setloadingsubmit(false)
                }
                else{
                  if (isNaN(Number(formDataT.beatPrice))) {
                    AppState.setNotification({ type: 'error', message: 'Beat price is not a number' });
                    setloadingsubmit(false)
                    return;
                  } else {
                    formDataT.beatPrice = Number(formDataT.beatPrice);
                  }
                  const regex = /^\d+\.\d{2}$/;
                  if(regex.test(formDataT.beatPrice.toString())) {
                    let formData = new FormData();
                    let newtags = formDataT.beatTags + ' ,' + formDataT.beatName + ', ' + formDataT.beatBPM + 'bpm, ' + formDataT.beatBPM
                    formData.append("beatname", formDataT.beatName);
                    formData.append('beatbpm', formDataT.beatBPM);
                    formData.append('beatPrice', formDataT.beatPrice)
                    formData.append('author', "SpaceNTime");
                    formData.append('beatimg', beatData.beatImage);
                    formData.append('beattaggedbeat', beatData.taggedbeat);
                    formData.append('beatuntaggedbeat', beatData.untaggedbeat);
                    formData.append('beattags', newtags);
                    formData.append('Beatcolor',  data.darkVibrant)
                    await axios.post( `/adminconsole/actions/adminUploadBeat`, formData, {withCredentials: true, credentials: 'include', headers: {'Content-Type': 'multipart/form-data', 'Authorization' : formattoken}})
                    .then (async (response) => {
                      window.location.reload()
                      })
                    .catch((error) => {
                      AppState.setNotification({ type: 'error', message: 'Something Went Wrong, Try Again Later.' });
                      seterrMessage({status : true, message : "Something Went Wrong, Try Again Later."})
                      setloadingsubmit(false)
                    });
                  }
                  else{
                    AppState.setNotification({ type: 'error', message: 'There should be two digits after the decimal point on price eg : 49.99' });
                    setloadingsubmit(false)
                  }

                }

            }
          }
        }
    }


    const SearchFormData = useCallback(
      debounce((value) => {
            if (value === "") {
          setMode(false);
          setsearchBeatlist([]);
        } else {
          const filteredDownloads = AppState.beatContext.filter(
            (download) =>
              download.beatname &&
              typeof download.beatname === "string" &&
              download.beatname.toLowerCase().includes(value.toLowerCase())
          );
          setMode(true);
          setsearchBeatlist(filteredDownloads);
        }
      }, 500),
      []
    );
    const [tempbtname, settempbtname] = useState("")


    // function SearchFormData(value) {
    //   if(value === "") {
    //     setMode(false)
    //   }
    //   else{
    //     setMode(true)
    //     const filteredDownloads = AppState.beatContext.filter((download) => {
    //       if (download.beatname && typeof download.beatname === "string") {
    //         return download.beatname.toLowerCase().includes(value.toLowerCase())
    //       }
    //       return false;
    //     });
    //     setsearchBeatlist(filteredDownloads)
    //   }
    // }
    const [priceChange, setpriceChange] = useState(null)
      const [price ,setPrice] = useState(0)
      const [priceLoad, setpriceLoad] = useState(false)

      const [addtoOffer, setaddtoOffer] = useState([])
      
      async function getAuthenticatedToken() {
        try {
          const token = await getAccessTokenSilently();
          return `Bearer ${token}`;
        } catch (error) {
          enqueueSnackbar("Failed to authenticate. Please try again.", { variant: 'error' });
          throw new Error("Authentication failed");
        }
      }
      
      async function updateOfferStatus(beatId, action, message) {
        const endpoint = `/adminconsole/actions/${action}`;
        let formattedToken;
    
        try {
            formattedToken = await getAuthenticatedToken();
            setaddtoOffer(prevOffers => [...prevOffers, beatId]);
    
            const response = await axios.post(endpoint, {
                data: { beatId },
            }, {
                withCredentials: true,
                credentials: 'include',
                headers: {'Authorization': formattedToken}
            });
    
            // Assuming AppState.newLoadBeats() is an async function
            
         
            enqueueSnackbar(message, { variant: 'success' });
            window.location.reload()
            setaddtoOffer(prevOffers => prevOffers.filter(id => id !== beatId));
        } catch (err) {
            // Properly handling errors from getAuthenticatedToken, axios, or newLoadBeats
            setaddtoOffer(prevOffers => prevOffers.filter(id => id !== beatId));
            const errorMessage = err.response && err.response.data ? err.response.data : 'Something went wrong, try again later.';
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    }
    

      
      async function addBeatToOffer(beatId) {
        try {
          await updateOfferStatus(beatId, "addtooffer", 'Beat added to offer successfully!');
        } catch (error) {
          console.error(error);
        }
      }
      
      async function removeBeatFromOffer(beatId) {
        try {
          await updateOfferStatus(beatId, "removefromoffer", 'Beat removed from offer successfully!');
        } catch (error) {
          console.error(error);
        }
      }
      

      async function handlePriceConfirm() {
        console.log(typeof value)
        if (typeof price !== 'number') {
          AppState.setNotification({ type: 'error', message: 'not a number' });
          return;
        }
        const regex = /^\d+\.\d{2}$/;
        if(regex.test(price.toString())) {
          setpriceLoad(true)
          const token = await getAccessTokenSilently();
          const formattoken = "Bearer " + token
          await axios.post( `/adminconsole/actions/setprice`, {
            beat : priceChange,
            price : price
            }, {withCredentials: true, credentials: 'include', headers: {'Authorization' : formattoken }})
            .then(() => {
              setpriceLoad(false)
              setpriceChange(null)
              AppState.setNotification({ type: 'success', message: 'Price Changed Successfully' });
            })
            .catch(() => {
              setpriceLoad(false)
              setpriceChange(null)
              AppState.setNotification({ type: 'error', message: 'Something Went Wrong' }); 
            });
          console.log("ok")
        }
        else{
          AppState.setNotification({ type: 'error', message: 'There should be two digits after the decimal point' });
        }
      }

    if(loading) {
        return(
            <>
            <Box sx={{ width: '100%' }}>
            <LinearProgress />
                <div className='Ldr' >
                    <h6>Loading, Please Wait...</h6>
                </div>
            </Box>
            </>
        )
    }
    else{
      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );



    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'SpaceyMusic Sales Report',
        },
      },
    };
 

      
        if(isValid) {

          const { name, nickname } = user;
          const username = nickname || name;
            return(
                <div className={style.AdminMain}>
                  
                  <Modal open={priceChange !== null} onClose={() =>setpriceChange(null)}>
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div>
            <h4 className="text-white">Change Price</h4>
            <h6 className="text-gray-400">{priceChange?.beatname}</h6>
            </div>
            <IconButton onClick={() => setpriceChange(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
  fullWidth
  margin="normal"
  type="number"
  label="New Price"
  defaultValue={priceChange?.beatPrice}
  onChange={(e) => setPrice(parseFloat(e.target.value))}
/>
          <Button variant="contained" color="primary"
           onClick={() => handlePriceConfirm()}
           disabled={priceLoad}
           >
            Confirm
          </Button>
        </Box>
      </Modal>
                <Snackbar open={snakbarOpen.open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity={snakbarOpen.color} sx={{ width: '100%' }}>
                    {snakbarOpen.message}
                  </Alert>
                </Snackbar>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 m-5">
                      <div>
                      <h1 className="text-3xl font-bold">Hello, {username}!</h1>
                    <h6 className="mt-2 font-medium">Manage Transactions and Subscriptions on SpaceyMusic Platform</h6>
                      </div>
                      <div className="flex justify-end items-center">
                      <Button onClick={() => setopenUploadBeat(true)} startIcon={<AddCircleOutlineIcon/>} variant="contained">Add New Beat</Button>
                      </div>
                    </div>
                    {errMessage.status ? <Alert severity="warning">{errMessage.message}</Alert> : ""}
                    
            <Divider>Application Activity</Divider>
            {graphdata !== null ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 m-5">
                      <div className="p-4 bg-black rounded-2xl mt-4 mb-4 max-h-[400px] min-h-[350px] flex items-center border-slate-600 border">
                      <Bar options={options} data={graphdata} />
                      </div>
                      <div className="p-4 pt-14 bg-black rounded-2xl mt-4 mb-4 relative max-h-[400px] min-h-[350px] border-slate-600 border">
                      <div  className="p-4 absolute top-0 right-0 left-0 bg-black  rounded-2xl "><h6>Recent Transactions</h6></div>
                      <div className="max-h-[320px] overflow-y-scroll bg-black ">
                      {transaction.map((item) => {
                        const date = new Date(item.transaction_info.transaction_initiation_date);
                        const formattedDate = date.toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        });

                        return(
                          <div className="bg-[#080808] p-4 flex justify-between mb-4">
                            <div>
                            <h5>{item.transaction_info.transaction_id}</h5>
                            <h6>{formattedDate}</h6>
                            </div>
                            <div className="flex items-center">
                              <h4 className="text-green-600 font-medium">{"+ " + item.transaction_info.transaction_amount.value}</h4>
                            </div>
                          </div>
                        )
                      })}
                      </div>
                      </div>
                    </div>
            ) : (
              <>
              <div className="flex items-center justify-center p-8"><CircularProgress/></div>
              </>
            )}

         <Modal
  open={openUploadBeat}
  onClose={() => setopenUploadBeat(false)}
  aria-labelledby="Upload Beat"
  aria-describedby="Upload Beat"
>
  <section className="min-h-screen flex items-center backdrop-blur-lg bg-[#0000007e] h-auto">
    <div className="bg-[#080808] container mx-auto p-8 rounded-2xl border border-slate-600 overflow-y-auto">
                      <h2 className="text-2xl font-bold text-white">New Beat {tempbtname !== "" ? (<>- {tempbtname}</>) : (<></>)}</h2>
                      <div className="mt-4 mb-4">
                      <Grid container spacing={1}>
                      {
                        splittag.length !== 0 ? (
                          splittag.map((beatName, BTID) => (
                            <Item2 className="!pt-1 !pb-1 !pr-1 !pl-1 !mr-2">{beatName}</Item2>
                          ))
                        ) : (
                          <></>
                        )
                      }
                      </Grid>
                      </div>
                      <Grid container spacing={2}>
                    <Grid item sx={{minWidth : "280px", marginTop : "40px" }} xs={1} md={4}>
                      <div className="flex flex-col items-center">
                      <img className="mb-5 rounded-md" src={imageSelect} alt="Beat Image" width={280} height={280}/>
                    <label htmlFor="SelectImagefile">
                    <Input accept="image/png"  onChange={handleChange} id="SelectImagefile" type="file" />
                    <Button variant="outlined" component="span" startIcon={<PhotoCameraBackIcon />}>
                    {beatData.beatImage.name === undefined ? "Select Image" : beatData.beatImage.name}
                    </Button>
                    </label>
                      </div>

                    </Grid>
                    <Grid item xs={6} md={8} sx={{minWidth : "280px", marginTop : "20px"}}>
                    <TextField fullWidth className="border-slate-600 border" label="Beat Name" id="beatName" variant="filled" margin="normal" onChange={(val) => {setformData({...formDataT, beatName : val.target.value}); settempbtname(val.target.value)}}/>
                    <TextField fullWidth className="border-slate-600 border" label="Beat Bpm" id="beatBpm" variant="filled" type="number" margin="normal" onChange={(val) => setformData({...formDataT, beatBPM : val.target.value})}/>
                    <TextField fullWidth className="border-slate-600 border" label="Beat Price" id="beatPrice" variant="filled" type="number" margin="normal" onChange={(val) => setformData({...formDataT, beatPrice : val.target.value})}/>
                    <TextField id="BeatTags" className="border-slate-600 border" fullWidth  label="Beat Tags" multiline rows={2} variant="filled" margin="normal"  helperText="Please Seperate tags by ', [SPACE]'" onChange={(val) => {setformData({...formDataT, beatTags : val.target.value}); settaginput(val.target.value)}}/>
                    <Stack spacing={2} className="mt-4" direction="row">
                    <label htmlFor="SelectTaggedBeat">
                    <Input accept="audio/mpeg3"  onChange={(val) =>  {setbeatData({...beatData, taggedbeat : val.target.files[0]})}} id="SelectTaggedBeat" type="file" />
                    <Button variant="outlined" component="span" startIcon={<PhotoCameraBackIcon />}>
                        {beatData.taggedbeat.name === undefined ? "Tagged Beat (Streaming)" : beatData.taggedbeat.name }
                    </Button>
                    </label>
                    <label htmlFor="SelectUnTaggedBeat">
                    <Input accept="audio/mpeg3" onChange={(val) =>  {setbeatData({...beatData, untaggedbeat : val.target.files[0]})}} id="SelectUnTaggedBeat" type="file" />
                    <Button variant="outlined" component="span" startIcon={<PhotoCameraBackIcon />}>
                        {beatData.untaggedbeat.name === undefined ? "unTagged Beat (Download)" : beatData.untaggedbeat.name } 
                    </Button>
                    </label>
                    </Stack>
                    </Grid>
                    </Grid>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" className="mt-4 w-full justify-end">
  <Button variant="outlined" onClick={() => setopenUploadBeat(false)}>Cancel</Button>
  <Button disabled={loadingsubmit} variant="contained" onClick={() => Beatupload()}>Submit Beat</Button>
</Stack>
                 
                 </div>
  </section>
</Modal>
  
                    <Accordion sx={{marginTop : "20px"}} expanded={expanded === 'panel1'} onChange={handleEChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            Manage Beats
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
        <TextField fullWidth label="Search Beat" id="beatSearch" variant="filled" onChange={(val) => SearchFormData(val.target.value)}/>
        </div>
        {Mode ? (<>
          <div className={style.BeatListWrap}>
                <List>
                {searchBeatlist.map((beat) => (
                    <>
                    <ListItem
                  secondaryAction={
                    <Stack direction="row" spacing={2}>
                    {loadingnow === beat._id ? <CircularProgress/> : <IconButton onClick={() => {delbeat(beat._id)}} color="error" aria-label="deleteBeat"><DeleteIcon /></IconButton>}
                    </Stack>
                  }
                >
                  <ListItemAvatar>
                  <Avatar variant="square" alt={beat.beatname} src={beat.beatimg}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={beat.beatname}
                  />
                </ListItem>
                <hr/>
                    </>

                ))}
                </List>
                <Modal
        open={open.value}
        onClose={handleClose}
        aria-labelledby="Playlist Selector"
        aria-describedby="Playlist Selector Description"
      >
        <Box sx={Modalstyle}>
            {addtoplmodal()}
        </Box>
      </Modal>
                <Pagination count={beatlist.length} onChange={handleChangepagination} variant="outlined" shape="rounded" />
                </div>
        </>) : (<>
          <div className={style.BeatListWrap}>
                <List>
                {beatlist[currentpaginate].map((beat) => (
                    <>
                    <ListItem
                  secondaryAction={
                    <Stack direction="row" spacing={2}>
                      <div className="flex items-center">
                        {/* Implement a MUI Radio Button */}
                        {
  addtoOffer.includes(beat._id) ? 
  <CircularProgress /> : 
  <FormControlLabel
    control={
      <Switch
      checked={!!beat.offerValid} // This should correctly convert false or undefined to false
      onChange={() => {
        if (beat.offerValid) {
          removeBeatFromOffer(beat._id);
        } else {
          addBeatToOffer(beat._id);
        }
      }}
        
        name="offerValid"
        color="primary"
      />
    }
    label="add to offer"
  />
}
                      </div>
                      <div className="flex items-center">
                      <Button variant="contained" onClick={() => {setpriceChange(beat)}} size="small">Edit Price</Button>
                      </div>
                    {loadingnow === beat._id ? <CircularProgress/> : <IconButton onClick={() => {delbeat(beat._id)}} color="error" aria-label="deleteBeat"><DeleteIcon /></IconButton>}
                    </Stack>
                  }
                >
                  <ListItemAvatar>
                  <Avatar variant="square" alt={beat.beatname} src={beat.beatimg}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={beat.beatname}
                  />
                </ListItem>
                <hr/>
                    </>

                ))}
                </List>
                <Modal
        open={open.value}
        onClose={handleClose}
        aria-labelledby="Playlist Selector"
        aria-describedby="Playlist Selector Description"
      >
        <Box sx={Modalstyle}>
            {addtoplmodal()}
        </Box>
      </Modal>
                <Pagination count={beatlist.length} onChange={handleChangepagination} variant="outlined" shape="rounded" />
                </div>
        </>)}
    
        </AccordionDetails>
      </Accordion>
      <div className="container mx-auto p-4 mt-4 border rounded-xl border-slate-900">
      <div className="flex justify-between items-center">
        <div>
          <h6 className="text-sm text-gray-400">Manage SpaceyMusic Users</h6>
          <h2 className="text-xl mt-1">User List ({filteredUsers.length})</h2>
        </div>
        <FormControl variant="outlined" size="small">
          <InputLabel id="role-select-label">Filter by Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRole}
            label="Filter by Role"
            onChange={handleRoleChange}
            style={{ minWidth: 120 }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="SPBSC1">Basic</MenuItem>
            <MenuItem value="SPSTNDRD1">Standard</MenuItem>
            <MenuItem value="SPUNLTD">Unlimited</MenuItem>
            <MenuItem value="Guest">Guest</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </div>
      {filteredUsers.length !== 0 ? (
        <>
          {filteredUsers.map((user) => {
            const date = new Date(user.createdAt);
            const formattedDate = date.toLocaleDateString('en-US');
            return (
              <div className="flex flex-col md:flex-row justify-between p-4 mt-2 mb-2 border rounded-md border-slate-700" key={user.id}>
                <div>
                  <h2 className="text-lg">UserName: {user.username}</h2>
                  <h4 className="text-xs mt-2 text-slate-400">authID: {user.authID}</h4>
                  <h4 className="text-xs mt-2 text-slate-400">email: {user.useremail}</h4>
                  <h4 className="text-xs mt-2 text-slate-400">Registered Date: {formattedDate}</h4>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
      {/* <div className="container mx-auto border border-slate-900 p-4 rounded-xl mt-4">
      <h6 className="text-sm text-gray-400">Manage SpaceyMusic Users</h6>
      <h2 className="mt-1 text-xl">User List</h2>
      {UserList.length !== 0 ? (
  <>
    {UserList.slice().reverse().map((user) => {
      const date = new Date(user.createdAt);
      const formattedDate = date.toLocaleDateString('en-US');
      return (
        <div className="p-4 border border-slate-700 rounded-md mt-2 mb-2 flex-col md:flex-row flex justify-between" key={user.id}>
          <div>
            <h2 className="text-lg">UserName : {user.username}</h2>
            <h4 className="text-xs text-slate-400 mt-2">authID : {user.authID}</h4>
            <h4 className="text-xs text-slate-400 mt-2">email : {user.useremail}</h4>
            <h4 className="text-xs text-slate-400 mt-2">Registered Date : {formattedDate}</h4>
          </div>
          <div className="flex items-center mt-4">
            {user.userRole === 'SPBSC1' ? (
              <div className="p-2 bg-violet-700 rounded-lg text-violet-400">Basic</div>
            ) : user.userRole === 'SPSTNDRD1' ? (
              <div className="p-2 bg-violet-700 rounded-lg text-violet-400">Standard</div>
            ) : user.userRole === 'SPUNLTD' ? (
              <div className="p-2 bg-violet-700 rounded-lg text-violet-400">Unlimited</div>
            ) : user.userRole === 'Guest' ? (
              <div className="p-2 bg-gray-700 rounded-lg text-gray-400">Guest</div>
            ) : user.userRole === 'Admin' ? (
              <div className="p-2 bg-green-700 rounded-lg text-green-400">Admin</div>
            ) : null}
          </div>
        </div>
      );
    })}
  </>
) : (
  <>
    <CircularProgress />
  </>
)}

    </div> */}

      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleEChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '85%', flexShrink: 0 }}>
            Manage Playlists
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
        <Button>Add New Playlist</Button>
        {AppState.PlaylistContext.map((playlist, PLID) => (
          <Accordion sx={{backgroundColor : "black"}} expanded={Childexpanded === PLID} onChange={handleChildChange(PLID)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={"Playlist" + PLID}
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {playlist.Name}
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {listbeatpl(playlist, PLID )}
          </Typography> 
        </AccordionDetails>
      </Accordion>
         ))}
                            

    </div>

        </AccordionDetails>
      </Accordion> */}
                </div>
            )
        }
        else{
            return(
                <>
                    <h1>403 Access Denied</h1>
                    <h4>Contact Administrator.</h4>
                </>
            )
        }
    }

    function listbeatpl(playlist, myid) {
      if (playlistbeatCollection.length === 0){
        return(
          <>
          No Data
          </>
        )
      }
      else{
        if(playlistLoading) {
          return(
            <div className="Ldr">
            <div className="dot-flashing"></div>
        </div>
          )
        }
        else{
          if(playlistbeatCollection[playlist._id] === undefined) {
            return(
              <div className="Ldr">
              <div className="dot-flashing"></div>
          </div>
            )
          }
          else{
            if(playlistbeatCollection[playlist._id].length === 0) {
              return(
                <>No Data</>
              )
            }
            else{
              return(
                <>
                  {playlistbeatCollection[playlist._id].map((beat, BTID) => (
                    <div className={style.beatInternal}>
          <div className={style.beatInfo}>
          <Stack direction="row" spacing={2}>
          <Avatar src={beat.beatimg} alt={beat.beatname} variant="square" />
            <div className={style.beatName}>
            <Typography noWrap gutterBottom component="div">
          {beat.beatname}
          <h5>SpaceNTime</h5>
          </Typography>
            </div>
        </Stack>
          </div>
          <div className={style.beatbpmlist}>
            <h5>{"#" + beat.beatbpm + "BPM"}</h5>
            </div>
            <div className={style.beatoplist}>
            <Stack direction="row" spacing={1}>
            {/* {likeload === beat._id ? <CircularProgress /> : <LikedSort>{beat._id}</LikedSort> } */}
          
          <Snackbar
          open={open.set}
          autoHideDuration={4000}
          onClose={handleClose}
        >
        <Alert severity={open.status}>{open.message}</Alert>
        </Snackbar>
          {/* <IconButton onClick={() => processDownload(beat._id)}  color="primary" aria-label="delete">
            <DownloadForOfflineIcon />
          </IconButton> */}
          <Button color="error" disabled={beatdeleteloader} onClick={() => rmplbeatmain(beat._id, playlist._id)}>Delete From Playlist</Button>
          </Stack>
            </div>
        </div>
                  ))}
                </>
              )
            }
          }

        }
      }
    }

    async function delbeat(beatid) {
      const token = await getAccessTokenSilently();
      const formattoken = "Bearer " + token
      
        setloadingnow(beatid)
        axios.post( `/adminconsole/actions/rmbeat`, {
          data: {
            beatId : beatid
          }
        }, {withCredentials: true, credentials: 'include', headers: {'Authorization' : formattoken }})
        .then(res => {
            setloadingnow("")
            window.location.reload()
        })
        .catch(err => {
            setloadingnow("")
            seterrMessage({status : true, message : "Something Went Wrong, Try Again Later."})
            setloadingsubmit(false)
        })
       }

       function handleChangepagination( event, value) {
        setcurrentpaginate(value - 1)
       }
       
       async function addbeatpl(data) {
        setmodalmainLoading(true)
        await axios.post( `/adminconsole/actions/addtopl`, {
          beatID : open.beatID,
          plID : data,
          }, {withCredentials: true, credentials: 'include'})
          .then(() => {
            setmodalmainLoading(false)
            setOpen({ value : false, beatID : ""})
            window.location.reload();
          })
          .catch(() => {
            setmodalmainLoading(false)
            setOpen({ value : false, beatID : ""})
            window.location.reload();
          })
       }

       function addtoplmodal() {
          if(modalmainLoading) {
            return(
              <CircularProgress/>
            )
          }
          else{
            return(
              <>
        <Typography id="Playlist Selector" variant="h6" component="h2">
          {"Select playlist to add " + open.beatID}
        </Typography>
        <Stack sx={{marginTop : "40px"}} spacing={2}>
        {AppState.PlaylistContext.map((plylst, myId) => (  
        <Item onClick={() => {addbeatpl(plylst._id)}} elevation={3}>{plylst.Name}</Item>
        ))}
         </Stack>
              </>
            )
          }
       }

       async function rmplbeatmain (beatidmbt, plidmainbt) {
         setbeatdeleteloader(true)
        await axios.post( `/adminconsole/actions/rmfropl`, {
          beatID : beatidmbt,
          plID : plidmainbt,
          }, {withCredentials: true, credentials: 'include'})
          .then(() => {
            setbeatdeleteloader(false)
          })
          .catch(() => {
            setbeatdeleteloader(false)

          })
       }
}