// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from 'react-router-dom'
// import {AppContextProvider } from './utils/stateManagement/appContext';
// import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//       <BrowserRouter>
//       <Auth0ProviderWithHistory>
//       <AppContextProvider>
//       <App />
//       </AppContextProvider>
//     </Auth0ProviderWithHistory>


//     </BrowserRouter>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './utils/stateManagement/appContext';
import { ContentProvider } from './utils/stateManagement/contentContext';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ContentProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
          </ContentProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
        <ContentProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
          </ContentProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
