import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../utils/stateManagement/appContext";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";

export default function PaymentForm({type}) {
  const AppState = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          break;
        case "processing":
          break;
        case "requires_payment_method":
          AppState.setNotification({ type: 'error', message: 'Your Card was Declined, please try a different card.' });
            console.log("Your payment was not successful, please try again.");
          break;
        default:
                    AppState.setNotification({ type: 'error', message: 'Something Went Wrong' });
            console.log("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location}/payment-complete`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      AppState.setNotification({ type: 'error', message: 'Your Card is Invlaid, please try a different card.' });
      setMessage(error.message);
    } else {
      AppState.setNotification({ type: 'error', message: 'Something Went Wrong' });
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="bg-white"> 
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      </div >
        <div className = "mt-2">
        { type === "guest" ? (
        <>
              <Button fullWidth variant="contained" disabled={isLoading || !stripe || !elements} type="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Checkout as Guest"}
        </span>
      </Button>
        </>
      ) : (
        <>
                      <Button fullWidth variant="contained" disabled={isLoading || !stripe || !elements} type="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Checkout"}
        </span>
      </Button>
        </>
      )}
        </div>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}