import Styles from "./auth.module.scss"
import Grid from '@mui/material/Grid';
import {HiCheckCircle} from "react-icons/hi"
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../utils/stateManagement/appContext';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer,
    getScriptID,
	destroySDKScript,
  } from "@paypal/react-paypal-js";
  import { useNavigate } from 'react-router-dom';
  import axios from "axios";
  import Box from '@mui/material/Box';
  import LinearProgress from '@mui/material/LinearProgress';
  import Lottie from 'react-lottie';
  import { withAuthenticationRequired } from "@auth0/auth0-react";
  import CircularProgress from '@mui/material/CircularProgress';
  import Modal from '@mui/material/Modal';
//   import { useAuth0 } from '@auth0/auth0-react';
function PlanSelect() {
    const [step, setstep] = useState(0)
    const [selectedPln, setselectedPln] = useState()
    const AppState = useContext(AppContext);
    const { user, isAuthenticated, isLoading , getAccessTokenSilently} = useAuth0();
    const [processingPayment, setprocessingPayment] = useState("")
    const date = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
    const [destroySCRIPT, setdestroySCRIPT] = useState(false)
    const [paymentprocessmodel, setpaymentprocessmodel] = useState(false)
    const [TrackingID, setTrackingID] = useState("")
const navigate = useNavigate()
    const [createSubscriptionError, setCreateSubscriptionError] = useState(null);
    const [createSubscriptionOrderID, setCreateSubscriptionOrderID] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    // const [{ isPending }] = usePayPalScriptReducer();

    useEffect(() => {
        if(selectedPln !== undefined) {
            setstep(1)
        }
        else{
            return
        }
    }, [selectedPln])

    // function reportOrderID(orderID) {
    //     setCreateSubscriptionOrderID(orderID);
    //   }

      async function handleApprove(data, actions) {
        try {
          const token = await getAccessTokenSilently();    
          const response = await axios.post(
            '/oauth/users/ProcessPayment',
            { Data: data },
            {
              withCredentials: true,
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          console.log(response.data);
          AppState.setisLoggedIn({
            ...AppState.LoggedIn,
            AccessControl: response.data.updatedRole,
          });
          AppState.updateUserFunc.then((data) => {
            navigate('/result?success');
          })
          .catch((err) => {
            navigate('/result?success');
          })
          // Navigate('/downloads')
      
        } catch (error) {
          console.error(error);
          navigate('/result?prfail');
        }
      }
    
      function handleCreateSubscription(data, actions) {
        return actions.subscription
          .create({
            plan_id: selectedPln.ID,
          });
        //   .then((subscription) => {
        //     console.log(selectedPln.ID)
        //     console.log("42342342$!@rff" , subscription)
        //     reportOrderID(subscription.id);
        //     return subscription.id;;
        //   })
        //   .catch((err) => {
        //     console.log(selectedPln.ID)
        //     console.log("23424234@" , err)
        //     setCreateSubscriptionError(err);
        //     console.error(err);
        //   });
      }
    
      function handleOnError(err) {
        console.error(err);
        navigate('/result?error');
      }
    
      function handleOnCancel() {
        navigate('/result?cancelled');
      }

    async function reportOrderID(orderID) {
        const token = await getAccessTokenSilently()
        axios.post( `/oauth/users/registerpayment`, {
          Data : {
            Type : "Report",
            TrackingID : TrackingID,
            orderID : orderID
          },
          }, { withCredentials: true, 
            credentials: 'include', 
            headers: {
              'Authorization' : `Bearer ${token}`
          }})
          .then((response) => {
            return
          })
          .catch((err) => {
            return
          })
      }
  
    
    async function createnewtransaction() {
        setpaymentprocessmodel(true)
          const token = await getAccessTokenSilently()
          axios.post( `/oauth/users/registerpayment`, {
            Data : {
              Type : "Open",
              Intent : "Subscribe",
              planID : selectedPln.ID,
              TransactionName : "Subscription Purchase - " + selectedPln.Plan
            },
            }, { withCredentials: true, 
              credentials: 'include', 
              headers: {
                'Authorization' : `Bearer ${token}`
            }})
            .then((response) => {
              setpaymentprocessmodel(false)
              setTrackingID(response.data.TrackingID)
              setprocessingPayment("Initiate")
            })
            .catch((err) => {
              console.log("requsest error")
            })
      }
    
    const ButtonWrapper = ({ type }) => {
        const navigate = useNavigate()
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    
        useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    intent: "subscription",
                },
            });
        }, [type]);

        useEffect(() => {
            if(destroySCRIPT) {
                console.log("destroying")
                destroySDKScript(getScriptID({
                    "client-id": "AYPq1IgH2-l5FVhyG6p2B1JF3Rng5C2BeV9wQXNbMQkLUgzXCcw5tyZOOS1QiRDIt206nOB8TRyvNvqD"
                    // "client-id": "AUYyf2s6mN7KHuLQ01qF2HIYDaMom3i53abavBEgUtlS1mRnGb5zZbUJ6Ulmh2uq7uWYfI_05LS7H1RS"
                }));
                dispatch({
                    type: "setLoadingStatus",
                    value: "initial",
                });
                setdestroySCRIPT(false)
                console.log("destroying Success")
            }
            else{
                return
            }
        }, [destroySCRIPT])
    
        if (createSubscriptionError) {
            return <p>Unable to create subscription. Please try again later.</p>;
          }
        return (
            <>
               <PayPalButtons
      createSubscription={handleCreateSubscription}
      onApprove={handleApprove}
      onError={handleOnError}
      onCancel={handleOnCancel}
      style={{ label: 'subscribe' }}
      disabled={isPending}
    />
            {/* {isPending ? (
                <>
                Please Wait.. We are Loading Payment Options
                </>
            ) : (
                <PayPalButtons
            createSubscription={(data, actions) => {
                return actions.subscription
                    .create({
                        plan_id: selectedPln.ID,
                    })
                    .then((orderId) => {
                        // Your code here after create the order
                        reportOrderID(orderId)
                        return orderId;
                    });
            }}
                onApprove = { async function(data, actions) {
                                            const token = await getAccessTokenSilently()
                                            axios.post( `/oauth/users/ProcessPayment`, {
                                            Data : data,
                                            }, { withCredentials: true, 
                                              credentials: 'include', 
                                              headers: {
                                                'Authorization' : `Bearer ${token}`
                                            }})
                                            .then((response) => {
                                                console.log(response.data)
                                                AppState.setisLoggedIn({...AppState.LoggedIn, AccessControl : response.data.updatedRole})
                                                // Navigate("/downloads")
                                                navigate("/result?success")
                                            })
                                            .catch((err) => {
        navigate("/result?prfail")
                                            })
                    }}
    onError = {function (err) {
      console.log(err)
        navigate("/result?error")
      }}
    onCancel = {function (err) {
        navigate("/result?cancelled")
    }}
            style={{
                label: "subscribe",
            }}
        />
            )} */}
            </>
        );
    }
    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#1A2027',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));

    useEffect(() => {

    }, [])
    if (step === 0) {
            return(
        <div className={Styles.Regintctnt}>
        <h4 className="text-2xl">Choose the plan that's right for you</h4>
        <h5  className="text-xl mt-5">Downgrade or upgrade at any time</h5>
        <Grid sx={{ flexGrow: 1, marginTop: "5vh" }} container spacing={2}>
  <Grid item xs={12}>
    <Grid container justifyContent="center" spacing={4}>
    
    {AppState.ActivePlans.map((plans, myID) => (
      <Grid key={myID} item>
          <div className="Pricing_Outlook">
            <div className={Styles.PlanCard}>
            <p className="text-xl mb-4">SpaceyMusic {plans.Plan}</p>
            <h3 className="mb-4"><span className="text-[#6747c7] font-medium">&#x24; {plans.Price}</span>/ Month</h3>
            <h5><span><HiCheckCircle/></span>{plans.DL} Beat Downloads</h5>
            <h5><span><HiCheckCircle/></span>{plans.Distribution_Copies} Distribution Copies</h5>
            {/* <h5><span><HiCheckCircle/></span>{plans.Music_Videos} Music Video</h5> */}
            <h5><span><HiCheckCircle/></span>{plans.Audio_Streams} Audio Streams</h5>
            <h5><span><HiCheckCircle/></span>{plans.Video_Streams} Video Streams</h5>
            <h5><span><HiCheckCircle/></span>Broadcasting Rights</h5>
            <h5><span><HiCheckCircle/></span>Profit Performances</h5>
            <h5 className='mb-5'><span><HiCheckCircle/></span>{plans.Radio_Stations} Radio Stations</h5>
            {plans.Recommended ? <Button variant="contained" color="success" onClick={() => {setselectedPln(plans)}} fullWidth>Select Plan</Button> : <Button variant="contained" onClick={() => {setstep(1); setselectedPln(plans)}} fullWidth>Select Plan</Button>}
            </div>
            </div>
        </Grid>
    ))}
    
    </Grid>
  </Grid>
  </Grid>
        </div>
    )
    }
    else{
        if(processingPayment === "Processing") {
            return(
                <>
                        <Box sx={{ width: '100%' }}>
          
          <div className='Ldr'>
          <div id='MainLoader' className='LoaderInternal'>
          {/*  */}
          <Lottie 
style={{ height: 250 , width: 250, marginTop: "3px"}}
  options={{
    loop: true, 
    path: 'https://assets7.lottiefiles.com/packages/lf20_gegb85kw.json',
  }}/>
              <h6>Processing Payment... Please Wait.</h6>
          </div>
          </div>
        </Box>
                </>
            )
        }
        else if(processingPayment === "Initiate") {
            return(
                <>
                                    <h3 className="text-3xl mt-8">2. Complete Payment</h3>
                <div>
                <div className="bg-black mt-5 rounded-md shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] p-4">
                <h3 className="text-xl">SpaceyMusic {selectedPln.Plan}</h3>
                <hr className="mt-5 mb-5"/>
                <div className="flex justify-between mb-5"><h5>SpaceyMusic Subscription</h5> <span>${selectedPln.Price}/m</span></div>
                <PayPalScriptProvider
                options={{
                    "client-id": "AYPq1IgH2-l5FVhyG6p2B1JF3Rng5C2BeV9wQXNbMQkLUgzXCcw5tyZOOS1QiRDIt206nOB8TRyvNvqD",
                    // "client-id": "AUYyf2s6mN7KHuLQ01qF2HIYDaMom3i53abavBEgUtlS1mRnGb5zZbUJ6Ulmh2uq7uWYfI_05LS7H1RS",

                    components: "buttons",
                    intent: "subscription",
                    vault : true
                }}
            >
            <PrintLoadingState />
                <ButtonWrapper type="subscription" />
            </PayPalScriptProvider>
            <p className="text-slate-500 text-xs">By checking out you agree with our Terms of Service. We will process your personal data for the fulfillment of your order and other purposes as per our Privacy Policy. You can cancel recurring payments at any time.</p>
                </div>
                </div>
                </>
            )
        }
        else{
            return(
                <> 
                          <Modal
  open={paymentprocessmodel}
  aria-labelledby="Payment Initiation Waiter"
  aria-describedby="New Transacton Creation"
>
      <div className=" flex justify-center items-center w-screen h-screen">
      <CircularProgress color="secondary" />
      </div>
</Modal> 
                <div className='paymentProcess'>
                <Button size="small" onClick={() => {
                    setdestroySCRIPT(true)
                    setstep(0)
                }} startIcon={<ArrowBackIcon />}>Change Plan</Button>
                <h2 className="text-4xl mt-5 font-bold">You're almost there! Complete your order</h2>
                <h3 className="text-3xl mt-8">1. Review Selected Plan</h3>
                <div className="bg-black mt-5 rounded-md shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] p-4">
                <h3 className="text-xl">SpaceyMusic {selectedPln.Plan}</h3>
                <hr className="mt-5 mb-5"/>
                <div className="flex justify-between"><h5>SpaceyMusic Subscription</h5> <span>${selectedPln.Price}/m</span></div>
                <p className="text-xs text-slate-500 mt-5">Registration renews at ${selectedPln.Price}/month on {date}</p>
                </div>
                <h3 className="text-3xl mt-8">2. Create / Review your account</h3>
                <div>
                <div className="bg-black mt-5 rounded-md shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] p-4">
                <img className='rounded-lg mt-5' src={user.picture} alt={user.email}/>
                    <h3 className='mt-5 mb-5'>Name : {user.name}</h3>
                    <h3 className='mt-5 mb-5'>Email : {user.email}</h3>
                </div>
                </div>
                <div className="mt-4 flex justify-center mb-24">
                <button className='serviceBTN' onClick={() => createnewtransaction()}>
  <span></span>
  <span></span>
  <span></span>
  <span></span> Complete Payment
</button>
                </div>
                </div>
                </>
            )
        }
    }
}

function PrintLoadingState() {
	const [{ isInitial, isPending, isResolved, isRejected }] =
		usePayPalScriptReducer();
	let status = "no status";

	if (isInitial) {
    return (
      <>
        <CircularProgress />
        <p className="mb-5">Please Wait... While we load payment options</p>
      </>
    );
	} else if (isPending) {
    return (
      <>
        <CircularProgress />
        <p className="mb-5">Please Wait... While we load payment options</p>
      </>
    );
	} else if (isResolved) {
    return (
      <>
      <p className="mb-5 text-green-400">Please use the Payment Options Below.</p>
      </>
    );
	} else if (isRejected) {
    return (
      <>
        <p className="mb-5 text-red-400">Could Not Load Payment Options. Please refresh the browser and try again later.</p>
      </>
    );
	}


}




export default withAuthenticationRequired(PlanSelect, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
  });
  