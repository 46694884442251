import {useContext, useEffect, useState} from "react"
import{ AppContext} from "../../utils/stateManagement/appContext"
import { useNavigate } from "react-router-dom";
import { deepPurple } from '@mui/material/colors';
import * as React from "react";
import Avatar from '@mui/material/Avatar';
import styles from "./mainstyle.module.scss"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Badge from '@mui/material/Badge';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import DownloadingIcon from '@mui/icons-material/Downloading';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Account() {
    const [paymentprocessmodel, setpaymentprocessmodel] = useState(false)
    const [lengthDetails, setlengthDetails] = useState({
        DownloadLength : 0,
        LikedLenght : 0,
    })
    const [snacopen, setsnacOpen] = React.useState(false);
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const AppState = useContext(AppContext)
    const Navigate = useNavigate()
    const [expanded, setExpanded] = useState(false);
    const [transactions, settransactions] = useState({
        loading : true,
        data : []
    })
    const [conformation, setconformation] = useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setsnacOpen(false);
      };

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently()
            axios.post( `/requesthandler/download/gettransactions`, {
              Intent : "list",
              }, { withCredentials: true, 
                credentials: 'include', 
                headers: {
                  'Authorization' : `Bearer ${token}`
              }})
            .then((tran) => {
                settransactions({loading : false, data : tran.data.TransactionHistory})
            })
            .catch((err) => {
                settransactions({...transactions, loading : false})
            })
          })();
        
          return () => {
            
            // this now gets called when the component unmounts
          };
    }, [])


    useEffect(() => {
        if(AppState.LoggedIn.Downloads !== undefined) {
            // setlengthDetails({...lengthDetails, DownloadLength : AppState.LoggedIn.Downloads.length})
            console.log(AppState.LoggedIn.Downloads.length)
        }
        if(AppState.LoggedIn.LikedBeats !== undefined) {
            // setlengthDetails({...lengthDetails, LikedLenght : AppState.LoggedIn.Downloads.length})
            console.log(AppState.LoggedIn.LikedBeats.length)
        }
    }, [])




useEffect(() => {
    var aColl = document.getElementsByClassName('MainContent');
    changeColor(aColl, "#080808")
}, [])

function changeColor(coll, color){
for(var i=0, len=coll.length; i<len; i++)
{
    coll[i].style["background-color"] = color
}
    
}
if (isAuthenticated) {
    return(
        <div style={{paddingBottom : "100px"}}>
                                  <Helmet>
  <title>Account | Spacey Music</title>
</Helmet>
              <Snackbar open={snacopen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Subscription Cancelled Successfully
        </Alert>
      </Snackbar>
                                  <Modal
  open={paymentprocessmodel}
  aria-labelledby="Payment Initiation Waiter"
  aria-describedby="New Transacton Creation"
>
      <div className=" flex justify-center items-center w-screen h-screen">
      <CircularProgress color="secondary" />
      </div>
</Modal>
                          <Modal
  open={conformation}
  aria-labelledby="Payment Initiation Waiter"
  aria-describedby="New Transacton Creation"
>
  <Box sx={style}>
    <h3 className="text-white mb-5 ">Are You Sure you want to cancel the Subscription</h3>
    <div className="flex justify-end">
      <Button sx={{marginRight : "10px"}} onClick={() => RunCancellationProcedure()} variant="text">Proceed</Button>
      <Button variant="contained" onClick={() => setconformation(false)}>Cancel</Button>
    </div>
  </Box>
</Modal>
            {/* <div className={styles.AccountInfo}>
            <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: deepPurple[500], width : "100px", height: "100px", fontSize : "40px", color : "white" }}>{AppState.LoggedIn.userName.charAt(0)}</Avatar>
            <div className={styles.AccountInfoTitle}>
            <Typography variant="h6" noWrap gutterBottom component="div">
                {user.name}
            </Typography>
            <Typography variant="body1" noWrap gutterBottom component="div">
            {user.email.toUpperCase()}
            </Typography>

            </div>
            </Stack>

            </div> */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 m-5">
                <div>
                <h1 className="text-4xl font-medium">Hello, {user.name}!</h1>
            <h4 className="text-xl  mt-2">View and Manage Your Spacey Music Account</h4>
                </div>
                <div className="flex justify-end items-center mt-4 md:mt-0">
                <Button startIcon={<LogoutIcon/>} onClick={() => logout({ returnTo: window.location.origin })} variant="outlined" color="error">
                    LogOut
                </Button> 
                </div>
            </div>

            <Divider>Account Details</Divider>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 m-5 mt-14 mb-10">
                <div className="flex justify-between border-r border-l border-slate-400 mb-8">
                <div className="flex ml-4">
                <div className="flex justify-center items-center">
                <span className="bg-slate-600 p-2 rounded-full">
                            <DownloadingIcon className="text-3xl"/>
                                </span>
                                </div>
                                <div className=" pl-4 items-center flex">
                    <div>
                    <h2 className="text-sm font-medium">Downloads</h2>
                <h4 >
                    {AppState.LoggedIn.Downloads.length}{" "}
                    {AppState.LoggedIn.Downloads.length === 1 ? "Download" : "Downloads"}
                </h4>
                    </div>
                </div>
                </div>
                <div>
                <div className="flex items-center  mr-4 h-full">
                <Tooltip title="View Downloads">
                <IconButton onClick={() => {Navigate("/downloads")}} aria-label="Downloads">
                    <ExitToAppIcon className="text-white"/>
                </IconButton>
                </Tooltip>
                </div>
                </div>
                </div>


                <div className="flex justify-between border-r border-l border-slate-400 mb-8">
                <div className="flex ml-4">
                <div className="flex justify-center items-center">
                <span className="bg-slate-600 p-2 rounded-full">
                            <DownloadingIcon className="text-3xl"/>
                                </span>
                                </div>
                                <div className=" pl-4 items-center flex">
                    <div>
                    <h2 className="text-sm font-medium">Plan</h2>
                <h4>
                    Spacey Music {AppState.LoggedIn.AccessControl}
                </h4>
                    </div>
                </div>
                </div>
                <div>


                <div className="flex items-center  mr-4 h-full">
                <Tooltip title="Upgrade Plan">
                <IconButton onClick={() => Navigate("/planupgrade")} aria-label="Upgrade Plan">
                    <UpgradeIcon className="text-green-600"/>
                </IconButton>
                </Tooltip>
                {
                    AppState.LoggedIn.AccessControl === "Guest" ? <></> :                 <Tooltip title="Cancel Plan">
                    <IconButton onClick={() => setconformation(true)} aria-label="Cancel Plan">
                    <CloseIcon className="text-red-600"/>
                    </IconButton>
                    </Tooltip>
                }
                </div>
                </div>
                </div>
                <div className="flex justify-between border-r border-l border-slate-400 mb-8">
                <div className="flex ml-4">
                <div className="flex justify-center items-center">
                <span className="bg-slate-600 p-2 rounded-full">
                            <DownloadingIcon className="text-3xl"/>
                                </span>
                                </div>
                                <div className=" pl-4 items-center flex">
                    <div>
                    <h2 className="text-sm font-medium">Liked Beats</h2>
                <h4>
                    {AppState.LoggedIn.LikedBeats.length}{" "}
                    {AppState.LoggedIn.LikedBeats.length === 1 ? "Beat" : "Beats"}
                </h4>
                    </div>
                </div>
                </div>
                <div>
                <div className="flex items-center  mr-4 h-full">
                <Tooltip title="View Liked">
                <IconButton onClick={() => {Navigate("/liked")}} aria-label="Liked">
                    <ExitToAppIcon className="text-white"/>
                </IconButton>
                </Tooltip>
                </div>
                </div>
                </div>
            </div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            Your Previous Transactions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <LoadTransactions/>
        </AccordionDetails>
      </Accordion>
        {AppState.LoggedIn.AccessControl === "Admin" ? <Button sx={{marginTop : "50px"}} onClick={() => Navigate("/adminspacetrumentals")} variant="contained">Admin Console</Button> : ""}
        </div>
    )
}
else {
    return(
        <>
            Authentication Failed, Please Log Out and Log In Again.
        </>
    )
}

async function RunCancellationProcedure() {
    // axios.post( `/requesthandler/download/gettransactions`, {
    //     Intent : "list",
    //     }, { withCredentials: true, 
    //       credentials: 'include', 
    //       headers: {
    //         'Authorization' : `Bearer ${token}`
    //     }})
    setconformation(false)
    setpaymentprocessmodel(true)
    const token = await getAccessTokenSilently()
    axios.post( `/oauth/users/CancelSubscription`, {
        Intent : "list",
        },
        { withCredentials: true, 
        credentials: 'include', 
        headers: {
          'Authorization' : `Bearer ${token}`
      }})
    .then((tran) => {
        setsnacOpen(true)
        setpaymentprocessmodel(false)
        setTimeout(() => {
            window.location.reload()
        }, 4000)
    })
    .catch((err) => {
        setpaymentprocessmodel(false)
        // window.location.reload()
    })
}

function PlanName() {
    console.log("planname : ", AppState.LoggedIn.AccessControl)
    if (AppState.LoggedIn.AccessControl === "SPBSC1") {
        return(
            <>
             Spacey Music Basic
             {/* <div className="flex justify-between">
             <Button startIcon={<UpgradeIcon />} onClick={() => Navigate("/planupgrade")} size="small">Upgrade</Button>
             <Button color="error" onClick={() => setconformation(true)} startIcon={<CancelIcon />} size="small">Cancel</Button>
             </div> */}
            </>
        )
      }
      else if (AppState.LoggedIn.AccessControl === "SPSTNDRD1") {
        return(
            <>
             Spacey Music Standard
            </>
        )
      }
     else if (AppState.LoggedIn.AccessControl === "SPUNLTD") {
        return(
            <>
             Spacey Music Unlimited
            </>
        )
     }
     else if (AppState.LoggedIn.AccessControl === "Guest") {
        return(
            <>
             Spacey Music Guest
            </>
        )
     }
     else if (AppState.LoggedIn.AccessControl === "Admin") {
        return(
            <>
             Spacey Music Admin
            </>
        )
     }
}

function LoadTransactions() {
    if(transactions.loading === true) {
        return(
        <>
            <div className="flex justify-center">
            <CircularProgress/>
            </div>
        </>
        )
    }
    else{
        if(transactions.data.length === 0) {
            return(
                <>
                 No Data
                </>
                )
        }
        else{
            return(
                <>
                    {transactions.data.map((obj, indx) => {
                        return(
                            <>
                                <div className="flex justify-between p-4 border border-gray-600 rounded-md mb-5 items-center">
                                    <div>
                                        <h4 className="text-sm">{obj.TransactionTitle}</h4>
                                        <h6 className="text-sm">Tracking : <span className="text-xs text-gray-600">{obj.TrackingID}</span></h6>
                                    </div>
                                    <div className="text-sm">
                                        Status : <DisplayStatus props={obj}/>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </>
            )
        }
    }
}

function DisplayStatus({props}) {
    console.log("ees", props )
    if(props.Status === "On Progress") {
        return(
            <>
                <span className="text-gray-500">On Progress</span>
            </>
        )
    }
    else if (props.Status === "Failed") {
        return(
            <>
                <span className="text-red-500">Failed</span>
            </>
        )
    }
    else if (props.Status === "Cancelled") {
        return(
            <>
                <span className="text-orange-500">Cancelled</span>
            </>
        )
    }
    else if (props.Status === "Completed") {
        return(
            <>
                <span className="text-green-500">Completed</span>
            </>
        )
    }
}
}
export default withAuthenticationRequired(Account, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
  });