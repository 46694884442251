import React, {useEffect, useState, useContext, useRef} from 'react'
import { AppContext } from '../../utils/stateManagement/appContext';
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Lottie from 'react-lottie';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import download from 'downloadjs';
import { useFormControlUnstyledContext } from '@mui/base';

function Processsingledownload() {
  const AppState = useContext(AppContext)
  const [isCodeExecuted, setisCodeExecuted] = useState(false);
    // const Navigate = useNavigate()
    // const finder = window.location.href.split("?")
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const beatID = searchParams.get("beatID");
    const transactionID = searchParams.get("transactionID");
    const TrackingID = transactionID
    const {loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [status, setstatus] = useState("Loading")
    const [downloadUrl, setdownloadUrl] = useState("")
    const [downloadFilename, setdownloadFilename] = useState("")

    //     useEffect(() => {
    //     (async () => {
    //       console.log("is details found?" + transactionID && beatID)
    //         if(transactionID && beatID) {
    //             const token = await getAccessTokenSilently()
    //             axios.post( `/requesthandler/download/getsignedlink`, {
    //               TrackingID : transactionID,
    //               beatID : beatID,
    //               }, { withCredentials: true, 
    //                 credentials: 'include', 
    //                 headers: {
    //                   'Authorization' : `Bearer ${token}`
    //               }})
    //             .then(async (signed) => {
    //               setdownloadUrl(signed.data.url)
    //               setdownloadFilename(signed.data.filename)
    //               setstatus("Processed")
    //               const link = document.createElement('a');
    //               link.href = signed.data.url;
    //               link.target = '_blank';
    //               document.body.appendChild(link);
    //               link.click();
    //               document.body.removeChild(link);
    //               AppState.updateUserFunc()
    //             })
    //             .catch((err) => {
    //               console.log(err)
    //               setstatus("Failed")
    //               // setTimeout(() => {
    //               //   Navigate("/downloads")
    //               // }, 3000)
    //             })
    //         }
    //         else{
    //             return
    //         }
    //       })();
        
    //       return () => {
            
    //         // this now gets called when the component unmounts
    //       };
    // }, [transactionID , beatID])

    useEffect(() => {
      console.log(transactionID && beatID && isCodeExecuted === false)
      if (transactionID && beatID && isCodeExecuted === false) {
        (async () => {
          console.log("is details found?" + transactionID && beatID);
          const token = await getAccessTokenSilently();
          axios
            .post(`/requesthandler/download/getsignedlink`, {
              TrackingID: transactionID,
              beatID: beatID,
            }, {
              withCredentials: true,
              credentials: 'include',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            })
            .then(async (signed) => {
              setdownloadUrl(signed.data.url);
              setdownloadFilename(signed.data.filename);
              setstatus("Processed");
              
              const initiateDownload = (url, filename) => {
                const link = document.createElement('a');
                link.href = url;
                link.download = filename || 'download';
                link.style.display = 'none';  // This makes sure the link doesn't get shown
              
                document.body.appendChild(link);
                
                // Trigger the download
                link.click();
              
                // Remove the link after a short delay, giving the browser some time to process
                setTimeout(() => {
                  document.body.removeChild(link);
                }, 100);
              };
              
              initiateDownload(signed.data.url, signed.data.filename);
              AppState.updateUserFunc();
            })
            .catch((err) => {
              console.log(err);
              setstatus("Failed");
              // setTimeout(() => {
              //   Navigate("/downloads")
              // }, 3000)
            });
  
          // Mark the code as executed to prevent further runs
          setisCodeExecuted(true)
        })();
      } else {
        return;
      }
  
      // Cleanup function when the component unmounts
      return () => {
        // You can perform cleanup tasks here if needed
      };
    }, [transactionID, beatID]);


    if(status === "Loading") {
      return(
        <div className='flex justify-center mt-10 h-[70vh] items-center'>
          Processing Request.... Please Wait
        </div>
      )
    }
    else if (status === "Processed") {
      return(
        <div>
        <div className='flex justify-center mt-10 h-[70vh] items-center'>
          Download Should start automatically, If not -  <a href={downloadUrl} target='_blank' className='text-purple-500 ml-1 cursor-pointer'> Click Here</a>
        </div>
        </div>
      )
    }
    else {
      return(
        <div>
          We Were not able to Verify the payment now, Please Try again Later, Redirecting....
        </div>
      )
    }



//     if(Token && Token.length > 10) {

//         return(
//             <>
//                              <Box sx={{ width: '100%' }}>
          
//           <div className='Ldr'>
//           <div id='MainLoader' className='LoaderInternal'>
//           {/*  */}
//           <h2>Payment Processed Successfully</h2>
//           <Lottie 
// style={{ height: 250 , width: 250, marginTop: "3px"}}
//   options={{
//     loop: true, 
//     path: 'https://assets10.lottiefiles.com/packages/lf20_EVkFabeecZ.json',
//   }}/>
//               <p>Please wait, The download will start automatically.</p>
//           </div>
//           </div>
//         </Box>
//             </>
//         )
//     }
//     else{
//         Navigate("/")
//         return (
//             <>
//                 <h2 className='text-center'>Bad Request... Redirecting.</h2>
//             </>
//           )
//     }


}

export default withAuthenticationRequired(Processsingledownload, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
  });



// 