import Container from '@mui/material/Container';
import Styles from "./auth.module.scss"
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { lightBlue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import {AppContext} from "../../utils/stateManagement/appContext"
import axios from 'axios';
import {FaGoogle, FaFacebookF} from "react-icons/fa"

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(lightBlue[100]),
    //form State 

    backgroundColor: "#eaeaea",
    '&:hover': {
      backgroundColor: "#818181",
    },
  }));
  const FBButton = styled(Button)(({ theme }) => ({
    color: "white",
    //form State 

    backgroundColor: "#4267B2",
    '&:hover': {
      backgroundColor: "#364a92",
    },
  }));

export default function Login() {
    const Navigate = useNavigate()
    const AppState = useContext(AppContext)
    const [emailAddress, setemailAddress] = useState("");
    const [password, setpassword] = useState("");
    const [loader, setloader] = useState(false);
    const [fail, setfail] = useState({
        status : false,
        message : ""
    })
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const [continuity, setcontinuity] = useState("")
    const [captchacomplete, setcaptchacomplete] = useState({
        verified : false,
        token : ""
    })
    const [validation, setvalidation] = useState({
        emailAddress : false,
        password : false,
        emailMessage : "",
        passwordMessage : "",
    })

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs";
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);

        const plmnwin = window.location.href
        console.log(plmnwin.indexOf("=?"))
        const playlistId = plmnwin.slice(plmnwin.indexOf("=?") + 2, plmnwin.length)
        console.log(playlistId)
        if(plmnwin.indexOf("=?") !== -1) {
          if(playlistId === "") {
            return
          }
          else(
            setcontinuity(playlistId)
          )
        }
        else{
          return
        }
      }, [])

      const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
          window.grecaptcha
            .execute("6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs", { action: "homepage" })
            .then(token => {
              console.log(token)
                setcaptchacomplete({
                    verified : true,
                    token : token
                })
            })
        });
      };
      
      async function formsubmit (value) {
        setloader(true)
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
          if (captchacomplete.verified === true) {
        //     setloader(false)
        //   axios.defaults.withCredentials = true;
        //   setloader(true)
        //   const datareq = {
        //     email : value.email,
        //     pass : value.password,
        //     // capchaver : captchacomplete.token,
        //   }
        //   await axios.post(`/oauth/users/gettoken`, datareq)
        //   .then ((response) => {
        //     if(response.data.req === "success") {
        //     // appstate.setuserDet012348({loggedIn: true, userName: response.data.userName, AccessControl: response.data.userRole })
        //     // const usrname = response.data.userName
        //     // const accessControl = response.data.userRole
        //     // var userdt = { 'isLoggedin': true, 'userID' : response.data.userID, 'userName': response.data.userName, 'AccessControl': response.data.userRole };
        //     // localStorage.setItem("sp_user_init", JSON.stringify(userdt));
        //     }else{
        //     //   message.error("Local Error Detected. Please LogIn Again.")
        //     }
        //     })
        //   .catch((error) => {
        //     const { response } = error;
        //     const { request, ...errorObject } = response;
        //     // window.grecaptcha.reset(recaptchaRef.current._widgetId);
        //     // setcapchacomplete({verified : false, token : ""})
        //     if (errorObject) {
        //     //   message.error(errorObject.data.message)
        //     //   setloader(false)
        //     }
        //     else{
        //     //   message.error("Internal Server Error - (50012) Please try again. ")
        //     //   setloader(false)
        //     }

        //   });
          }else {
            setfail({
                status : true,
                message : "Client Verification Failed!"
            })
          }
            //---------------------------------------------------Login Strategy Here-------------------------------------------------------//
        } else {
            setloader(false);
        }
    }

async function captureLogin(e) {
        setloader(true);
        e.preventDefault();
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target[0].value)) {
            if (captchacomplete.verified === true) {
                  setloader(true)
                  const datareq = {
                    email : e.target[0].value,
                    pass : e.target[2].value,
                    capchaver : captchacomplete.token,
                  }
                  await axios.post(`/oauth/users/gettoken`, datareq, {withCredentials: true, credentials: 'include'})
                  .then ((response) => {
                    if(response.data.req === "success") {
                      AppState.setisLoggedIn({...AppState.LoggedIn, Downloads : response.data.Downloads, loggedIn: true, userName: response.data.userName, userID : response.data.userID, AccessControl: response.data.userRole, LikedBeats: response.data.LikedBeats, OfferValid : response.data.OfferValid, isVerified : response.data.isVerified })
                      console.log(response.data)
                      var userdt = { 'isLoggedin': true, 'userID' : response.data.userID, 'userName': response.data.userName, 'AccessControl': response.data.userRole, 'LikedBeats': response.data.LikedBeats, 'DownloadBeats' : response.data.Downloads, "OfferValid" : response.data.OfferValid, "isVerified" : response.data.isVerified  };
                      localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                      setloader(false)
                      // if(continuity === "") {
                      //   Navigate("/")
                      // }
                      // else{
                        Navigate("/asdkajdnasd")
                      // }
                    }else{
                      setfail({
                        status : true,
                        message : "Something Went Wrong"
                    })
                    setloader(false)
                    }
                    })
                  .catch((error) => {
                    const { response } = error;
                    const { request, ...errorObject } = response;
                    if (errorObject) {
                      if (errorObject.data.message === "Incorrect Password"){
                        setvalidation({password : true, passwordMessage : "Password Incorrect" })
                        handleLoaded()
                        setloader(false)
                      }
                      else if (errorObject.data.message === "User Not Found") {
                        setvalidation({emailAddress : true, emailMessage : "User Does Not Exist!" })
                        handleLoaded()
                        setloader(false)
                      }
                      else if(errorObject.data.message === "Invalid Captcha") {
                        setfail({
                          status : true,
                          message : "Client Verification Failed! "
                      })
                      handleLoaded()
                      }
                      else{
                        setfail({
                          status : true,
                          message : "Internal Server Error - (50013) Please try again. "
                      })
                      handleLoaded()
                        setloader(false)
                      }
                    }
                    else{
                      setfail({
                        status : true,
                        message : "Internal Server Error - (50012) Please try again. "
                    })
                    handleLoaded()
                      setloader(false)
                    }
        
                  });
                  }else {
                    setfail({
                        status : true,
                        message : "Client Verification Failed!"
                    })
                    handleLoaded()
                    setloader(false)
                  }
        }
        else{
            setvalidation({emailAddress : true, emailMessage : "invalid Email Address" })
            setloader(false)
        }
    }
    return(
        <>
            <section className={Styles.Login} >
                <div className={Styles.LoginModuleWrapper}>
                    <h2>Log in</h2>
                    {fail.status ? <Alert severity="warning">{fail.message}</Alert> : ""}
                    <div className={Styles.Sociallogin}>
                    <ColorButton disabled startIcon={<FaGoogle />} fullWidth>Google</ColorButton>
                    <FBButton disabled fullWidth startIcon={<FaFacebookF/>}>Facebook</FBButton>
                    </div>
                    <h5><Divider>or</Divider></h5>
                    <form onSubmit={(e) => {
                        captureLogin(e)
                    }}>
                    <TextField required error={validation.emailAddress} helperText={validation.emailMessage} id="Email Field" name='Email' label="Email Address" variant="outlined" margin="normal" fullWidth />
                    <TextField required error={validation.password} helperText={validation.passwordMessage} id="Password Field" name='Password' label="Password" type="password" variant="outlined" margin="normal" fullWidth/>
                    <Button disabled={loader} variant="contained" type='submit' fullWidth>Log in</Button>
                    </form>
                    <div
    className="g-recaptcha"
    data-sitekey="6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs"
    data-size="invisible"
  ></div>
                    <Link>Forgot password?</Link>
                    <h5>Not a Subscriber? <Link onClick={() => Navigate("/register")}>Create New Account</Link> </h5>
                </div>
            </section>
        </>
    )
}