import { useContext , useState, useEffect} from "react"
import { AppContext } from "../../utils/stateManagement/appContext"
import Listbeat from "../../utils/BeatList/ListBeat";
import { Helmet } from 'react-helmet';

export default function RecentlyPlayed() {
    const AppState = useContext(AppContext);
    const WasplayingList = AppState.was;
    const BeatData = AppState.beatContext;
    const [FoundData, setFoundData] = useState([]);
    const [Loaded, setLoaded] = useState(false);

useEffect(() => {
    setFoundData([])
    const populateFoundData = async () => {
      if (WasplayingList.length === 0) {
        setLoaded(true);
        return;
      }
  
      for (const wasPlayingId of WasplayingList) {
        const foundBeat = BeatData.find((beat) => beat._id === wasPlayingId);
        if (foundBeat) {
          setFoundData((prevData) => [...prevData, foundBeat]);
        }
      }
  
      setLoaded(true);
    };
  
    populateFoundData();
  }, [BeatData, WasplayingList]);

    if(Loaded) {
        if(FoundData.length === 0) {
            return(
                <>
                <Helmet>
  <title>Recently Played | Spacey Music</title>
</Helmet>
                    No Entries Found
                </>
            )
        }
        else{
            return(<>
                          <Listbeat data={FoundData.reverse()}/>
            </>)
        }
    }
    else{
        return(
            <>
                Please Wait....
            </>
        )
    }
}