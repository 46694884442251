import { useState, useEffect, useContext } from "react"
import { AppContext } from "../../utils/stateManagement/appContext"
import { usePalette } from "react-palette"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import style from "./mainstyle.module.scss"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import SignaturePad from 'react-signature-pad-wrapper'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProcessDownload() {
  const { enqueueSnackbar } = useSnackbar();
    const [BeatLoading, setBeatLoading] = useState(true)
    const [valid, setvalid] = useState(true)
    const [theBeat, settheBeat] = useState({})
    const AppState = useContext(AppContext)
    const Navigate = useNavigate()
    const [status, setstatus] = useState("init")
    const [ldr, setldr] = useState(false)
    const [signaturePad, setSignaturePad] = useState(null);
    const [maxAccuracy, setMaxAccuracy] = useState(null);
    const [penerrsnackbar, setpenerrsnackbar] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const [downloadUrl, setdownloadUrl] = useState("")
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const reset = () => {
        signaturePad.instance.clear();
        setSignaturePad(null);
        setMaxAccuracy(null);
      };
      const { data, loadingimg, error } = usePalette(theBeat.beatimg)
      async function getImageData() { 
        return new Promise(resolve => {
          const context = document.createElement("canvas").getContext("2d");
    
          const image = new Image();
          const width = 28;
          const height = 28;
    
          image.onload = () => {
            context.drawImage(image, 0, 0, width, height);
            const imageData = context.getImageData(0, 0, width, height);
            for (let i = 0; i < imageData.data.length; i += 4) {
              const avg =
                (imageData.data[i] +
                  imageData.data[i + 1] + 
                  imageData.data[i + 2]) /
                3;
    
              imageData.data[i] = avg;
              imageData.data[i + 1] = avg;
              imageData.data[i + 2] = avg;
            }
    
            resolve(imageData);
          };
          image.src = signaturePad.toDataURL();
          var blob = dataURItoBlob(image.src);
          resolve(blob)
        });
      };
      
      function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ia], {type:mimeString});
    }

    async function dbprocess(val) {
      
      setldr(false); // Assuming setldr is a state setter for loader
      const token = await getAccessTokenSilently();
    
      setldr(true);
    
      const endpoint = AppState.LoggedIn.OfferValid ? 
                       '/requesthandler/download/offerDownload' : 
                       '/requesthandler/download/dlprocess';
    
      try {
        const response = await axios.post(endpoint, {
          beatID: theBeat._id,
        }, {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
    
        setstatus("Processed"); // Assuming this sets some state for status
        setdownloadUrl(response.data.url); // Assuming setter for download URL
    
        const link = document.createElement('a');
        link.href = response.data.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        try {
          await AppState.updateUserFunc();
          console.log("Update done"); // Consider changing to more useful logging
        } catch (err) {
          enqueueSnackbar('Failed to update user data.', { variant: 'error' });
        }
    
        const userData = await axios.get(`/oauth/users/getnewdata`, {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
    
        AppState.setisLoggedIn({
          ...AppState.LoggedIn,     
          Downloads: userData.data.DownloadsTotal,
          LikedBeats: userData.data.LikedTotal,
          OfferValid : userData.data.OfferValid,
          isVerified : userData.data.isVerified
        });
        console.log("Downloads Updated");
    
      } catch (error) {
        console.error("Error during download process:", error);    
        if (error.response?.data?.Message === "Download Limit Exceeded") {
          enqueueSnackbar('Download Limit Exceeded', { variant: 'warning' });
          setTimeout(() => {
            Navigate("/"); // Assuming Navigate is correctly imported/available
          }, 3000);
        } else {
          enqueueSnackbar('Cannot Process Download', { variant: 'warning' });
          setTimeout(() => {
            Navigate("/downloads"); // Same assumption as above
          }, 5000);
        }
      }
      finally {
        setldr(false); // Turn off loader regardless of outcome
      }
      // Old Logic
        // setldr(false)
        // const token = await getAccessTokenSilently();
        // console.log( `Bearer ${token}`)
        // setldr(true)
        // if (AppState.LoggedIn.OfferValid) {

        // }
        // else{
        //   await axios.post( `/requesthandler/download/dlprocess`, {
        //     beatID : theBeat._id,
        //     }, { withCredentials: true, 
        //       credentials: 'include', 
        //       headers: {
        //         'Authorization' : `Bearer ${token}`
        //     }})
        //   .then (async (signed) => {
        //     setstatus("Processed")
        //     setdownloadUrl(signed.data.url)
        //     // setdownloadFilename(signed.data.filename)
        //     const link = document.createElement('a');
        //     link.href = signed.data.url;
        //     link.target = '_blank';
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     AppState.updateUserFunc().then((docs) => {
        //       console.log("done")
        //     })
        //     .catch((err) => {
        //       console.log("done")
        //     })
        //       // if(response.data.Authorised === true) {
        //       //     Navigate("/processdownloadreq?" + response.data.Token)
        //       // }
        //       // else{
        //       //   setldr(false)
        //       //     setOpen(false)
        //       //     setpenerrsnackbar(true)
        //       // }
  
  
        //     const token = await getAccessTokenSilently();
        //   await axios.get( `/oauth/users/getnewdata`, 
        //   { withCredentials: true, 
        //     credentials: 'include', 
        //     headers: {
        //       'Authorization' : `Bearer ${token}`
        //   }})
        //   .then((response) =>{
        //     AppState.setisLoggedIn({
        //       ...AppState.LoggedIn,     
        //       Downloads: response.data.DownloadsTotal,
        //     })
        //     console.log("Downloads Updated")
        //   })
        //   .catch((err) => {
        //     console.log("somethingwentwrong",err)
        //   })
  
        //     })
        //     .catch((error) => {
        //       console.log(error)
         
        //       if(error.response.data.Message === "Download Limit Exceeded") {
        //         alert("Unusual downloads detected. Please wait and try again later.")
        //         setTimeout(() => {
        //           Navigate("/")
        //         }, 3000)
                
        //       }
        //       else{
        //         alert("Download Request Failed")
        //         setTimeout(() => {
        //           Navigate("/downloads")
        //         }, 3000)
        //       }
        //       //   // setldr(false)
        //       //   // setOpen(false)
        //       //   // setpenerrsnackbar(true)
        //     });
        // }


      }

      const predict = () => {
        setldr(true)
        getImageData()
          .then((data) => {
            dbprocess(data)
          })
      };

    const bxstyle = {
        position: 'absolute',
        top: '50%',
        color: "white",
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
   
        borderRadius : "20px",
        boxShadow: 24,
        p: 4,
      };
      
      useEffect(() => {
        console.log(data)
        var aColl = document.getElementsByClassName('MainContent');
        changeColor(aColl, data.darkVibrant)
      }, [data])
      
    function changeColor(coll, color){
        for(var i=0, len=coll.length; i<len; i++)
        {
            coll[i].style["background-color"] = color
        }
            
    }

    useEffect(() => {
        const URLParam = window.location.href;
        const ifurl = URLParam.includes('?')
          if (ifurl) {
            let value = URLParam.slice(URLParam.indexOf("?")+1)
            let obj = AppState.beatContext.find(o => o._id === value);
            if(obj === undefined) {
                setvalid(false)
            }
            else {
                settheBeat(obj)
            }
          }
          else{
            setvalid(false)
          }
    }, [])

    function handleCloseerrsnackbar() {
      setpenerrsnackbar(false)
    }
    useEffect(() => {
      if (Object.keys(theBeat).length === 0) {
          return;
      } else {
          setBeatLoading(false);
      }
  }, [theBeat]);

    if(BeatLoading) {
        if (valid) {
            return(
                <>
              <div className='Ldr' >
                <div className='dot-flashing'></div>
              </div>
                </>
            )
        }
        else{
            return(
                <>
              <div className='Ldr' >
                <h5>Beat Not Found</h5>
              </div>
                </>
            )
        }
    }
    else{
        if(isAuthenticated) {
           if (status === "init") {
            return(
              <>
                  <section>
              <div className={style.TitleMain}>
              <img src={theBeat.beatimg} alt="Beat Image" style={{width : "190px", height : "190px", alignSelf : "center"}}/>
              <div className={style.TitleInfod}>
              <h5 className="text-xs">Beat</h5>
              <h2 className="font-medium !mt-2 !mb-1">{theBeat.beatname}</h2>
              <h6 className="!font-bold !mb-4">{theBeat.beatbpm} Bpm</h6>
              <Stack direction="row" spacing={1}>
              <Button size="small" onClick={handleOpen} variant="contained" >
              Download
              </Button>
              <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Beat Download Request?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Please Confirm the Beat Initated Beat Download Request, A contract will be Generated and Signed when you click "Agree".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={dbprocess} autoFocus disabled={ldr} >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
              <IconButton size="small" aria-label="Play Beat" color="success">
              <PlayCircleOutlineIcon fontSize="inherit"/>
              </IconButton>
              </Stack>
              </div>
              </div>
              <h5><Divider>AGGREMENT</Divider></h5>
              <Typography className="p-4" variant="body2" gutterBottom>
                  The Beat Contract is Valid Until the User ID {user.email} Associated with you account stays active. If the User ID is Suspended, The Contract Becomes Invalid and the Use of This Resource will be Claimed.
              </Typography>
              </section>
              <Snackbar open={penerrsnackbar} autoHideDuration={6000} onClose={handleCloseerrsnackbar}>
              <Alert severity="error">Request Could Not Be Processed - Contact Support@spacetrumentals.com</Alert></Snackbar>
              </>
          )
           
        }
        else if(status === "Processed") {
          return(<>
                    <div>
        <div className='flex justify-center mt-10 h-[70vh] items-center'>
          Download Should start automatically, If not -  <a href={downloadUrl} target='_blank' className='text-purple-500 ml-1 cursor-pointer'> Click Here</a>
        </div>
        </div>
          </>)
        }
        else if(status === "Exceeded") {
          return(<>
                    <div>
        <div className='flex justify-center mt-10 h-[70vh] items-center'>
          Download Limit Reached, please upgrade Plan -  <span onClick={() => Navigate("/planupgrade")}  className='text-purple-500 ml-1 cursor-pointer'> Upgrade</span>
        </div>
        </div>
          </>)
        }
        else{
          return(
            <>
              Something Went Wrong.... Redirecting.
            </>
          )
        }
        }
        else{
            return(
                <>
              <div className='Ldr' >
                <h5>Please Wait</h5>
              </div>
                </>
            )
        }
    }


    function ButtonPlan() {
        if (ldr) {
            return(
                <>
                <div style={{marginTop : "20px"}}>
                <CircularProgress />
                </div>
                </>
            )
        }
        else{
            return(
                <Stack sx={{marginTop : "20px"}} direction="row" spacing={1}>
                <Button onClick={reset} >Clear</Button>    
                <Button variant="contained"  onClick={predict} disabled={ldr} >Process Download</Button>
                </Stack>
            )
        }
    }
}