import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../utils/stateManagement/appContext';
import searchstyles from "./mainstyle.module.scss"
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Typography from '@mui/material/Typography';
import Listbeat from "../../utils/BeatList/ListBeat";
import { Helmet } from 'react-helmet';



export default function Search() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const beatlist = useContext(AppContext).beatContext;
  const [isDiscoverLoad, setDiscoverLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const aColl = document.getElementsByClassName('MainContent');
    changeColor(aColl, '#080808');
  }, []);

  function changeColor(coll, color) {
    for (let i = 0; i < coll.length; i++) {
      coll[i].style.backgroundColor = color;
    }
  }

  useEffect(() => {
    const URLParam = window.location.href;
    const hasQueryParams = URLParam.includes('?');
    if (hasQueryParams) {
      setDiscoverLoad(true);
      const value = URLParam.slice(URLParam.indexOf('?') + 1);
      const parsedURL = value.replaceAll('%20', ' ');
      setSearchResults([]);
      setHasSearched(true);
      setIsLoading(true);
      const tempSearchResults = beatlist.filter(element => {
        return new RegExp(parsedURL, 'i').test(element.tags);
      });
      setSearchResults(tempSearchResults);
    }
  }, []);

  function handleSearch(e) {
    e.preventDefault();
    const value = e.target[0].value;
    setSearchResults([]);
    setHasSearched(true);
    setIsLoading(true);
    const tempSearchResults = beatlist.filter(element => {
      return new RegExp(value, 'i').test(element.tags);
    });
    setSearchResults(tempSearchResults);
  }

  useEffect(() => {
    if (searchResults.length === 0) {
      setIsLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setIsLoading(false);
        setDiscoverLoad(false);
        setHasSearched(true);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [searchResults]);

  return (
    <>
     <Helmet>
   <title>Search | Spacey Music</title>
   <meta name="description" content="Search and discover high-quality beats from producer SpaceNTime (@spacey6) on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
   <link rel="canonical" href="https://open.spaceymusic.com/search" />
   <meta property="og:title" content="SpaceyMusic - Search for High-Quality Beats" />
   <meta property="og:description" content="Search and discover high-quality beats on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
   <meta property="og:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
   <meta property="og:url" content="https://open.spaceymusic.com/search" />
   <meta name="twitter:card" content="summary" />
   <meta name="twitter:title" content="SpaceyMusic - Search for High-Quality Beats" />
   <meta name="twitter:description" content="Search and discover high-quality beats on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
   <meta name="twitter:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
   <script type="application/ld+json">
   {`
       {
         "@context": "http://schema.org",
        "@type": "Organization",
        "name": "SpaceyMusic",
        "url": "https://www.spaceymusic.com",
        "logo": "https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png",
        "description": "SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects.",
        "sameAs": [
          "https://twitter.com/SpaceNTime6",
          "https://www.instagram.com/spacey6",
        ]
      }
    `}
   </script>
 </Helmet>

      <Paper
        onSubmit={handleSearch}
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Spacey Music"
          inputProps={{ 'aria-label': 'search spacey music' }}
        />
        <IconButton color="primary" type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className={searchstyles.SearchResultWrap}>
        {isDiscoverLoad ? <LoaderMain /> : <Results />}
      </div>
    </>
  );

  function LoaderMain() {
    return (
      <div className={searchstyles.mn_ldr_wr}>
        <div className="dot-flashing"></div>
      </div>
    );
  }

  function Results() {
    if (!hasSearched) { // No search has been initiated yet
      if (isLoading) {
        return (
          <div className={searchstyles.mn_ldr_wr}>
            <div className="dot-flashing"></div>
          </div>
        );
      } else {
        return <div className={searchstyles.mn_err_wr}>Search Our Platform</div>;
      }
    } else {
      if (searchResults.length === 0) { // Search was done, but nothing found
        return <div className={searchstyles.mn_err_wr}>Nothing found</div>;
      } else if (isLoading) {
        return (
          <div className={searchstyles.mn_ldr_wr}>
            <div className="dot-flashing"></div>
          </div>
        );
      } else {
        return <Listbeat data={searchResults} />;
      }
    }
  }
}


// export default function Search() {
//     const [isloading, setisloading] = useState(false)
//     const appstate = useContext(AppContext);
//     const [search, setsearch] = useState([])
//     const [searchprog, setsearchprog] = useState(false)
//     const beatlist = appstate.beatContext
//     const [discoverload, setdiscoverload] = useState(false)
//     let navigate = useNavigate();

//     useEffect(() => {
//       var aColl = document.getElementsByClassName('MainContent');
//       changeColor(aColl, "#080808")
// }, [])

// function changeColor(coll, color){
//   for(var i=0, len=coll.length; i<len; i++)
//   {
//       coll[i].style["background-color"] = color
//   }
      
// }

//     useEffect(() => {
//         const URLParam = window.location.href;
//         const ifurl = URLParam.includes('?')
//           if (ifurl) {
//             setdiscoverload(true)
//             let value = URLParam.slice(URLParam.indexOf("?")+1)
//             let parseURL = value.replaceAll("%20", " ")
//             console.log(parseURL)
//             setsearch([])
//             setsearchprog(false)
//             setisloading(true)
//             const tempsearcresults = []
//             beatlist.forEach((element, index, array) => {
//                 let position = element.tags.search(eval("/" + parseURL.toLowerCase() + "/i"));
//                 if (position === -1) {return;}
//                 else{ 
//                     tempsearcresults.push(array[index])
//                 }
//             });
//             console.log(tempsearcresults)
//             setsearch(tempsearcresults)
//           }
//       }, []);

//       function onSearch (e){
//         e.preventDefault();
//         let value = e.target[0].value
//         setsearch([])
//         setsearchprog(false)
//         setisloading(true)
//         const tempsearcresults = []
//         beatlist.forEach((element, index, array) => {
//             let position = element.tags.search(eval("/" + value.toLowerCase() + "/i"));
//             if (position === -1) {return}
//             else{ 
//                 tempsearcresults.push(array[index])
//             }
//         });
//         console.log(tempsearcresults)
//         setsearch(tempsearcresults)
//     }

//     useEffect(() => {
//         if (search.length === 0) {
//             setisloading(false)
//         } else {
//             console.log(search)
//             const timeout = setTimeout(() => {
//             setisloading(false)
//             setdiscoverload(false)
//             setsearchprog(true)
//         },3000);
//         }
//       }, [search]);

//     return(
//         <>
//         <Helmet>
//   <title>Search | Spacey Music</title>
//   <meta name="description" content="Search and discover high-quality beats from producer SpaceNTime (@spacey6) on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
//   <link rel="canonical" href="https://open.spaceymusic.com/search" />
//   <meta property="og:title" content="SpaceyMusic - Search for High-Quality Beats" />
//   <meta property="og:description" content="Search and discover high-quality beats on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
//   <meta property="og:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
//   <meta property="og:url" content="https://open.spaceymusic.com/search" />
//   <meta name="twitter:card" content="summary" />
//   <meta name="twitter:title" content="SpaceyMusic - Search for High-Quality Beats" />
//   <meta name="twitter:description" content="Search and discover high-quality beats on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
//   <meta name="twitter:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
//   <script type="application/ld+json">
//   {`
//       {
//         "@context": "http://schema.org",
//         "@type": "Organization",
//         "name": "SpaceyMusic",
//         "url": "https://www.spaceymusic.com",
//         "logo": "https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png",
//         "description": "SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects.",
//         "sameAs": [
//           "https://twitter.com/SpaceNTime6",
//           "https://www.instagram.com/spacey6",
//         ]
//       }
//     `}
//   </script>
// </Helmet>
//         <Paper
//             onSubmit={onSearch}
//       component="form"
//       sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
//     >
//       <InputBase
//         sx={{ ml: 1, flex: 1 }}
//         placeholder="Search Spacey Music"
//         inputProps={{ 'aria-label': 'search google maps' }}
//       />
//       <IconButton color="primary" type="submit" sx={{ p: '10px' }} aria-label="search">
//         <SearchIcon />
//       </IconButton>
//     </Paper>
//     <div className={searchstyles.SearchResultWrap}>
//       {discoverload ? <LoaderMain/> : <Resultsnos/>}
    
//     </div>
//         </>
//     )

//     function LoaderMain() {
//       return(  
//         <div className={searchstyles.mn_ldr_wr}>
//         <div className="dot-flashing"></div>
//          </div>
//          )
//     }

//     function Resultsnos() {
//         if (!searchprog) {
//             if (isloading) { 
//             return(  
//                 <div className={searchstyles.mn_ldr_wr}>
//                 <div className="dot-flashing"></div>
//                  </div>
//                  )
//             }else{
//               return(
//                 <div className={searchstyles.mn_err_wr}>
//                 No Data
//                  </div>
//               )
//             }
//         }else{
//           if (isloading) { 
//             return(  
//                 <div className={searchstyles.mn_ldr_wr}>
//                 <div className="dot-flashing"></div>
//                  </div>
//                  )
//             }else{
//               return(
//                 <>
//                   <Listbeat data={search}/>
//                 </>
//               )
//             }
//         }
//         }
// }