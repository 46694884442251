import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import ExploreIcon from '@mui/icons-material/Explore';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import { AppContext } from "../../utils/stateManagement/appContext";
import { useAuth0 } from '@auth0/auth0-react';
import styles from "./Navigation.module.scss";

export default function DesktopNav() {
  const { beatContext, playerMounted } = useContext(AppContext);
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const menuItems = [
    { name: "Home", path: "/", icon: <HomeOutlinedIcon />, activeIcon: <HomeIcon /> },
    { name: "Discover", path: "/discover", icon: <ExploreOutlinedIcon />, activeIcon: <ExploreIcon /> },
    { name: "Search", path: "/search", icon: <SearchOutlinedIcon />, activeIcon: <SavedSearchIcon /> },
    // { name: "Featured", path: "/featured", icon: <FeaturedVideoOutlinedIcon/>, activeIcon: <FeaturedVideoIcon/> },
  ];

  const libraryItems = [
    { name: "Liked Beats", path: "/liked", icon: <FavoriteIcon /> },
    { name: "Downloads", path: "/downloads", icon: <DownloadForOfflineIcon /> },
    { name: "Recently Played", path: "/recentlyplayed", icon: <HistoryToggleOffRoundedIcon /> },
  ];

  function handleNavigate(path) {
    navigate(path);
  }


  return (
    <nav className={styles.navmn}>
      <img onClick={() => handleNavigate("/")} alt="Spacetrumentals Main Logo" src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+Long+-+White.png" width={180} />
      <h5 className="mt-8 mb-2 text-xs">MENU</h5>
      {menuItems.map((item, index) => (
        <div key={index} onClick={() => handleNavigate(item.path)} className={pathName === item.path ? styles.navactive : styles.MainNv}>
          <a>
            <span>{pathName === item.path ? item.activeIcon : item.icon}</span>
            {item.name}
          </a>
        </div>
      ))}
      <h5 className="mt-8 mb-2 text-xs">LIBRARY</h5>
      {libraryItems.map((item, index) => (
        <div key={index} onClick={() => handleNavigate(item.path)} className={pathName === item.path ? styles.navactive : styles.MainNv}>
          <a>
            <span>{item.icon}</span>
            {item.name}
          </a>
        </div>
      ))}
      {/* <div
        className={styles.bottomNav}
        style={{ marginBottom: playerMounted.try > 0 ? '100px' : '0px' }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.backgroundVideo}
        >
          <source
            src="https://videos.pexels.com/video-files/7087627/7087627-uhd_2732_1440_25fps.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className={styles.overlay}></div>
        <div className={styles.bottomNavContent}>
          <p>Join the waitlist for SpaceyMusic Producers</p>
          <Button
            sx={{ marginTop: '10px' }}
            size="small"
            variant="contained"
            fullWidth
            onClick={() => window.location.href = 'https://forms.gle/gu1Mzmi9aLKnEX658'}
          >
            Join Now!
          </Button>
        </div>
      </div> */}
    </nav>
  );
}
