// // MyContext.js
// import React, { createContext, useContext, useEffect, useState } from 'react';

// // Create a new context
// const ContentContext = createContext();

// // Create a custom hook to access the context
// export const useContent = () => {
//   return useContext(ContentContext);
// };

// // Create a provider component
// export const ContentProvider = ({ children }) => {

//   const [beatList, setbeatList] = useState([]); // Replace with your initial data
//   const [playlist, setplaylist] = useState([]); // Replace with your initial data

//   useEffect(() => {
//     console.log("New Context Refresh - " , playlist)
//   }, [playlist])
//   // Define functions or state management logic here

//   return (
//     <ContentContext.Provider 
//     value={{ 
//         beatList,
//         setbeatList,
//         playlist,
//         setplaylist,
//         }}>
//       {children}
//     </ContentContext.Provider>
//   );
// };
import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a new context
const ContentContext = createContext();

// Create a custom hook to access the context
export const useContent = () => {
  return useContext(ContentContext);
};

// Create a provider component
export const ContentProvider = ({ children }) => {
    

  const [beatList, setBeatList] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [tempplaylist, setTempPlaylist] = useState([]); // Replace with your initial data
  const [isBeatListLoaded, setIsBeatListLoaded] = useState(false);
  const [isPlaylistLoaded, setIsPlaylistLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [cachedPlaylistData, setCachedPlaylistData] = useState(null);

  useEffect(() => {
    console.log("New Context Refresh - ", tempplaylist);
    setIsPlaylistLoaded(tempplaylist.length > 0);
  }, [tempplaylist]);

  
  useEffect(() => {
    const cached = JSON.parse(localStorage.getItem('cachedImages'));
    if (cached) {
        setCachedPlaylistData(cached);
    } else {
      if(playlist.length > 0) {
        const newCacheData = tempplaylist.map((item) => {
          console.log("collectplItems", item)
          const uniqueRandomIndices = [];
          while (uniqueRandomIndices.length < 4) {
            const randIndex = Math.floor(Math.random() * item.selected.length);
            if (!uniqueRandomIndices.includes(randIndex)) {
              uniqueRandomIndices.push(randIndex);
            }
          }
          return { ...item, uniqueRandomIndices };
        });
        localStorage.setItem('cachedImages', JSON.stringify(newCacheData));
        setCachedPlaylistData(newCacheData);
      }
    }
  }, [tempplaylist]);

  useEffect(() => {
    if (playlist.length !== 0 && beatList.length !== 0) {
      const imgSet = new Set();
      const newPlaylist = playlist.reduce((acc, obj) => {
        const selected = beatList.filter((element) =>
          element.tags.search(new RegExp(obj.sortTag, "i")) !== -1
        );
        if (selected.length > 0) {
          const { _id, Name, Author, AuthorAvatar } = obj;
          acc.push({
            id: _id,
            Name,
            Author,
            AuthorAvatar,
            selected,
          });
          selected.forEach((element) => {
            imgSet.add(element.beatimg);
          });
        }
        return acc;
      }, []);
      setTempPlaylist(newPlaylist);
    }
  }, [playlist, beatList]);

  return (
    <ContentContext.Provider 
      value={{ 
        beatList,
        setBeatList,
        playlist : tempplaylist,
        setPlaylist,
        isBeatListLoaded,
        isPlaylistLoaded,
        cachedPlaylistData, 
        error
      }}>
      {children}
    </ContentContext.Provider>
  );
};
