import React, { createContext, useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { gsap } from "gsap";
import { useAuth0 } from "@auth0/auth0-react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Cookies from 'js-cookie';
//Create Context//
const AppContext = createContext();


const AppContextProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  // Lyric Pad Implementation
  const [lyricpad ,setlyricpad] = useState({
    beatInd : 0,
    state : false,
    beatID : null,
    loading : true,
    content : []
  })
  const [lyricpadObj ,setlyricpadObj] = useState()
  const [lyricpadload ,setlyricpadload] = useState(true)

  useEffect(() => {
    if(lyricpad.beatID === null){
      setlyricpadObj(null)
      setlyricpadload(true)
    }
    else{
      var result = beatlist.find(item => item._id === lyricpad.beatID)
      setlyricpadObj(result)
      setTimeout(() => {
        setlyricpadload(false)
      }, 500)
    }
  },[lyricpad])

//Initial Setup//
const [AuthEnable, setAuthEnable] = useState(false)
const [InitializeMain, setInitializeMain] = useState(true);
const [beatlist, setbeatlist] = useState([])
const [playlistCollection, setplaylist] = useState([])
const [playingfrom, setplayingfrom] = useState("Random Selection")
const [mountPlayer, setmountPlayer] = useState({
  try : 0,
  status : false
})
const [isLoggedIn, setisLoggedIn] = useState({
    loggedIn : false,
    userID : "Undefined",
    userName: "Undefined",
    userEmail: "Undefined",
    AccessControl: "Guest",
    OfferValid : false ,
    isVerified : true,
    Downloads: [],
    LikedBeats: [],
})

const [mobilePlayerDrawer, setmobilePlayerDrawer] = useState(false)
const [nowplaying, setnowplaying] = useState({
    Ind : "0",
    isPlaying : false,
    beatID : "0",
    beatname : "Null",
    beatbpm : 0,
    beatimage : require("./No-album-art.png"),
    beatpreview : "",
    beatPrice : 0,
    beatcolor : "#424242",
    tags : "sample1, sample2, "
  });
  const [beatLoadingContext, setbeatLoadingContext] = useState(true);
  const [wasplaying, setwasplaying] = useState([]);
  const [wasplayingrec, setwasplayingrec] = useState([]);
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, handleRedirectCallback } = useAuth0();

  useEffect(() => {
    console.log(wasplaying)
  }, [wasplaying])

  async function updateUserFunc() {
    console.log("Update User");
    console.log(isAuthenticated)
    if (!isAuthenticated) {
      console.log("user Not Authenticated")
      return;
    }
  
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`/oauth/users/getnewdata`, {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setisLoggedIn({
        ...isLoggedIn,
        userID: response.data._id,
        userName: response.data.username,
        userEmail: response.data.useremail,
        AccessControl: response.data.userRole,
        Downloads: response.data.DownloadsTotal,
        LikedBeats: response.data.LikedTotal,
        OfferValid : response.data.OfferValid,
        isVerified : response.data.isVerified
      });
  
      console.log("Response:", response);
      return { status: "done" };
    } catch (error) {
      console.error("Error:", error);
      return { status: "error" };
    }
  }


const [plans, setAllPlans] = useState([])

const [trackIndex, setTrackIndex] = useState(0);
const [trackProgress, setTrackProgress] = useState(0);
const audioRef = useRef(new Audio(nowplaying.beatpreview));
const intervalRef = useRef();
const isReady = useRef(false);
const { duration } = audioRef.current;
const [playlistLoad, setplaylistLoad] = useState({
  load : true,
  list : []
})
const [purchaseSelec, setpurchaseSelector] = useState()
const [purchaseMod, setpurchaseModal] = useState(false)
const handlePurchaseClose = () => {
  setpurchaseSelector(undefined)
  setpurchaseModal(false)
}
const handlePurchaseOpen = (beat, myarr) => {
  setpurchaseSelector(beat)
  setpurchaseModal(true)
}
//user Context//


//Initialization Function//

useEffect(() => {
  if (playlistCollection.length !== 0 && beatlist.length !== 0) {
    const imgSet = new Set();
    const newPlaylist = playlistCollection.reduce((acc, obj) => {
      const selected = beatlist.filter((element) =>
        element.tags.search(new RegExp(obj.sortTag, "i")) !== -1
      );
      if (selected.length > 0) {
        const { _id, Name, Author, AuthorAvatar } = obj;
        acc.push({
          id: _id,
          Name,
          Author,
          AuthorAvatar,
          selected,
        });
        selected.forEach((element) => {
          imgSet.add(element.beatimg);
        });
      }
      return acc;
    }, []);
    const imgList = [...imgSet];
    console.log(newPlaylist);
    setplaylistLoad({ load: false, list: newPlaylist });
    console.log("Everything Loaded");
  }
}, [playlistCollection, beatlist]);

useEffect(() => {
    if(localStorage.getItem("sp_user_init")){
        const storagetech = localStorage.getItem("sp_user_init");
        const DecodeTech = JSON.parse(storagetech);
        console.log(DecodeTech)
        setisLoggedIn({...isLoggedIn,  loggedIn: true, Downloads: DecodeTech.DownloadBeats ,userID: DecodeTech.userID, userName: DecodeTech.userName, AccessControl: DecodeTech.AccessControl, LikedBeats: DecodeTech.LikedBeats, OfferValid : DecodeTech.OfferValid, isVerified : DecodeTech.isVerified })
      }else{
        return
      }
},[])

async function fetchDataWithRetry(url, maxRetries = 3) {
  for (let i = 0; i < maxRetries; i++) {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(`Attempt ${i + 1} failed for ${url}: ${error}`);
        if (i === maxRetries - 1) throw error;  // Re-throw error on the last attempt
    }
}
}

async function newLoadBeats() {
  try {
    const beats = await fetchDataWithRetry('/apipath/beatlist/find');
    setbeatlist(beats.reverse());  // Assuming setBeatList is defined elsewhere and modifies state
} catch (error) {
    console.error('Failed to fetch and process beats:', error);
}
}

useEffect(() => {
 if (mountPlayer.try >= 1) {
  return
 } 
 else{
  if (mountPlayer.status === false) {
    gsap.set("#Spacetrumentals_Mbl_Plr", {y: 500, opacity: 0, autoAlpha : 0, position: "absolute"})
    gsap.set("#Msc_plr", {y: 500, opacity: 0})
  setTimeout(() => {
    if (isLoggedIn.loggedIn) {
      return
    }
    else{
      if (window.innerWidth <= 768) {
        gsap.set("#Spacetrumentals_Mbl_CTA", {autoAlpha : 1, display : "flex"})
      }
      else {
        gsap.set(".spacetrumentalsCTA", {autoAlpha : 1, display : "flex"})
      }

      
    }
  }, 2000);
  }
 }
}, [InitializeMain])

useEffect(() => {
  if (mountPlayer.try === 1) {
    gsap.to(".spacetrumentalsCTA", {autoAlpha : 0, display : "none"})
    gsap.to("#Spacetrumentals_Mbl_CTA", {autoAlpha : 0, display : "none"})
    gsap.to("#Spacetrumentals_Mbl_Plr", {y: 0, opacity: 1, autoAlpha : 1, position: "relative"})
    gsap.to("#Msc_plr", {y: 0, opacity: 1})
    
  }
  else{
    return
  }
}, [mountPlayer])

function playnext() {
  const currentIndex = beatlist.findIndex(
    (beat) => beat._id === nowplaying.beatID
  );
  
  let nextIndex = 0;
  if (currentIndex < beatlist.length - 1) {
    nextIndex = currentIndex + 1;
  }

  const nextBeat = beatlist[nextIndex];
  if (nowplaying.beatID !== "0") {
    // Add the current beat to the `wasplaying` array
    if (wasplaying.includes(nowplaying.beatID)) {
      const lastIndex = wasplaying.findIndex((entry) => entry === nowplaying.beatID);
      setwasplaying((prevWasPlaying) => [
        ...prevWasPlaying.slice(0, lastIndex),
        ...prevWasPlaying.slice(lastIndex + 1),
        nowplaying.beatID,
      ]);
    } else {
      setwasplaying((prevWasPlaying) => [...prevWasPlaying, nowplaying.beatID]);
    }
  }



  setnowplaying({
    Ind: nextIndex,
    beatID: nextBeat._id,
    beatPrice: nextBeat.beatPrice,
    beatname: nextBeat.beatname,
    beatbpm: nextBeat.beatbpm,
    beatimage: nextBeat.beatimg,
    beatpreview: nextBeat.beatpreview,
    tags: nextBeat.tags,
    beatcolor: nextBeat.color,
  });
  
  if (!audioRef.current) {
    audioRef.current = new Audio(nextBeat.beatpreview);
    audioRef.current.play()
  }
  else{
    audioRef.current.pause();
    audioRef.current = new Audio(nextBeat.beatpreview);
    audioRef.current.play()
  }

}

function EntryNow(btid) {
  if (wasplaying.includes(btid)) {
    const lastIndex = wasplaying.findIndex((entry) => entry === btid);
    setwasplaying((prevWasPlaying) => [
      ...prevWasPlaying.slice(0, lastIndex),
      ...prevWasPlaying.slice(lastIndex + 1),
      btid,
    ]);
  } else {
    setwasplaying((prevWasPlaying) => [...prevWasPlaying, btid]);
  }
  }


function playbefore() {
  if (wasplaying.length === 0) {
    return;
  } else {
    const lastIndex = wasplaying[wasplaying.length - 1];
    const beat = beatlist.find((beat) => beat._id === lastIndex);
    if (!beat) return; // Exit early if beat not found

    setnowplaying({ 
      Ind: lastIndex,
      beatID: beat._id,
      beatname: beat.beatname,
      beatbpm: beat.beatbpm,
      beatimage: beat.beatimg,
      beatpreview: beat.beatpreview,
      tags: beat.tags,
      beatcolor: beat.color
    });
    
    // Remove the last beat from the `wasplaying` array
    setwasplaying((prevWasPlaying) => prevWasPlaying.slice(0, -1));

    if (!audioRef.current) {
      audioRef.current = new Audio(beat.beatpreview);
      audioRef.current.play()
    }
    else{
      audioRef.current.pause();
      audioRef.current = new Audio(beat.beatpreview);
      audioRef.current.play()
    }

  }
}
  



  useEffect(() => {
    if (nowplaying.isPlaying && nowplaying.beatID !== "0") {
      audioRef.current.play();
      setmountPlayer(prevState => ({ ...prevState, try: prevState.try + 1 }));
    } else {
      audioRef.current.pause();
    }
  }, [nowplaying.isPlaying, nowplaying.beatID]);
  
    useEffect(() => {
      setbeatLoadingContext(true);
      // audioRef.current.pause();
      // audioRef.current = new Audio(nowplaying.beatpreview);
      setTrackProgress(audioRef.current.currentTime);
      audioRef.current.addEventListener('loadeddata', () => {
        setbeatLoadingContext(false);
      });
      if (isReady.current && nowplaying.beatID !== "0") {
        // audioRef.current.play();
        setnowplaying(prevState => ({ ...prevState, isPlaying: true }));
      } else {
        isReady.current = true;
      }
    }, [nowplaying.beatID, isReady]);


      const onScrub = (value) => {
        // Clear any timers already running
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = value;
      setTrackProgress(audioRef.current.currentTime);
      // startTimer();
    }
    
    const onScrubEnd = () => {
      // If not already playing, start
      if (!nowplaying.isPlaying) {
    
        setnowplaying({...nowplaying, isPlaying : true})
      }
      // startTimer();
    }

    const featuredcon = [ 
      {
          Name : "Do It For The Hood - Pacman Da Gunman Ft. J. Stone, GI Joe (Official Video)",
          Youtube_ID : "SbN6NNcNlaQ",
          views : "5M"
      },
      {
        Name : "Never Gon Change - Pacman Da Gunman (feat. O.T. Genasis) [Official Music Video]",
        Youtube_ID : "2J8YsMsjB0w",
        views : "3M"
      },
      {
          Name : "Steady Bangin' - Pacman Da Gunman (feat. Rayven Justice) [Official Music Video]",
          Youtube_ID : "EvwyE-zuwH0",
          views : "1.5M"
      },
      {
          Name : "Fuc You Niggaz - Pacman Da Gunman (Official Music Video)",
          Youtube_ID : "m5Eyri5VTno",
          views : "1.8M"
      },
      {
          Name : "Just Incredible - Ralfy The Plug (Feat. GoodFinesse)",
          Youtube_ID : "jVgnHkkvAVY",
          views : "50K"
      },
      {
          Name : "1800 - Young Dopey (feat. G'sta Wish & Buc) [Official Music Video]",
          Youtube_ID : "z1V100bDCdk",
          views : "800K"
      },
      {
          Name : "All Good - Pacman Da Gunman (feat. Yhung T.O.) [Official Music Video]",
          Youtube_ID : "lrvNDqijlew",
          views : "500K"
      },
      {
          Name : "Ambient Lights - Pacman Da Gunman (Official Music Video)",
          Youtube_ID : "LFzELfbQ00k",
          views : "400K"
      },
      {
          Name : "Circumstances - Pacman Da Gunman (Official Music Video)",
          Youtube_ID : "JdJ6VyaYLgU",
          views : "500K"
      },
      {
          Name : "Loyalty - Pacman Da Gunman (Official Music Video)",
          Youtube_ID : "nxDclRm1v3c",
          views : "1.4M"
      },
      {
          Name : "Gotti - Pacman Da Gunman (feat. Mozzy) [Official Audio]",
          Youtube_ID : "EHNwDTc9D38",
          views : "400K"
      },
      {
          Name : "Drama - Pacman Da Gunman & Slim 400 (feat. Gi Joe OMG) [Official Music Video]",
          Youtube_ID : "ONKUBClRuQk",
          views : "700K"
      },
  ]


  const initialCart = () => {
    const cartFromCookie = Cookies.get('cart');
    return cartFromCookie ? JSON.parse(cartFromCookie) : [];
  }

  const [cart, setCart] = useState(initialCart);

  useEffect(() => {
    // Update the cookie whenever the cart state changes
    Cookies.set('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item) => {
    let cartItem;

    cartItem = {
      id: item._id,
      image: item.beatimg,
      type: 'beat',
      name: item.beatname,
      price: item.beatPrice,
    };

    setCart((prevCart) => {
      if (!prevCart.some((cartItem) => cartItem.id === item._id)) {
        // Show a success notification
        setNotification({ type: 'success', message: 'Item added to cart successfully' });
        return [...prevCart, cartItem];
      } else {
        setNotification({ type: 'error', message: 'Item already in cart' });
        return prevCart;
      }
    });
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
    // Show a success notification
    setNotification({ type: 'success', message: 'Item removed from cart successfully' });
  };

  const clearCart = () => {
    setCart([]);
    // Show a success notification
  };


  // const [cart, setCart] = useState([]);
  // const addToCart = (item) => {
  //   let cartItem;

  //     cartItem = {
  //       id: item._id,
  //       image : item.beatimg,
  //       type: 'beat',
  //       name: item.beatname,
  //       price: item.beatPrice,
  //     };

  //   setCart((prevCart) => {
  //     if (!prevCart.some((cartItem) => cartItem.id === item._id)) {
  //       return [...prevCart, cartItem];
  //     } else {
  //       setNotification({ type: 'error', message: 'Item already in cart' });
  //       return prevCart;
  //     }
  //   });

  //   // Show a success notification
  //   setNotification({ type: 'success', message: 'Item added to cart successfully' });
  // };

  // // Function to remove an item from the cart
  // const removeFromCart = (itemId) => {
  //   setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));

  //   // Show a success notification
  //   setNotification({ type: 'success', message: 'Item removed from cart successfully' });
  // };

  // // Function to clear the cart
  // const clearCart = () => {
  //   setCart([]);

  //   // Show a success notification
  //   setNotification({ type: 'success', message: 'Cart cleared successfully' });
  // };

  // Close the notification after 3 seconds
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);
    const userContext = {
        mobilePlayer : mobilePlayerDrawer,
        InitialLoad : InitializeMain,
        AuthenticationEnabled : AuthEnable,
        beatContext : beatlist,
        PlaylistContext : playlistCollection,
        LoggedIn : isLoggedIn,
        dura : duration,
        prog: trackProgress,
        wasrec: wasplayingrec,
        was : wasplaying,
        currentBeat : nowplaying,
        beatloadingplayer : beatLoadingContext,
        ActivePlans : plans,
        featuredVids : featuredcon,
        from : playingfrom,
        LYRPad : lyricpad,
        cartCont: cart,
        addToCart,
        removeFromCart,
        clearCart,
        audioRef,
        LYRPadLoad : lyricpadload,
        LYRPadObj : lyricpadObj,
        collectpl : playlistLoad,
        playerMounted : mountPlayer,
        EntryNow,
        handlePurchaseOpen,
        handlePurchaseClose,
        setpurchaseModal,
        setpurchaseSelector,
        setNotification,
        newLoadBeats,
        notif : notification,
        purchaseSelector : purchaseSelec,
        purchaseModal : purchaseMod,
        setAllPlans,
        setlyricpadObj,
        setlyricpadload,
        setlyricpad,
        setplayingfrom,
        setmobilePlayerDrawer,
        setisLoggedIn,
        setplaylist,
        setbeatlist,
        setAuthEnable,
        setInitializeMain,
        setwasplayingrec,
        onScrub,
        onScrubEnd,
        setwasplaying,
        setnowplaying,
        playbefore,
        playnext,
        updateUserFunc,
    }

return (  
    <AppContext.Provider value={userContext}>
    <div className="App">
        {children}
    </div>
    </AppContext.Provider>
  );

}



export { AppContext, AppContextProvider };