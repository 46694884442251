import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Styles from "./auth.module.scss"
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {HiCheckCircle} from "react-icons/hi"
import { lightBlue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {FaGoogle, FaFacebookF} from "react-icons/fa"
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { AppContext } from '../../utils/stateManagement/appContext';
import { CircularProgress } from '@mui/material';

const style = {"layout":"vertical"};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(lightBlue[100]),
    //form State 

    backgroundColor: "#eaeaea",
    '&:hover': {
      backgroundColor: "#818181",
    },
  }));
  const FBButton = styled(Button)(({ theme }) => ({
    color: "white",
    //form State 

    backgroundColor: "#4267B2",
    '&:hover': {
      backgroundColor: "#364a92",
    },
  }));

  const APILINK = process.env.REACT_APP_APIDOMAIN
  
export default function Register() {
    const AppState = useContext(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const Navigate = useNavigate()
    const [emailAddress, setemailAddress] = useState("");
    const [password, setpassword] = useState("");
    const [loader, setloader] = useState(false);
    const [Selectedplan, setSelectedplan] = useState({})
    const [LoadingButton, SetLoadingButton] = useState(false)
    const [fail, setfail] = useState({
        status : false,
        message : ""
    })
    const [captchacomplete, setcaptchacomplete] = useState({
        verified : false,
        token : ""
    })
    const [validation, setvalidation] = useState({
        emailAddress : false,
        password : false,
        emailMessage : "",
        passwordMessage : "",
    })
    const [formData, setformData] = useState({
      planID : "",
      userName : "",
      userEmail : "",
      userID : "",
      userPassword : "",
      userRole : "",
      userorderID : "",
      usersubscriptionID : "",
      userFacAccessTkn : "",
      paymentMethod : ""
  })
  const ButtonWrapper = ({ type }) => {
    const appstate = useContext(AppContext)
    const navigate = useNavigate()
	const [{ options }, dispatch] = usePayPalScriptReducer();
  
	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

	return (<PayPalButtons
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: formData.userRole,
				})
				.then((orderId) => {
					// Your code here after create the order
					return orderId;
				});
		}}
            onApprove = { async function(data, actions) {
              const tempform = {
                planID: formData.planID,
                userName: formData.userName,
                userEmail: formData.userEmail,
                userID: formData.userID,
                userPassword: formData.userPassword,
                userRole: formData.userRole,
                userorderID : data.orderID,
                usersubscriptionID : data.subscriptionID,
                userFacAccessTkn : data.facilitatorAccessToken,
                paymentMethod : data.paymentSource
              }
              SetLoadingButton(true)
                setTimeout(async () => {
              await axios.post(`/oauth/users/Register`, tempform)
            .then ((response) => {
              AppState.setisLoggedIn({...AppState.LoggedIn, Downloads : response.data.Downloads, loggedIn: true, userName: response.data.userName, userID : response.data.userID, AccessControl: response.data.userRole, LikedBeats: response.data.LikedBeats, OfferValid : response.data.OfferValid, isVerified : response.data.isVerified })
                      console.log(response.data)
                      var userdt = { 'isLoggedin': true, 'userID' : response.data.userID, 'userName': response.data.userName, 'AccessControl': response.data.userRole, 'LikedBeats': response.data.LikedBeats, 'DownloadBeats' : response.data.Downloads, "OfferValid" : response.data.OfferValid, "isVerified" : response.data.isVerified  };
                      localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                      setloader(false)
                      AppState.updateUserFunc().then((data) => {
                        Navigate("/result?success")
                      })
                      .catch((Err) => {
                        Navigate("/result?success")
                      })

              })
            .catch((error) => {
              console.log(error)
              navigate("/result?error")
            })
                },5000)
                }}
onError = {function (err) {
  console.log(err)
    navigate("/result?error")
  }}
onCancel = {function (err) {
    navigate("/result?cancelled")
}}
		style={{
			label: "subscribe",
		}}
	/>);
}


    useEffect(() => {
      // Add reCaptcha
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs";
      script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);
    }, [])

    const handleLoaded = _ => {
      window.grecaptcha.ready(_ => {
        
        window.grecaptcha
          .execute("6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs", { action: "homepage" })
          .then(token => {
              setcaptchacomplete({
                  verified : true,
                  token : token
              })
          })
      });
    };
    

    
    const handleNext = (val) => {
      setActiveStep(val);
    };
  
    const handleBack = (val) => {
      setActiveStep(val);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    return(
        <div className={Styles.RegModuleWrapper}>
    <Box >
    {fail.status ? <Alert severity="error">{fail.message}</Alert> : ""}
      <Stepper activeStep={activeStep} orientation="vertical">

          <Step key={0}>
            <StepLabel>
              Plan Selection
            </StepLabel>
  
            <StepContent>
            <div className={Styles.Regintctnt}>
            <h4 className='text-3xl'>Choose the plan that's right for you</h4>
            <h5 className='text-2xl'>Downgrade or upgrade at any time</h5>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4}>
        
        {AppState.ActivePlans.map((plans, myID) => (
          <Grid key={myID} item>
              <Paper
              variant="outlined"
              levation={3}
                sx={{
                  height: 500,
                  width: 250,
                  backgroundColor: '#080808'
                }}> 
                <div className={Styles.PlanCard}>
                <p>SpaceyMusic {plans.Plan}</p>
                <h3><span>&#x24; {plans.Price}</span>/Month</h3>
                <h5><span><HiCheckCircle/></span>{plans.Distribution_Copies} Distribution Copies</h5>
                <h5><span><HiCheckCircle/></span>{plans.Music_Videos} Music Video</h5>
                <h5><span><HiCheckCircle/></span>{plans.Audio_Streams} Audio Streams</h5>
                <h5><span><HiCheckCircle/></span>{plans.Video_Streams} Video Streams</h5>
                <h5><span><HiCheckCircle/></span>Broadcasting Rights</h5>
                <h5><span><HiCheckCircle/></span>Profit Performances</h5>
                <h5><span><HiCheckCircle/></span>{plans.Radio_Stations} Radio Stations</h5>
                {plans.Recommended ? <Button variant="contained" color="success"   onClick={() => {setformData({...formData, userRole :  plans.ID, planID: plans.ID}); setSelectedplan(plans); handleNext(1)}} fullWidth>Select Plan</Button> : <Button variant="contained"  onClick={() => {setformData({...formData, userRole :  plans.ID}); setSelectedplan(plans); handleNext(1)}} fullWidth>Select Plan</Button>}
                </div>
                </Paper>
            </Grid>
        ))}
        
        </Grid>
      </Grid>
      </Grid>
            </div>
            </StepContent>
          </Step>
          <Step key={1}>
            <StepLabel>
              Account Creation
            </StepLabel>
            <StepContent>
            <div className={Styles.Regintctnt}>
            <Button onClick={() => handleBack(0)}  size="small">Back</Button>
                <section className={Styles.LoginModuleWrapperregwrp}>
                <div className={Styles.LoginModuleWrapperreg}>
                    <h2>Create Account</h2>
                    <div className={Styles.Sociallogin}>
                    <ColorButton disabled startIcon={<FaGoogle />} fullWidth>Google</ColorButton>
                    <FBButton disabled fullWidth startIcon={<FaFacebookF/>}>Facebook</FBButton>
                    </div>
                    <h5><Divider>or</Divider></h5>
                    <form onSubmit={(e) => {
                        captureRegistration(e)
                    }}>
                    <TextField required error={validation.emailAddress} helperText={validation.emailMessage} id="Email Field" name='Email' label="Email Address" variant="outlined" margin="normal" fullWidth />
                    <TextField required id="User Name" name='UserName' label="User Name" variant="outlined" margin="normal" fullWidth />
                    <TextField required error={validation.password} helperText={validation.passwordMessage} id="Password Field" name='Password' label="Password" type="password" variant="outlined" margin="normal" fullWidth/>
                    <TextField required error={validation.password} helperText={validation.passwordMessage} id="Password Confirm" name='PasswordConf' label="Confirm Password" type="password" variant="outlined" margin="normal" fullWidth/>
                    <Button disabled={loader} variant="contained" type='submit' fullWidth>Create Account</Button>
                    </form>
                    <div
    className="g-recaptcha"
    data-sitekey="6Lf-YvAaAAAAAGmTwepuBsUeRD42gEasA-bcbdvs"
    data-size="invisible"
  ></div>
                </div>
                </section>
                </div>
            </StepContent>

 
          </Step>
          <Step key={2}>
            <StepLabel>
              Set up Payment
            </StepLabel>
            <StepContent>
            <div className={Styles.Regintctnt}>
                    <div className={Styles.PaymentProcess}>
                    <h4><span>User Name:</span> {formData.userName}</h4>
                    <h4><span>User Email:</span> {formData.userEmail}</h4>
                    <h4><span>User ID:</span> {formData.userID}</h4>
                    <h4><span>Plan:</span> Spacetrumenals {Selectedplan.Plan}</h4>
                    <h4><span>Price:</span> &#x24; {Selectedplan.Price}</h4>
                    <PayPalScriptProvider
			options={{
				"client-id": "AUYyf2s6mN7KHuLQ01qF2HIYDaMom3i53abavBEgUtlS1mRnGb5zZbUJ6Ulmh2uq7uWYfI_05LS7H1RS",

				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			{LoadingButton ? <CircularProgress/> :<ButtonWrapper type="subscription" />}
		</PayPalScriptProvider>
                    </div>
                </div>
            </StepContent>
          </Step>
      </Stepper>
    </Box>
        </div>
    )

  async function captureRegistration(value) {
    value.preventDefault();
    console.log(value.target)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.target[0].value)) {
      if (captchacomplete.verified === true) {
        console.log(captchacomplete.token)
            axios.defaults.withCredentials = true;
            setloader(true)
            if(value.target[4].value.length >= 6) {
              if(value.target[4].value === value.target[6].value ){
                const emailadd =  value.target[0].value.toLowerCase();
                const datareq = {
                  email : emailadd,
                  capchaver : captchacomplete.token,
                }
                await axios.post(`/oauth/users/verifyemail`, datareq)
                .then ((response) => {
                  setcaptchacomplete({verified : false, token : ""})
                  if (response.data.Status === "valid") {
                    // setVerify(false)
                    const newID = "sp-"+Date.now().toString(36) + "hsh" + Math.random().toString(36).substr(2) 
                    setformData({...formData, userName : value.target[2].value, userEmail : value.target[0].value, userID : newID, userPassword : value.target[4].value})
                    setloader(false)
                    setActiveStep(2)
                    // setusrID(newID)
                    // message.success("User ID Successfully Generated")
                    // appstate.setRegData({...appstate.reg, userEmail : emailadd, userID : newID})
                    // setmodule("Step2")
                  }
                  else{
                    setvalidation({emailAddress : true, emailMessage : "Email Already Exists" })
                    setloader(false)
                    // setVerify(false)
                    // message.error(response.data.Message)
                    // window.grecaptcha.reset();
                    // setcapchacomplete({verified : false, token : ""})
                  }
                  console.log(response)
                  setloader(false)
                })
                .catch ((err) => {
                  console.log(err)
                  setfail({
                    status : true,
                    message : "Invalid Request!! Please Reload"
                })
                setloader(false)
                })
                setloader(false)
              }else{
                setloader(false)
                setvalidation({password : true, passwordMessage : "Password Mismatch, Please Check Again." })
              }
            }else{
              setloader(false)
              setvalidation({password : true, passwordMessage : "Password Too Short! Must be over 6 Characters." })
            }


            // await axios.post('/oauth/users/gettoken', datareq)
            // .then ((response) => {
            //   if(response.data.req === "success") {
            //   // appstate.setuserDet012348({loggedIn: true, userName: response.data.userName, AccessControl: response.data.userRole })
            //   // const usrname = response.data.userName
            //   // const accessControl = response.data.userRole
            //   // var userdt = { 'isLoggedin': true, 'userID' : response.data.userID, 'userName': response.data.userName, 'AccessControl': response.data.userRole };
            //   // localStorage.setItem("sp_user_init", JSON.stringify(userdt));
            //   }else{
            //   //   message.error("Local Error Detected. Please LogIn Again.")
            //   }
            //   })
            // .catch((error) => {
            //   const { response } = error;
            //   const { request, ...errorObject } = response;
            //   // window.grecaptcha.reset(recaptchaRef.current._widgetId);
            //   // setcapchacomplete({verified : false, token : ""})
            //   if (errorObject) {
            //   //   message.error(errorObject.data.message)
            //   //   setloader(false)
            //   }
            //   else{
            //   //   message.error("Internal Server Error - (50012) Please try again. ")
            //   //   setloader(false)
            //   }
  
            // });
            }else {
              setfail({
                  status : true,
                  message : "Client Verification Failed! Please Reload."
              })
              setloader(false)
            }
  }
  else{
      setvalidation({emailAddress : true, emailMessage : "invalid Email Address" })
      setloader(false)
  }
  }
}