import { useContext, useRef } from "react";
import { AppContext } from "./utils/stateManagement/appContext";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import styles from "./App.scss"
import { useEffect, useState } from "react";
import axios from "axios";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

//Import Modules//
import PlanSelect from "./components/AuthenticationEnables/planselect"
import LikedBeats from "./components/MainPages/LikedBeats";
import SingleDownload from "./components/MainPages/SingleDownload";
import Discover from "./components/MainPages/Discover";
import Search from "./components/MainPages/Search";
import Home from "./components/MainPages/Home";
import PaymentComplete from "./components/MainPages/PaymentComplete";
import Login from "./components/AuthenticationEnables/Login";
import Link from '@mui/material/Link';
import ProcessPayment from "./components/AuthenticationEnables/ProcessPayment";
import Register from "./components/AuthenticationEnables/Register"
import Switcher from "./components/AuthenticationEnables/Switcher";
import SwitchtoAuthenticate from "./components/AuthenticationEnables/SwitchtoAuthenticate";
import ErrorHandle404 from "./components/ErrorHandler/404";
import Result from "./components/AuthenticationEnables/result";
import DesktopNav from "./components/Navigation/DesktopNav";
import TopBar from "./utils/TopBar/TopBar";
import SubDownload from "./components/MainPages/SubscriptionDownload";
import Playlist from "./components/MainPages/Playlist";
import Featured from "./components/MainPages/Featured";
import Downloads from "./components/MainPages/Download";
import ProcessDownload from "./components/MainPages/ProcessDownload";
import AuthDownload from "./components/MainPages/AuthenticateDownload";
import MusicPlayer from "./helpers/MusicPlayer";
import Account from "./components/MainPages/Account";
import AdminMain from "./components/MainPages/admin"
import MobileNav from "./utils/MobileNav/MobileNav";
import Alltracks from "./components/MainPages/Alltracks";
import RecentlyPlayed from "./components/MainPages/Recent";
import Notification from "./components/MainPages/Notification";
import Processsingledownload from "./components/AuthenticationEnables/processsingledownload";
import Cart from "./components/MainPages/Cart";
// Lyric Pad
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { experimentalStyled as styled } from '@mui/material/styles';
import mainstyles from "./components/MainPages/mainstyle.module.scss"
import Singlepurchase from "./components/AuthenticationEnables/singlepurchase"
import Confetti from 'react-confetti';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#1B1B1B',
    borderRadius : "10px",
    color : "white",
    boxShadow: 24,
    maxWidth : "1250px",
    width : "90%",
    height : "65vh",
    minWidth : "250px",
  };


export default function App_Interface() {
    const AppState = useContext(AppContext);
    const [value, setValue] = useState(0);
    const valueRef = useRef('')
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [atopen, setatOpen] = useState(false);

    // Effect to control the modal based on authentication and offer availability
    useEffect(() => {
      if (isAuthenticated && AppState.LoggedIn.OfferValid && AppState.LoggedIn.isVerified) {
        setatOpen(true);
      }
    }, [isAuthenticated, AppState.LoggedIn.OfferValid, AppState.LoggedIn.isVerified]);
  
    const handleatClose = () => {
      setatOpen(false);
    };

    const isVerified = AppState.LoggedIn.isVerified; // Assuming this comes from a global state or context

    useEffect(() => {
      let intervalId;
  
      // Define the async function to fetch data and check authentication
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await axios.get(`/oauth/users/isEmailVerified`, {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
  
          if (response.data.isAuth) {
            AppState.setisLoggedIn({ ...AppState.LoggedIn, isVerified: true });
          } else {
            console.log("Authentication failed or still pending.");
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
      };
  
      if (!isVerified) {
        // Set up a timer to call fetchData every 5 seconds
        intervalId = setInterval(fetchData, 20000);
      }
  
      // Cleanup function to clear the interval when the component is unmounted or isVerified changes to true
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [isVerified]);

    function SecondaryDialog() {
        const [OpenDialog, setOpenDialog] = useState(false)
        const [rhymeword, setrhymeword] = useState()
        const [rhymelist, setrhymelist] = useState()
        const [isLoading, setisLoading] = useState(false)
    
        useEffect(() => {
            if (rhymelist === undefined) {
                return
            }
            else{
                setisLoading(false)
            }
          }, [rhymelist])
    
          const root = {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            height: "45vh",
            overflowY: "scroll",
            paddingTop: "10px"
          };
    
          function addWord(event) {
            valueRef.current.value = valueRef.current.value + " " + event.target.innerText;
            setOpenDialog(false);
          }
          
        async function HandleRhymeSearch() {
            setisLoading(true)
    
            function dynamicSort(property) {
                var sortOrder = 1;
                if(property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    /* next line works with strings and numbers, 
                     * and you may want to customize it to your needs
                     */
                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            }
    
            axios.get('https://rhymebrain.com/talk', {
                params: {
                    function: 'getRhymes',
                    word: rhymeword
                }
              })
              .then((res) => {
                setrhymelist(res.data.sort(dynamicSort("syllables")))
    
              })
              .catch((err) => {
                console.log(err)
              })
    
    
    
        }
    
        function handleClose() {
            setOpenDialog(false)
        };
    
        function handleOpen() {
            setOpenDialog(true)
        };
    
        return(
            <>
          <Button variant="text" onClick={() => handleOpen()}>Rhyme Finder</Button>
    
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={OpenDialog}
    
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={OpenDialog}>
              <Box sx={style}>
                    <div>
                        <div className="lyricHeader">
                            <h2>Rhyme Finder</h2>
                            <IconButton color="secondary" onClick={handleClose} >
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <div className="lyricContent">
                        <Box sx={{ padding : "24px" }}>
                        <Stack spacing={2} direction="row">
                        <TextField id="filled-basic" size="small" onChange={(e) => setrhymeword(e.target.value)} label="Search Here" variant="filled" fullWidth/>
                        <Button variant="contained" onClick={() => HandleRhymeSearch()}>Search</Button>
                        </Stack>
                        {isLoading ? (
                            <div className="LoaderCenter">
                        <span className="loader"></span>
                        </div>
                        ) : (
                            <ListRhymes/>
                        )}
                        </Box>
                        </div>
                    </div>
              </Box>
            </Fade>
          </Modal>
            </>
        )
    
        function ListRhymes() {
            if(rhymelist === undefined) {
                return (
                    <>
                    </>
                )
            }
            else{
                return (
                    <>
                     <div style={root}>
                        {rhymelist.map((obj, index) => {
                            return(
                                <Chip label={obj.word} onClick={(e) => addWord(e)}/>
                            )
                        })}
                        </div>
                    </>
                )
            }
        }
      }

    const Navigate = useNavigate()
    const img1 = AppState.currentBeat.beatimage
    const handleClose = () => AppState.setlyricpad({...AppState.LYRPad, state : false, beatID : null});

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        margin: theme.spacing(1),
        padding : theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius : "10px"
      }));

    if(AppState.AuthenticationEnabled === false) {
        if ('mediaSession' in window.navigator) {
            window.navigator.mediaSession.metadata = new MediaMetadata({
                title: AppState.currentBeat.beatname,
                artist: AppState.currentBeat.beatbpm + "Bpm",
                album: 'SpanceNTime',
                artwork: [
                    { src: img1, sizes: '512x512', type: 'image/png' },
                ]
              });
              navigator.mediaSession.setActionHandler('previoustrack', AppState.playbefore);
              navigator.mediaSession.setActionHandler('nexttrack', AppState.playnext);
        }

        if (AppState.LoggedIn.isVerified === false) {
          return(
            <>
  <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Fullscreen
        textAlign: 'center', // Center text
        backgroundColor: '#080808', // Background color
      }}>
        <img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+Long+-+White.png" alt="SpaceyMusic" style={{marginBottom : "10px", width : "200px"}} />
        <MailOutlineIcon style={{ fontSize: 50, color: '#8c14fc' }} />
        <h1>Please Verify Your Email</h1>
        <p>Click the link that has been sent to your email address to complete the verification process.</p>
      </div>
            </>
          )
        }
        else{
          return(
            <div className="Main">

        <div className="MainSidebar">
            <DesktopNav/>
        </div>
        <div className="MainContent">
        <div className="MainContentMain">
        <div className="TopBar">
            <TopBar/>
        </div>
            <div className="MainContentInt">


        {/* Lyric Pad Implementation */}
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={AppState.LYRPad.state}

        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={AppState.LYRPad.state}>
          <Box sx={style}>
                <div>
                    <div className="lyricHeader">
                        <h2>Lyric Pad</h2>
                        <IconButton color="secondary" onClick={handleClose} >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <div className="lyricContent">
                        {AppState.LYRPadLoad ? (
                            <>
                            <LinearProgress />
                            </>
                        ) : (
                            <>
                            <Box sx={{ width: '100%', overflowY : "scroll", height: "100%" }}>
                            <Stack>
                            <div className={mainstyles.beatInternal} key={AppState.LYRPad.beatInd}>
      <div className={mainstyles.beatInfo}>
      <Stack sx={{cursor : "pointer"}} onClick={()=> {AppState.EntryNow(AppState.LYRPad.beatInd) ; AppState.setwasplayingrec([...AppState.was, AppState.LYRPad.beatInd ]) ; AppState.setnowplaying({...AppState.currentBeat, Ind : AppState.LYRPad.beatInd, beatID: AppState.LYRPadObj._id ,  beatname : AppState.LYRPadObj.beatname, beatbpm : AppState.LYRPadObj.beatbpm, beatimage : AppState.LYRPadObj.beatimg, beatpreview : AppState.LYRPadObj.AppState.LYRPadObj.beatpreview, tags : AppState.LYRPadObj.tags, beatcolor : AppState.LYRPadObj.color})}} direction="row" spacing={2}>
      <Avatar src={AppState.LYRPadObj.beatimg} alt={AppState.LYRPadObj.beatname} variant="square" />
        <div className={mainstyles.beatName}>
        <Typography noWrap gutterBottom component="div">
      {AppState.LYRPadObj.beatname}
      <h5>{"#" + AppState.LYRPadObj.beatbpm + "BPM"}</h5>
      </Typography>
        </div>
    </Stack>
      </div>
      </div>
                            </Stack>
      <TabPanel value={value} index={0} sx={{overflowY : "scroll", padding : "0px !important"}}>
            <div className = "lyricPanelContent">
          <Item>
          <TextField id="filled-basic" label="Lyric Title" variant="filled" defaultValue={'Lyrics For "' + AppState.LYRPadObj.beatname + '"'} fullWidth/>
      <TextField
      sx={{marginTop : "20px"}}
          id="filled-multiline-static"
          label="Write Lyrics"
          multiline
          rows={8}
          variant="filled"
          inputRef={valueRef} 
          fullWidth
        />
          </Item>
            </div>
            <div className="LyricPanelOptions">
            <Stack spacing={2} direction="row">
            <SecondaryDialog/>
      <Button variant="contained">Save</Button>
    </Stack>
            </div>
      </TabPanel>

    </Box>
                            </>
                        )}
                    </div>
                </div>
          </Box>
        </Fade>
      </Modal>
      {
        atopen ? (<><Confetti style={{zIndex : "1100", }} /></>) : (<></>)
      }
      
      <Modal
        open={atopen}
        onClose={handleatClose}
        aria-labelledby="congratulations-modal-title"
        aria-describedby="congratulations-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '1px solid #8C14FC', boxShadow: 24, p: 2, color : "white", borderRadius : "20px"}}>
          <Typography id="congratulations-modal-title" variant="h6" component="h2">
            Congratulations!
          </Typography>
          <Typography id="congratulations-modal-description" sx={{ mt: 2 }}>
            You have unlocked 1 Free Download
          </Typography>
         <div>
         <Button onClick={handleatClose} style={{ marginTop: 20 }} fullWidth variant="contained">
            Start Exploring!
          </Button>
         </div>
          
        </Box>
      </Modal>


            <Routes>
        <Route path= "/planupgrade" element={<SwitchtoAuthenticate/>}/>
        <Route path= "/alltracks" element={<Alltracks/>}/>
        <Route path= "/notifications" element={<Notification/>}/>
        <Route path= "/recentlyplayed" element={<RecentlyPlayed/>}/>
        {/* <Route path= "/register" element={<SwitchtoAuthenticate/>}/> */}
        {/* <Route path= "/processpayment" element={<SwitchtoAuthenticate/>}/> */}
        {/* <Route path= "/result" element={<SwitchtoAuthenticate/>}/> */}
        <Route path='/downloadprocessback' element={<AuthDownload/>}/>
        <Route path='/' element={<Home/>}/>
        <Route path= "/singlepurchase" element={<SwitchtoAuthenticate/>}/>
        <Route path= "/processdownloadsingle" element={<SwitchtoAuthenticate/>}/>
        <Route path='/featured' element={<Featured/>}/>
        <Route path='/search' element={<Search/>}/>
        <Route path='/playlist/:id' element={<Playlist/>}/>
        <Route path='/discover' element={<Discover/>}/>
        <Route path="/liked" element={<LikedBeats/>}/>
        <Route path="/downloads" element={<Downloads/>}/>
        <Route path='*' element={<ErrorHandle404/>}/>
        <Route path="/download" element={<ProcessDownload/>}/>
        <Route path="processdownloadreq" element={<AuthDownload/>}/>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/cart/payment-complete" element={<PaymentComplete/>}/>
        <Route path="/account" element={<Account/>}/>
        <Route path="/adminspacetrumentals" element={<AdminMain/>}/>
        <Route path="/player" element={<></>}/>
        <Route path="/download-handler-single" element={<SingleDownload />}/>
        <Route path="/download-handler-sub" element={<SubDownload />}/>
      </Routes>
            </div>
            <div id="Msc_plr" className ="pl_wrap">
            <div className="MainContentPlayer">
            <MusicPlayer/>

            </div>
                
            </div>
            <div className="mbl_ftr_wrap">
            <MobileNav/>
            </div>
            
        </div>
            </div>
            </div>
        )
        }


    }else{
        return(
            <div>
        <section className='AuthMain'>
        <div className='AuthMainInt'>
        <div className="container mx-auto">
        <div className='LoginHeader'>
            <img onClick={() => Navigate("/")} src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+Long+-+White.png" alt="Spacetrumentals Logo" />
            <Link className="cursor-pointer" onClick={() => {
              Navigate("/");
              window.location.reload()
            }} underline="none">
                {'Browse as Guest'}
            </Link>
            </div>
            Depreciated Route
{/* 
        <Routes>
        <Route path= "/planupgrade" element={<PlanSelect/>}/>
        <Route path= "/singlepurchase" element={<Singlepurchase/>}/> */}
        {/* <Route path= "/login" element={<Login/>}/>
        <Route path= "/register" element={<Register/>}/>
        <Route path= "/processpayment" element={<ProcessPayment/>}/> */}
        {/* <Route path= "/result" element={<Result/>}/>
        <Route path='*' element={<Switcher/>}/>
      </Routes> */}
      </div>
        </div>

      </section>
            </div>
        )
    }

    //internal Functions//
    function LoginValidator(element){
        console.log("fired")
        if (AppState.LoggedIn.loggedIn) {
            return(element)
        }
        else {
            Navigate("/login")
        }
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };

      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
}

