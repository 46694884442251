import {useEffect, useState, useContext} from "react"
import mainstyles from "./mainstyle.module.scss"
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AppContext } from "../../utils/stateManagement/appContext";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

 function LikedBeats() {
    const Navigate = useNavigate()
    const AppState = useContext(AppContext)
    const [beatlisttem001, setbeatlisttem001] = useState([])
    const[loadcomp, setloadcomp] = useState(true)
    const [loaderbtn, setloaderbtn] = useState("")
    const [noData, setnoData] = useState(false)
    const { loginWithRedirect, logout, user, getAccessTokenSilently,  isAuthenticated, isLoading } = useAuth0();
    const [open, setOpen] = useState({
        set : false,
        message : "",
        status : ""
      });

//       useEffect(() => {
//         if(AppState.LoggedIn.loggedIn) {
//           return
//         }
//         else{
//           Navigate("/login")
//         }
// }, [])

    useEffect(() => {
        var aColl = document.getElementsByClassName('MainContent');
        changeColor(aColl, "#7f53ac")
}, [])

  function changeColor(coll, color){
    for(var i=0, len=coll.length; i<len; i++)
    {
        coll[i].style["background-color"] = color
    }
        
}
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
          
      setOpen({set : false});
    };
    useEffect(() => {
        if (AppState.LoggedIn.LikedBeats.length === 0) {
            return
        }
        else{
            var objmn = []
            AppState.LoggedIn.LikedBeats.map((plbtoz, myIID) => {
                var obj = AppState.beatContext.find(o => o._id === plbtoz.beatID);
                if (obj === undefined) {
                  return
                }
                else{
                  objmn.push(obj)
                }
                
            })
            setbeatlisttem001(objmn)
        }
    }, [AppState.beatContext])

    useEffect(() => {
        if (AppState.LoggedIn.LikedBeats.length === 0) {
            return
        }
        else{
            var objmn = []
            AppState.LoggedIn.LikedBeats.map((plbtoz, myIID) => {
                var obj = AppState.beatContext.find(o => o._id === plbtoz.beatID);
                if (obj === undefined) {
                  return
                }
                else{
                  objmn.push(obj)
                }
            })
            setbeatlisttem001(objmn)
        }
    }, [AppState.LoggedIn.LikedBeats])

    useEffect(() => {
        if(beatlisttem001.length === 0) {
          setnoData(true)
          setloadcomp(false)
        }
        else{
          setnoData(false)
            setloadcomp(false)
        }
    }, [beatlisttem001])

    return(
        <>
         <section>
            <div className={mainstyles.TitleMain}>
            <div className={mainstyles.TitleImg}>
                <FavoriteIcon/>
            </div>
            <div className={mainstyles.TitleInfo}>
            <h5 className="text-sm">Collection</h5>
            <h2>Liked Beats</h2>
            <h6> {user.name} &middot; {AppState.LoggedIn.LikedBeats.length} Beats</h6>
            </div>
            </div>
</section>   
        <ListBeat/>
        </>
    )

    function ListBeat() {
        if (loadcomp) {
            return(
                <>
                <div className="Ldr">
                  <div className="dot-flashing"></div>
                </div>
                </>
            )
        }else{
          if (noData) {
            return(
              <>
                <div className="Ldr">
                  <h6>No Data</h6>
                </div>
              </>
            )
          }
          else{
            return(
              <>
                                        <Helmet>
  <title>Liked Beats | Spacey Music</title>
</Helmet>
                <div className={mainstyles.b_li_rgt_tpc}>
                <div className={mainstyles.beatInfo}>
                <h5 className="text-sm">BEAT NAME</h5>
                </div>
                <div className={mainstyles.beatbpm}>
                <h5  className="text-sm">BPM</h5>
                </div>
                <div className={mainstyles.beatop}>
                <h5  className="text-sm">OPTIONS</h5>
                </div>
            </div>
            <div className={mainstyles.beaListWrap}>
            {beatlisttem001.map((beat, MYID) => (
                <div className={mainstyles.beatInternal}>
              <div className={mainstyles.beatInfo}>
              <Stack direction="row" spacing={2}>
              <Avatar src={beat.beatimg} alt={beat.beatname} variant="square" />
                <div className={mainstyles.beatName}>
                <Typography noWrap gutterBottom component="div">
              {beat.beatname}
              <h5 className="text-xs">SpaceNTime</h5>
              </Typography>
                </div>
            </Stack>
              </div>
              <div className={mainstyles.beatbpmlist}>
                <h5 className="text-sm">{"#" + beat.beatbpm + "BPM"}</h5>
                </div>
                <div className={mainstyles.beatoplist}>
                <Stack direction="row" spacing={1}>
              <IconButton onClick={() => processDownload(beat._id)} color="primary" aria-label="delete">
                <DownloadForOfflineIcon />
              </IconButton>
              <Snackbar
      open={open.set}
      autoHideDuration={4000}
      onClose={handleClose}
    >
    <Alert severity={open.status}>{open.message}</Alert>
    </Snackbar>
     {loaderbtn === beat._id ? <div className="flexon1"><CircularProgress style={{width : "24px", height : "24px"}}/></div> : <IconButton color="error" aria-label="delete"><DeleteForeverIcon onClick={() => {DeleteLikedBeats(beat._id)}} /></IconButton>}   
              </Stack>
                </div>
            </div>
              ))}
            </div>
        
            </>
            )
          }
        }
    }

    function processDownload(Val) {
      Navigate("/download?" + Val);
    
    }
    
    async function DeleteLikedBeats(val) {

    if(isAuthenticated) {
      console.log(val)   
      setloaderbtn(val)
      const token = await getAccessTokenSilently();
      
      await axios.post( `/oauth/users/likedelaction`, {
          beatID : val,
          }, 
      { withCredentials: true, 
        credentials: 'include', 
        headers: {
          'Authorization' : `Bearer ${token}`
      }})
          .then((response) => {
            console.log(response)
            AppState.updateUserFunc().then((docs) => {
              console.log("done")
            })
            .catch((err) => {
              console.log("done")
            })
            //   const storagetech = localStorage.getItem("sp_user_init");
            //   const DecodeTech = JSON.parse(storagetech);
            //   var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData };
            //   localStorage.setItem("sp_user_init", JSON.stringify(userdt));
            setloaderbtn("")
          })
          .catch((err) => {
            console.log(err)
            setloaderbtn("")
          })
  
    }
    else{
      setloaderbtn("")
  }
    }
    
}



export default withAuthenticationRequired(LikedBeats, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});