// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { CircularProgress } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// const PaymentComplete = () => {
//     const [paymentProcess, setpaymentProcess] = useState("processing")
//   const [paymentData, setPaymentData] = useState({
//     payment_intent: '',
//     payment_intent_client_secret: '',
//     redirect_status: ''
//   });
//   const maxRetries = 5; // Maximum number of retries
//   const retryDelay = 1000; // Delay in milliseconds between retries

//   useEffect(() => {
//     // Use URLSearchParams to extract URL parameters
//     const params = new URLSearchParams(window.location.search);
//     const payment_intent = params.get('payment_intent');
//     const payment_intent_client_secret = params.get('payment_intent_client_secret');
//     const redirect_status = params.get('redirect_status');

//     setPaymentData({
//       payment_intent,
//       payment_intent_client_secret,
//       redirect_status,
//     });

//     let retryCount = 0;

//     const makeRequest = () => {
//       axios
//         .post('/verify-payment-intent', {
//           payment_intent_id: payment_intent,
//         })
//         .then((response) => {
//             setpaymentProcess('success');
//           console.log('Payment intent verification successful:', response.data);
//         })
//         .catch((error) => {
//           console.error('Error verifying payment intent:', error);
//           retryCount++;
//           if (retryCount < maxRetries) {
//             console.log(`Retrying (${retryCount}/${maxRetries})...`);
//             setTimeout(makeRequest, retryDelay);
//           } else {
//             setpaymentProcess('failed');
//             console.error('Max retries reached. Payment intent verification failed.');
//           }
//         });
//     };

//     // Initial request
//     makeRequest();
//   }, []);

//   if (paymentProcess === "processing") {
//     return (
//       <div className='flex gap-4 items-center justify-center'>
//         <CircularProgress/>
//         <h5>Processing Payment... Please Wait</h5>
//       </div>
//     );
//   } else if (paymentProcess === "failed") {
//     return (
//         <div className='flex gap-4 items-center justify-center'>
//         <CloseIcon/>
//         <h5>Payment Failed</h5>
//       </div>
//     );
//   } else if (paymentProcess === "success") {
//     return (
//       <div className='flex gap-4 items-center justify-center'>
//         <CircularProgress/>
//         <h5>Payment Succeeded</h5>
//       </div>
//     );
//   }

// };

// export default PaymentComplete;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AppContext } from '../../utils/stateManagement/appContext';


const PAYMENT_STATES = {
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCESS: 'success',
};

const PaymentComplete = () => {
  const AppState = useContext(AppContext)
  const [paymentProcess, setPaymentProcess] = useState(PAYMENT_STATES.PROCESSING);
  const [paymentData, setPaymentData] = useState({
    payment_intent: '',
    payment_intent_client_secret: '',
    redirect_status: '',
  });
  const [downloadData, setdownloadData] = useState([])
  const maxRetries = 5;
  const retryDelay = 1000;
  let retryCount = 0;
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setPaymentData({
      payment_intent: params.get('payment_intent'),
      payment_intent_client_secret: params.get('payment_intent_client_secret'),
      redirect_status: params.get('redirect_status'),
    });

  

    const makeRequest = async () => {
      try {
        const response = await axios.post('/handle/payment/verify-payment-intent', {
          payment_intent_id: params.get('payment_intent'),
        });
        setPaymentProcess(PAYMENT_STATES.SUCCESS);
        console.log(response.data.downloadLinks)
        setdownloadData(response.data.downloadLinks)
        AppState.clearCart()
        AppState.updateUserFunc()
        console.log('Payment intent verification successful:', response.data);
      } catch (error) {
        console.error('Error verifying payment intent:', error);
        retryCount++;
        if (retryCount < maxRetries) {
          console.log(`Retrying (${retryCount}/${maxRetries})...`);
          setTimeout(makeRequest, retryDelay);
        } else {
          setPaymentProcess(PAYMENT_STATES.FAILED);
          console.error('Max retries reached. Payment intent verification failed.');
        }
      }
    };

    makeRequest();
  }, []);


  const renderContent = () => {
    switch (paymentProcess) {
      case PAYMENT_STATES.PROCESSING:
        return (
            <div className='flex gap-4 items-center justify-center'>
            <CircularProgress />
            <h5>Processing Payment... Please Wait</h5>
            </div>
        );
      case PAYMENT_STATES.FAILED:
        return (
            <div className='flex gap-4 items-center justify-center'>
            <CloseIcon />
            <h5>Payment Failed</h5>
            </div>
        );
      case PAYMENT_STATES.SUCCESS:
        return (
            <>
            <div>
            <div className='flex gap-4 items-center justify-center'>
  
            <CheckCircleIcon color="success" />
            <h5>Payment Verified</h5>
          </div>
          <h5 className="mt-4 mb-4 font-bold">Download Beats</h5>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Beat Name</TableCell>
            <TableCell align="right">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {downloadData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.beatName}
              </TableCell>
              <TableCell align="right"><a href={row.signedLink}><Button variant='contained'>Download</Button></a></TableCell>
            </TableRow> 
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </div>
            </>

        );
      default:
        return <h5>Unexpected error. Please try again.</h5>;
    }
  };

  return (
    <>
       {
    renderContent()
   }</>
  )
};

PaymentComplete.propTypes = {
  paymentData: PropTypes.shape({
    payment_intent: PropTypes.string,
    payment_intent_client_secret: PropTypes.string,
    redirect_status: PropTypes.string,
  }),
};

export default PaymentComplete;
