import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../utils/stateManagement/appContext';
import { usePalette } from 'react-palette';
import { useParams } from 'react-router-dom';
import Listbeat from '../../utils/BeatList/ListBeat';
import { Helmet } from 'react-helmet';
import { useContent } from '../../utils/stateManagement/contentContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import style from './mainstyle.module.scss';

export default function Playlist() {
  const { id } = useParams();
  const AppState = useContext(AppContext);
  const AppContent = useContent();
  const [open, setOpen] = useState({ set: false, message: '', status: '' });
  const [playlistdata, setPlaylistData] = useState({});
  const [loadingMN, setLoadingMN] = useState(true);
  const [beatlisttemp, setBeatlistTemp] = useState([]);
  const [beatlisttemp001, setBeatlistTemp001] = useState([]);
  const [validPlaylist, setValidPlaylist] = useState(true);

  const [selectedIndx, setselectedIndx] = useState([]);
  const { data, loadingimg, error } = usePalette(
    playlistdata.selected ? playlistdata.selected[0].beatimg : ''
  );

  useEffect(() => {
    if (data) {
      const aColl = document.getElementsByClassName('MainContent');
      for (let i = 0, len = aColl.length; i < len; i++) {
        aColl[i].style['background-color'] = data.darkVibrant;
      }
    }
  }, [data]);

  useEffect(() => {
    if (!id || AppState.collectpl.list.length === 0) {
      setValidPlaylist(false);
      return;
    }

    const playlistData = AppState.collectpl.list.find((element) => element.id === id);
    const cache = AppContent.cachedPlaylistData.find((element) => element.id === id);
    if (!playlistData) {
      setValidPlaylist(false);
      return;
    }

    setPlaylistData(playlistData);
    setLoadingMN(false);
    setselectedIndx(cache.uniqueRandomIndices)
  }, [id, AppState.collectpl]);

  useEffect(() => {
    if (beatlisttemp.length === 0) {
      return;
    }

    const objmn = beatlisttemp
      .map((plbtoz) => AppState.beatContext.find((o) => o._id === plbtoz.beatIDmn))
      .filter((obj) => obj !== undefined);

    setBeatlistTemp001(objmn);
  }, [beatlisttemp]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen({ set: false });
  };

  if (!validPlaylist) {
    return <div>Playlist Does Not Exist</div>;
  }

  if (loadingMN) {
    return (
      <div className="Ldr">
        <div className="dot-flashing"></div>
      </div>
    );
  }

  if (playlistdata.selected.length === 0) {
    return (
      <section>
        <div className={style.TitleMain}>
          <div className={style.TitleInfo}>
            <h5>Playlist</h5>
            <h2>{playlistdata.Name}</h2>
            <h6>{playlistdata.Author} &middot; {playlistdata.selected.length} Beats</h6>
          </div>
        </div>
        <h3>Empty Playlist</h3>
      </section>
    );
  }

  return (
    <>
      <Helmet>
        <title>{playlistdata.Name} - Playlist | Spacey Music</title>
      </Helmet>
      <section>
        <div className={style.TitleMain}>
          <div className="plImg">
            {selectedIndx.map((item, index) => (
              <>
                  <LazyLoadImage
                      key={index}
                      src={playlistdata.selected[item].beatimg}
                      width={125}
                      height={125}
                      alt={`Beat Image - ${playlistdata.selected[item].beatname
                      }`}
                      className={`plImg${index + 1}`}
                      PlaceholderSrc="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Placeholder+Image+(2).png"
                    />
              </>
            ))}
          </div>
          <div className={style.TitleInfo}>
            <h5>Playlist</h5>
            <h2>{playlistdata.Name}</h2>
            <h6>{playlistdata.Author} &middot; {playlistdata.selected.length} Beats</h6>
          </div>
        </div>
        <Listbeat data={playlistdata.selected} />
      </section>
    </>
  );
}


// import { useEffect, useState, useContext} from "react"
// import { AppContext } from "../../utils/stateManagement/appContext"
// import style from "./mainstyle.module.scss"
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
// import IconButton from '@mui/material/IconButton';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import Typography from '@mui/material/Typography';
// import { usePalette } from "react-palette"
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import CircularProgress from '@mui/material/CircularProgress';
// import axios from "axios";
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import { useNavigate , useParams } from "react-router-dom";
// import Listbeat from "../../utils/BeatList/ListBeat";
// import { Helmet } from 'react-helmet';
// import { useContent } from "../../utils/stateManagement/contentContext";



// export default function Playlist() {
// const [validplaylist, setvalidplaylist] = useState(true)
// const [playlistdata, setplaylistdata] = useState({})
// const [loadingMN, setloadingMN] = useState(true)
// const [beatlisttemp001, setbeatlisttem001] = useState([])
// const [beatlisttemp, setbeatlisttem] = useState([])
// const [errcase, seterrcase] = useState()
// const [likeload, setlikeload] = useState("")
// const Navigate = useNavigate()
// const APILINK = process.env.REACT_APP_APIDOMAIN
// const { id } = useParams();

// const [open, setOpen] = useState({
//   set : false,
//   message : "",
//   status : ""
// });
// const   AppState = useContext(AppContext);
// const AppContent = useContent()

// const { data, loadingimg, error } = usePalette(playlistdata.selected ? playlistdata.selected[0].beatimg : "")

// useEffect(() => {
//   console.log(data)
//   var aColl = document.getElementsByClassName('MainContent');
//   changeColor(aColl, data.darkVibrant)
// }, [data])

// function changeColor(coll, color){
//   for(var i=0, len=coll.length; i<len; i++)
//   {
//       coll[i].style["background-color"] = color
//   }
      
//   }

// const handleClose = (event, reason) => {
//   if (reason === 'clickaway') {
//     return;
//   }

//   setOpen({set : false});
// };







// useEffect(() => {
//   if (id && AppState.collectpl.list.length !== 0) {
//     console.log(AppState.collectpl)
//     const SelectPL = AppState.collectpl.list.find(function (element) {
//       return element.id === id;
//   });
//   console.log("selected" , SelectPL)
//   setplaylistdata(SelectPL)
//     setloadingMN(false)
//     // setplaylistdata(playplist)
//   }
//   else{
//     return
//   }
// }, [id, AppState.collectpl])

// useEffect(() => {
//     if (beatlisttemp.length === 0) {
//         return
//     }
//     else{
//         var objmn = []
//         console.log(beatlisttemp)
//         beatlisttemp.map((plbtoz, myIID) => {
//           console.log(plbtoz)
//             var obj = AppState.beatContext.find(o => o._id === plbtoz.beatIDmn);
//             if ( obj === undefined) {
//               return
//             }
//             else{
//               objmn.push(obj)
//             }
//         })
//         console.log(objmn)
//         setbeatlisttem001(objmn)
//     }
// }, [beatlisttemp])

// if(validplaylist === false) {
//     return(
//         <>
//             Playlist Does Not Exist           
//         </>
//     )
// }
// else{
//     if(loadingMN === true) {
//         return(<>
//        <div className="Ldr">
//             <div className="dot-flashing"></div>
//         </div>
//         </>

//         )
//     }
//     else{
      
//       if(playlistdata.selected.length === 0) {
//         return(
//           <>
// <section>
//           <div className={style.TitleMain}>
//           {/* <img src={playlistdata.Img} alt="playlist Image" width={190} height={190}/> */}
//           <div className={style.TitleInfo}>
//           <h5>Playlist</h5>
//           <h2>{playlistdata.Name}</h2>
//           <h6>{playlistdata.Author} &middot; {playlistdata.selected.length} Beats</h6>
//           </div>
//           </div>
//           <h3>Empty Playlist</h3>
//           {/* <ListBeatHome/> */}
// </section>
//           </>
//       )
//       }
//       else{
//         return(
//           <>
//                           <Helmet>
//   <title>{playlistdata.Name} - Playlist | Spacey Music</title>
// </Helmet>
// <section>
//           <div className={style.TitleMain}>
//           {/* <img src={playlistdata.Img} alt="playlist Image" width={190} height={190}/> */}
//           <div className="plImg">
//                          <img className="plImg1" src={playlistdata.selected[Math.floor(Math.random() * playlistdata.selected.length)].beatimg} alt={playlistdata.Name} />
//                          <img className="plImg2" src={playlistdata.selected[Math.floor(Math.random() * playlistdata.selected.length)].beatimg} alt={playlistdata.Name} />
//                          <img className="plImg3" src={playlistdata.selected[Math.floor(Math.random() * playlistdata.selected.length)].beatimg} alt={playlistdata.Name} />
//                          <img className="plImg4" src={playlistdata.selected[Math.floor(Math.random() * playlistdata.selected.length)].beatimg} alt={playlistdata.Name} />
//                          </div>
//           <div className={style.TitleInfo}>
//           <h5>Playlist</h5>
//           <h2>{playlistdata.Name}</h2>
//           <h6>{playlistdata.Author} &middot; {playlistdata.selected.length} Beats</h6>
//           </div>
//           </div>
//           <Listbeat data={playlistdata.selected}/>
// </section>
//           </>
//       )
//       }
//     }
// }

// // function ListBeatHome() {
// //     if (loadingMN) {
// //       return(
// //           <>
// //           <div className="Ldr">
// //               <div className="dot-flashing"></div>
// //           </div>
// //           </>
// //       )
// //   }
// //   else{
// //       return(
// //         <>
// //         <section > 
// //         <div className={style.b_li_rgt_tpc}>
// //         <div className={style.beatInfo}>
// //         <h5>BEAT NAME</h5>
// //         </div>
// //         <div className={style.beatbpm}>
// //         <h5>BPM</h5>
// //         </div>
// //         <div className={style.beatop}>
// //         <h5>OPTIONS</h5>
// //         </div>
// //     </div>
// //         {playlistdata.selected.map((beat, MYID) => (
// //           <div className={style.beatInternal}>
// //         <div className={style.beatInfo}>
// //         <Stack direction="row" spacing={2}>
// //         <Avatar src={beat.beatimg} alt={beat.beatname} variant="square" />
// //           <div className={style.beatName}>
// //           <Typography noWrap gutterBottom component="div">
// //         {beat.beatname}
// //         <h5>SpaceNTime</h5>
// //         </Typography>
// //           </div>
// //       </Stack>
// //         </div>
// //         <div className={style.beatbpmlist}>
// //           <h5>{"#" + beat.beatbpm + "BPM"}</h5>
// //           </div>
// //           <div className={style.beatoplist}>
// //           <Stack direction="row" spacing={1}>
// //           {likeload === beat._id ? <CircularProgress /> : <LikedSort>{beat._id}</LikedSort> }
        
// //         <Snackbar
// //         open={open.set}
// //         autoHideDuration={4000}
// //         onClose={handleClose}
// //       >
// //       <Alert severity={open.status}>{open.message}</Alert>
// //       </Snackbar>
// //         <IconButton onClick={() => processDownload(beat._id)}  color="primary" aria-label="delete">
// //           <DownloadForOfflineIcon />
// //         </IconButton>
        
// //         </Stack>
// //           </div>
// //       </div>
// //         ))}
// //         </section>
// //       </>
// //       )
// //   }
// //   }

//   function LikedSort( children ) {
//     if (AppState.LoggedIn.loggedIn === true) {
//       if(AppState.LoggedIn.LikedBeats === undefined) {
//         return(
//           <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
//           <FavoriteBorderIcon />
//           </IconButton>
//           )
//       }
//       else{
//         let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
//         if(obj) {
//           return(
//           <IconButton color="success" aria-label="delete">
//           <FavoriteIcon />
//           </IconButton>
//           )
//         }else{
//           return(
//           <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
//           <FavoriteBorderIcon />
//           </IconButton>
//           )
//         }
//       }
    
//     }
//     else{
//       return(
//         <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
//         <FavoriteBorderIcon />
//         </IconButton>
//       )
//     }
    
//       }
    
//       async function addLikedBeat(data) {
//         if(AppState.LoggedIn.loggedIn === true) {
//             setlikeload(data)
//             await axios.post( `/oauth/users/likedbeat`, {
//                 beatID : data,
//                 }, {withCredentials: true, credentials: 'include'})
//                 .then((response) => {
//                   console.log(response)
//                   AppState.setisLoggedIn({...AppState.LoggedIn, LikedBeats: response.data.updatedData})
//                   setOpen({set : true, message : "Beat Added to LikedBeats!", status : "success"})
//                     const storagetech = localStorage.getItem("sp_user_init");
//                     const DecodeTech = JSON.parse(storagetech);
//                     var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData };
//                     localStorage.setItem("sp_user_init", JSON.stringify(userdt));
//                     setlikeload("")
//                     AppState.updateUserFunc().then((docs) => {
//                       console.log("done")
//                     })
//                     .catch((err) => {
//                       console.log("done")
//                     })
//                 })
//                 .catch((err) => {
//                   console.log(err)
//                   setOpen({set : true, message : "Something Went Wrong", status : "error"})
//                   setlikeload("")
//                 })
        
//           }
//           else{
//             Navigate("/login")
//         }
//     }

//     function processDownload(Val) {
//         if(AppState.LoggedIn.loggedIn === true) {
//           Navigate("/download?" + Val);
//         }else{
//           Navigate("/login")
//         }
//       }
      
// }