import { useState, useEffect, useContext } from "react"
import { AppContext } from "../../utils/stateManagement/appContext"
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import style from "./mainstyle.module.scss"
import {GiMetronome} from "react-icons/gi"
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import mainstyles from "./mainstyle.module.scss"
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListItemText from '@mui/material/ListItemText';
import Listbeat from "../../utils/BeatList/ListBeat";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import { Helmet } from 'react-helmet';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#202020',
    ...theme.typography.body1,
    padding: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    "&:hover": {
          backgroundColor: '#404040'
        }
  }));

export default function Discover() {
    const URLParam = window.location.href;
    const ifurl = URLParam.includes('?')
    const AppState = useContext(AppContext)
    const [value, setvalue] = useState(URLParam.slice(URLParam.indexOf("?")+1))
    const [plMode, setplMode] = useState(ifurl)
    const [plLoad, setplLoad] = useState(true)
    const Navigate = useNavigate()
    const [isLoading, setisLoading] = useState(true)
    const [currentpaginate, setcurrentpaginate] = useState(0)
    const [beatlists, setbeatlists] = useState([])
    const [likeload, setlikeload] = useState("")
    const [loadingmain, setloadingmain] = useState(true)

    const beatlist = [...AppState.beatContext].reverse();

    useEffect(() => {
      // Check if the beatlist array is empty
      if (beatlist.length === 0) {
        return; // Early return if there are no beats
      }
      // Set loading to false if there are items in the beatlist
      setloadingmain(false);
    }, [beatlist]); // Use the correct variable name in the dependency array
 

    const [open, setOpen] = useState({
        set : false,
        message : "",
        status : ""
      });
  
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen({set : false});
      };

      if(!loadingmain) {
        return(
          <>
          <Helmet>
  <title>Discover | Spacey Music</title>
  <meta name="description" content="Discover and explore a wide range of high-quality beats from producer SpaceNTime (@spacey6) on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <link rel="canonical" href="https://open.spaceymusic.com/discover" />
  <meta property="og:title" content="SpaceyMusic - Discover High-Quality Beats" />
  <meta property="og:description" content="Discover and explore a wide range of high-quality beats on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <meta property="og:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <meta property="og:url" content="https://open.spaceymusic.com/discover" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="SpaceyMusic - Discover High-Quality Beats" />
  <meta name="twitter:description" content="Discover and explore a wide range of high-quality beats on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <meta name="twitter:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "SpaceyMusic",
        "url": "https://www.spaceymusic.com",
        "logo": "https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png",
        "description": "SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects.",
        "sameAs": [
          "https://twitter.com/SpaceNTime6",
          "https://www.instagram.com/spacey6"
        ]
      }
    `}
  </script>
</Helmet>
          <div className={style.discovericon} style={{marginBottom : "20px"}}>
          <Item onClick={() => { Navigate('/alltracks')}}><span><MusicNoteIcon/></span>All Beats</Item>
          </div>
          
          <Typography variant="h6" mb={2}>Trending Searches</Typography>
          <div className={style.discovericon}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {SearchParams.map((mapitem, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
              <Item onClick={() => Navigate("/search?" + mapitem.Url)}><span>{mapitem.Icon}</span><div>{mapitem.Name}</div></Item>
            </Grid>
          ))}
        </Grid>
          </div>
  
          </>
        )

      }
      else{
        return(
          <>
          <Helmet>
  <title>Discover | Spacey Music</title>
  <meta name="description" content="Discover and explore a wide range of high-quality beats from producer SpaceNTime (@spacey6) on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <link rel="canonical" href="https://open.spaceymusic.com/discover" />
  <meta property="og:title" content="SpaceyMusic - Discover High-Quality Beats" />
  <meta property="og:description" content="Discover and explore a wide range of high-quality beats on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <meta property="og:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <meta property="og:url" content="https://open.spaceymusic.com/discover" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="SpaceyMusic - Discover High-Quality Beats" />
  <meta name="twitter:description" content="Discover and explore a wide range of high-quality beats on SpaceyMusic. Join our subscription-based platform and access genre-specific beats for your music projects." />
  <meta name="twitter:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "SpaceyMusic",
        "url": "https://www.spaceymusic.com",
        "logo": "https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png",
        "description": "SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects.",
        "sameAs": [
          "https://twitter.com/SpaceNTime6",
          "https://www.instagram.com/spacey6"
        ]
      }
    `}
  </script>
</Helmet>
          <div className='Ldr' >
          <div id='MainLoader' className='LoaderInternal'>
          <h5>Loading...</h5>
            <div className='dot-flashing'></div>
          </div>
          </div>
          </>
        )
      }

    function handleChangepagination( event, value) {
      setcurrentpaginate(value - 1)
     }

    function LikedSort( children ) {
        if (AppState.LoggedIn.loggedIn === true) {
          if(AppState.LoggedIn.LikedBeats === undefined) {
            return(
              <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
              <FavoriteBorderIcon />
              </IconButton>
              )
          }
          else{
            let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
            if(obj) {
              return(
              <IconButton color="success" aria-label="delete">
              <FavoriteIcon />
              </IconButton>
              )
            }else{
              return(
              <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
              <FavoriteBorderIcon />
              </IconButton>
              )
            }
          }
        
        }
        else{
          return(
            <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
            <FavoriteBorderIcon />
            </IconButton>
          )
        }
          }

          function processDownload(Val) {
            if(AppState.LoggedIn.loggedIn === true) {
              Navigate("/download?" + Val);
            }else{
              Navigate("/login")
            }
          }

          async function addLikedBeat(data) {
            if(AppState.LoggedIn.loggedIn === true) {
              setlikeload(data)
              await axios.post( `/oauth/users/likedbeat`, {
                  beatID : data,
                  }, {withCredentials: true, credentials: 'include'})
                  .then((response) => {
                    console.log(response)
                    AppState.setisLoggedIn({...AppState.LoggedIn, LikedBeats: response.data.updatedData})
                    setOpen({set : true, message : "Beat Added to LikedBeats!", status : "success"})
                      const storagetech = localStorage.getItem("sp_user_init");
                      const DecodeTech = JSON.parse(storagetech);
                      var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData, "Downloads": DecodeTech.DownloadBeats, "OfferValid" : DecodeTech.OfferValid, "isVerified" : DecodeTech.isVerified };
                      localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                      setlikeload("")
                      AppState.updateUserFunc().then((docs) => {
                        console.log("done")
                      })
                      .catch((err) => {
                        console.log("done")
                      })
                  })
                  .catch((err) => {
                    console.log(err)
                    setOpen({set : true, message : "Something Went Wrong", status : "error"})
                    setlikeload("")
                  })
          
            }
            else{
              Navigate("/login")
          }
            };
        
}

const SearchParams = [
    {
        Name : "Young Thug",
        Url : "young thug",
        Icon : <PersonIcon/>
    },
    {
        Name : "Drake",
        Url : "drake",
        Icon : <PersonIcon/>
    },
    {
        Name : "Kanye West",
        Url : "kanye west",
        Icon : <PersonIcon/>
    },
    {
        Name : "Gangsta",
        Url : "gangsta",
        Icon : <PersonIcon/>
    },
    {
        Name : "Young Dolph",
        Url : "young dolph",
        Icon : <PersonIcon/>
    },
    {
        Name : "200 Bpm",
        Url : "200",
        Icon : <AvTimerIcon/>
    },    {
        Name : "Tory Lanez",
        Url : "tory lanez",
        Icon : <PersonIcon/>
    },

]

