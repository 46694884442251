import Mainstyle from "./mobilenav.module.scss"
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../stateManagement/appContext";
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PauseIcon from '@mui/icons-material/Pause';
import Drawer from '@mui/material/Drawer';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Slider from '@mui/material/Slider';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TextField from '@mui/material/TextField';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import HomeIcon from '@mui/icons-material/Home';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { usePalette } from "react-palette";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SliderRender from "../slider";
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';

export default function MobileNav() {
  const { loginWithRedirect, logout, user, getAccessTokenSilently,  isAuthenticated, isLoading } = useAuth0();
    const AppState = useContext(AppContext)
    const [drawerOpen, setdrawerOpen] = useState(false)
    const [temptags, settemptags] = useState([])
    const [likeload, setlikeload] = useState(false)
    const Navigate = useNavigate()
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const minuterun = AppState.audioRef.current.currentTime/60
    const [anchorEl, setAnchorEl] = useState(null);
    const openselector = Boolean(anchorEl);
    console.log("hr")

    const handleselectorClick = (event, value) => {
      setAnchorEl(event.currentTarget)
    };
    const handleselectorClose = () => {
      setAnchorEl(null)
    };
  
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        border : "1px solid #6747c7",
        borderRadius : "10px",
        backgroundColor : "#6747c796" ,
        textAlign: 'center',
        
        fontSize: "12px",
        padding: "2px",
        color: theme.palette.text.secondary,
      }));



    var minutes =(AppState.dura / 60)
    var dur = minutes.toFixed(2);
 


    useEffect(() => {
        if (window.location.pathname === "/player"){
            setdrawerOpen(true)
               }
         else {
            setdrawerOpen(false)
         }
    }, [window.location.pathname])

    useEffect(() => {
        const tags = AppState.currentBeat.tags
        const decodedtag = tags.split(", ")
        settemptags(decodedtag)
        console.log(decodedtag)
    },[AppState.currentBeat.tags])

    return(
        <>
            <div id="Spacetrumentals_Mbl_Plr" className={Mainstyle.mbl_plr + " h-20"} style={{backgroundColor: AppState.currentBeat.beatcolor}}>
              <div className={Mainstyle.mbl_plr_trc} >
      <SliderRender/>
              </div>
                <div className={Mainstyle.mbl_plrInternal} onClick={() => {Navigate("/player")}} style={{backgroundColor: AppState.currentBeat.beatcolor}} >
                <Stack direction="row" spacing={2}>
                <Avatar alt={AppState.currentBeat.beatname} src={AppState.currentBeat.beatimage} variant="rounded"/>
                <Typography sx={{textAlign : "left", position : "relative", top : "-3px"}} noWrap gutterBottom component="div">
                    {AppState.currentBeat.beatname}
                    <h5 style={{marginBottom : "0px"}}>{AppState.currentBeat.beatbpm + " Bpm"}</h5>
                    </Typography>
                
                {/* <IconButton 
                sx={{height : "!00%"}}
                size="small"
        id='BeatSelector'
        aria-controls='BeatSelector'
        aria-haspopup="true"
        aria-expanded={openselector ? 'true' : undefined}
        onClick={(e) => handleselectorClick(e)}
      color="secondary" aria-label="delete">
      <MoreVertIcon/>
      </IconButton> */}
      </Stack>
      {/* <Menu
        id='BeatSelect'
        aria-labelledby='BeatSelector'
        anchorEl={anchorEl}
        open={openselector}
        onClose={handleselectorClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mb: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: -10,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
            <MenuItem onClick={handleselectorClose}>
          <ListItemIcon>
            <FavoriteBorderIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          Like Beat
        </MenuItem>
        <MenuItem onClick={() => {AppState.setlyricpad({...AppState.LYRPad, state : true, beatID : AppState.currentBeat.beatID}); handleselectorClose()}}>
          <ListItemIcon>
            <TextFormatIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          Lyric Pad
        </MenuItem>
      </Menu> */}
                </div>
                {/* <Stack direction="row" spacing={1}>
                <IconButton color="success" aria-label="Download Beat" size="small">
                
                </IconButton>
     
                {PlayerMain()} */}
                <Stack direction="row" spacing={1}>
                <IconButton color="primary" aria-label="Like Beat" onClick={() => {
          AppState.handlePurchaseOpen({
            beatimg : AppState.currentBeat.beatimage,
            _id : AppState.currentBeat.beatID,
          } , []); 
          console.log(AppState.currentBeat)
          }} size="small">
                     <ArrowDownwardRoundedIcon/>
                </IconButton>
                <IconButton color="secondary" onClick={() => AppState.playbefore()} aria-label="Exit Drawer" >
                    <SkipPreviousRoundedIcon/>
                </IconButton>
                {PlayerMain()}
                <IconButton color="secondary" onClick={() => AppState.playnext()} aria-label="Exit Drawer" >
                    <SkipNextRoundedIcon/>
                </IconButton>
                </Stack>
                {/* </Stack> */}
                <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            sx={{zIndex : 1700}}
          >
            <div className={Mainstyle.InternalPlayerWrap}  style={{background : "url("+AppState.currentBeat.beatimage+")", backgroundColor: AppState.currentBeat.beatcolor}}>
              <div className={Mainstyle.InternalPlayerMain}>
              <div className={Mainstyle.InternalPlayertop}>
              <Stack direction="row"  
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{padding : "5px"}}
              height={80}>
              
              <IconButton onClick={() => Navigate(-1)} color="secondary" aria-label="Exit Drawer" >
                    <KeyboardArrowDownIcon />
                </IconButton>
                <div>
                <Typography variant="body2" sx={{textAlign : "right", position : "relative", fontSize : "10px", color : "gray"}} noWrap gutterBottom component="div">
                PLAYING FROM
                    </Typography>
                <Typography sx={{textAlign : "right", position : "relative", fontSize: "14px"}} noWrap gutterBottom component="div">
                {AppState.from}
                    </Typography>
                </div>
              </Stack>

                </div>
                <div className={Mainstyle.InternalPlayerImg} style={{display : "flex" , justifyContent : "space-around", padding : "15px"}}>
                <img src={AppState.currentBeat.beatimage} alt="Beat Image" width={300} height={300} />
                </div>
                <Stack direction="row" justifyContent="space-between" style={{marginRight : "35px"}}>
                <div className={Mainstyle.mbl_plr_internalimage}>
                <Typography variant="h6" noWrap gutterBottom component="div">
                {AppState.currentBeat.beatname}
                </Typography>
                <Typography variant="subtitle1" noWrap gutterBottom component="div">
                {AppState.currentBeat.beatbpm + "Bpm"}
                </Typography>
                </div>
                <div style={{marginTop : "40px"}}>
                {likeload ? <CircularProgress /> : <LikedSort>{AppState.currentBeat.beatID}</LikedSort> }
                </div>
                </Stack>

                <div style={{marginTop : "15px" ,marginLeft : "30px", marginRight : "30px"}}>
                <SliderRender/>
      <div className={Mainstyle.mbl_plr_internalh3} style={{display : "flex", justifyContent : "space-between"}}>
      <h6>{minuterun.toFixed(2)}</h6>
      <h6>{dur}</h6>
      </div>
                </div>
                <div style={{display : "flex" , justifyContent : "space-around", padding : "15px", paddingTop : "20px"}}>
                <Stack direction="row" spacing={1}>
                
                <IconButton color="primary" onClick={() => {
                  Navigate("/")
          AppState.handlePurchaseOpen({
            beatimg : AppState.currentBeat.beatimage,
            _id : AppState.currentBeat.beatID,
          } , []); 
          console.log(AppState.currentBeat)
          }} aria-label="Exit Drawer" >
                    <ShareRoundedIcon sx={{ height: 28, width: 28 }}/>
                </IconButton>
                {/* <IconButton color="success" onClick={() => AppState.playbefore()} aria-label="Exit Drawer" >
                    <FavoriteBorderIcon sx={{ height: 28, width: 28 }}/>
                </IconButton> */}
                <IconButton color="secondary" onClick={() => AppState.playbefore()} aria-label="Exit Drawer" >
                    <SkipPreviousRoundedIcon sx={{ height: 48, width: 48 }}/>
                </IconButton>
                {PlayerMain("internal")}
                <IconButton color="secondary" onClick={() => AppState.playnext()} aria-label="Exit Drawer" >
                    <SkipNextRoundedIcon sx={{ height: 48, width: 48 }}/>
                </IconButton>
                <IconButton color="primary" onClick={() => {
                  Navigate("/")
          AppState.handlePurchaseOpen({
            beatimg : AppState.currentBeat.beatimage,
            _id : AppState.currentBeat.beatID,
          } , []); 
          console.log(AppState.currentBeat)
          }} aria-label="Exit Drawer" >
                    <ArrowDownwardRoundedIcon sx={{ height: 28, width: 28 }}/>
                </IconButton>
                </Stack>
   
                </div>
                <div className="lyricCover">
                <div className="lyricPanel">
                
                {/* <TextField
          id="standard-multiline-static"
          label="Write lyrics"
          inputProps={{
      style: {
        height: "370px",
        fontSize : "30px",
        lineHeight: "40px",
        overflowY: "scroll",
        fontWeight: 700,
      },
    }}
          multiline
          fullWidth
          variant="standard"
        />
                      <div class="lyricbtn"><Stack direction="row" spacing={2}><Button size="small" variant="text">View Saved Lyrics</Button><Button size="small" variant="contained">Save Lyrics</Button></Stack></div> */}

              </div>  

                </div>
                <div style={{marginLeft : "30px", marginRight : "30px"}}>
    </div>    
              </div> 

            </div>

          </Drawer>

            </div>
            <div className={Mainstyle.mobileMainNav}>
                <div className={window.location.pathname === "/" ? Mainstyle.mobileMainNavigationElements + " active" : Mainstyle.mobileMainNavigationElements} onClick={() => Navigate("/")}>
                    <span>{window.location.pathname === "/" ? <HomeIcon/> : <HomeOutlinedIcon/>}</span>
                    <h6>Home</h6>
                </div>
                <div className={window.location.pathname === "/discover" ? Mainstyle.mobileMainNavigationElements + " active" : Mainstyle.mobileMainNavigationElements}  onClick={() => Navigate("/discover")}>
                <span>{window.location.pathname === "/discover" ? <ExploreIcon/> : <ExploreOutlinedIcon/>}</span>
                    <h6>Discover</h6>
                </div>
                <div className={window.location.pathname === "/search" ? Mainstyle.mobileMainNavigationElements + " active" : Mainstyle.mobileMainNavigationElements}  onClick={() => Navigate("/search")}>
                <span>{window.location.pathname === "/search" ? <SavedSearchIcon/> : <SearchOutlinedIcon/>}</span>
                    <h6>Search</h6>
                </div>
                
                {/* <div className={window.location.pathname === "/featured" ? Mainstyle.mobileMainNavigationElements + " active" : Mainstyle.mobileMainNavigationElements}  onClick={() => Navigate("/featured")}>
                <span>{window.location.pathname === "/featured" ? <FeaturedVideoIcon/> : <FeaturedVideoOutlinedIcon/>}</span>
                    <h6>Featured</h6>
                </div> */}
            </div>
        </>
    )

    function PlayerMain(val) {
        if (val === "internal") {
          function beatplay() {
            if (AppState.currentBeat.isPlaying) {
              AppState.setnowplaying({...AppState.currentBeat, isPlaying : false})
            }else{
              AppState.setnowplaying({...AppState.currentBeat, isPlaying : true})
            }
          }
          function pausefilledCirc() {
            if (AppState.beatloadingplayer === true) {
              if (AppState.currentBeat.beatID === "0"){
                return(<CloseIcon sx={{ height: 58, width: 58 }}/>)
              }else{
                return(
                    <CircularProgress size={58}/>
                )
              }
            }
            else{
              return(<PauseIcon sx={{ height: 58, width: 58 }} />)  
            }
          }
          
          function playfilledCirc() {
            if (AppState.beatloadingplayer === true) {
              if (AppState.currentBeat.beatID === "0"){
                return(<CloseIcon sx={{ height: 58, width: 58 }}/>)
              }else{
                return(
                    <CircularProgress size={58}/>
                )
              }
            }
            else{
              return(<PlayArrowIcon sx={{ height: 58, width: 58 }} />)  }
          }
          return(
            <>
                          <IconButton onClick= { () => beatplay()} color='secondary' aria-label="play/pause">
                        {AppState.currentBeat.isPlaying ? pausefilledCirc() : playfilledCirc() }
            </IconButton>
            </>
          )
        }
        else{
          function beatplay() {
            if (AppState.currentBeat.isPlaying) {
              AppState.setnowplaying({...AppState.currentBeat, isPlaying : false})
            }else{
              AppState.setnowplaying({...AppState.currentBeat, isPlaying : true})
            }
          }
          function pausefilledCirc() {
            if (AppState.beatloadingplayer === true) {
              if (AppState.currentBeat.beatID === "0"){
                return(<CloseIcon sx={{ height: 28, width: 28 }}/>)
              }else{
                return(
                    <CircularProgress size={28}/>
                )
              }
            }
            else{
              return(<PauseIcon sx={{ height: 28, width: 28 }} />)  
            }
          }
          
          function playfilledCirc() {
            if (AppState.beatloadingplayer === true) {
              if (AppState.currentBeat.beatID === "0"){
                return(<CloseIcon sx={{ height: 28, width: 28 }}/>)
              }else{
                return(
                    <CircularProgress size={28}/>
                )
              }
            }
            else{
              return(<PlayArrowIcon sx={{ height: 28, width: 28 }} />)  }
          }
          return(
            <IconButton onClick= { () => beatplay()} color='secondary' aria-label="play/pause">
                        {AppState.currentBeat.isPlaying ? pausefilledCirc() : playfilledCirc() }
            </IconButton>
          )
            // if(AppState.currentBeat.isPlaying) {
            //     return(
            //         <IconButton color="secondary" onClick={() => {AppState.setnowplaying({...AppState.currentBeat, isPlaying : false })}} aria-label="Play" size="small">
            //         <PauseIcon/>
            //    </IconButton>
            //     )
            // }
            // else{
            //     return(
            //         <IconButton color="secondary" onClick={() => validatePlay()} aria-label="Play" size="small">
            //         <PlayArrowIcon/>
            //    </IconButton>
            //     )
            // }   
        }

    }

    function validatePlay() {
        if (AppState.currentBeat.beatID === "0") {
            return
        }
        else{
            AppState.setnowplaying({...AppState.currentBeat, isPlaying : true})
        }
    }

    async function addLikedBeat(data) {
      if(isAuthenticated) {
        console.log(data)   
        setlikeload(data)
        const token = await getAccessTokenSilently();
        
        await axios.post( `/oauth/users/likeaction`, {
            beatID : data,
            }, 
        { withCredentials: true, 
          credentials: 'include', 
          headers: {
            'Authorization' : `Bearer ${token}`
        }})
            .then((response) => {
              console.log(response)
              AppState.updateUserFunc().then((docs) => {
                console.log("done")
              })
              .catch((err) => {
                console.log("done")
              })
              //   const storagetech = localStorage.getItem("sp_user_init");
              //   const DecodeTech = JSON.parse(storagetech);
              //   var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData };
              //   localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                setlikeload("")
            })
            .catch((err) => {
              console.log(err)
              setlikeload("")
            })
    
      }
      else{
        return
    }
      };
        function LikedSort( children ) {
          if (isAuthenticated) {
            if(AppState.LoggedIn.LikedBeats === undefined) {
              return(
                <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
                <FavoriteBorderIcon />
                </IconButton>
                )
            }
            else{
              let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
              if(obj) {
                return(
                <IconButton color="success" aria-label="delete">
                <FavoriteIcon />
                </IconButton>
                )
              }else{
                return(
                <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
                <FavoriteBorderIcon />
                </IconButton>
                )
              }
            }
          
          }
          else{
            return(
              <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
              <FavoriteBorderIcon />
              </IconButton>
            )
          }
          
            }
              function processDownload(Val) {
                if(AppState.LoggedIn.loggedIn === true) {
                  Navigate("/download?" + Val);
                }else{
                  // Navigate("/login")
                }
              }
            
    
}