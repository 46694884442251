import React, { useEffect, useContext, useState, useRef  } from 'react'
import { useLocation, useNavigate} from 'react-router-dom';
import { AppContext } from '../../utils/stateManagement/appContext';
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
// import loadStripe from '@stripe/stripe-js';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// const stripePromise = loadStripe("pk_test_51NkPyqSEcJYsEOib6PAp8rQzUG00IuSiH8HC3CXAqCl0lJAfeXI1bkyd8K5btsbPEX0JqYXBT9dhULiiAkcBL0UA00MuOqwSoE");

function Singlepurchase() {
  

  
    const {loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const AppState = useContext(AppContext);
    const Navigate = useNavigate()
    const [step, setstep] = useState("")
    const [selectedBeat, setselectedBeat] = React.useState();
    const [rdurl, setrdurl] = React.useState("")
    const [processingPayment, setprocessingPayment] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [paymentprocessmodel, setpaymentprocessmodel] = useState(false)
    const [TrackingID, setTrackingID] = useState("")

    

    const handleErrorOpen = () => {
      setOpen(true);
    };
  
    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    async function reportOrderID(orderID) {
      const token = await getAccessTokenSilently()
      axios.post( `/oauth/users/registerpayment`, {
        Data : {
          Type : "Report",
          TrackingID : TrackingID,
          orderID : orderID
        },
        }, { withCredentials: true, 
          credentials: 'include', 
          headers: {
            'Authorization' : `Bearer ${token}`
        }})
        .then((response) => {
          return
        })
        .catch((err) => {
          return
        })
    }

    async function createnewtransaction() {
      setpaymentprocessmodel(true)
        const token = await getAccessTokenSilently()
        axios.post( `/oauth/users/registerpayment`, {
          Data : {
            Type : "Open",
            Intent : "Single",
            beatID : Selected_id,
            TransactionName : "Single Beat Purchase - " + selectedBeat.beatname
          },
          }, { withCredentials: true, 
            credentials: 'include', 
            headers: {
              'Authorization' : `Bearer ${token}`
          }})
          .then((response) => {
            setpaymentprocessmodel(false)
            setTrackingID(response.data.TrackingID)
            setstep("payment")
          })
          .catch((err) => {
            console.log("requsest error")
          })
    }
  

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Selected_id = searchParams.get('id');

    useEffect(() => {
        console.log(location.search)
        const searchParams = new URLSearchParams(location.search);
        const Selected_id = searchParams.get('id');
        var filtered = AppState.beatContext.filter(function(el) {
            return el._id === Selected_id;
          });
        setselectedBeat(filtered[0])
        setrdurl(window.location.pathname + window.location.search)
    }, [location.search])


      if (processingPayment) {
        return(
          <>
          <section className='flex justify-center items-center mt-24'>
          <div className='flex justify-center items-center'>
          <CircularProgress/>
          <p className="ml-5">Processing Payment.... Please do not refresh.</p>
          </div>
          </section>
          </>
        )
      }
      else{
        if(step === "payment") {
          return(
            <>
            <h2 className='text-4xl mt-10 mb-10 font-bold'>You're almost there! Complete your order</h2>
          <div className="bg-black mt-5 rounded-md shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] p-4">
          <h3 className="text-xl">SpaceyMusic - {selectedBeat ? selectedBeat.beatname : ""}</h3>
          <hr className="mt-5 mb-5"/>
          <div className="text-sm mb-5">Tracking ID : <span className='text-slate-300'>{"#" + TrackingID}</span></div>
          <div className="flex justify-between mb-5"><h5>SpaceyMusic {selectedBeat ? selectedBeat._id : ""}</h5> <span>$74</span></div>
      <p className="text-slate-500 text-xs">By checking out you agree with our Terms of Service. We will process your personal data for the fulfillment of your order and other purposes as per our Privacy Policy. You can cancel recurring payments at any time.</p>
          </div>
            </>
          )
        }
        else{

        }
        return (
          <div>
          <h2 className='text-4xl mt-10 mb-10 font-bold'>Please review your order</h2>
          <h3 className="text-3xl mt-8">1. Review Selected Beat</h3>
                      <div className="bg-black mt-5 rounded-md shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] p-4">
                      <img className='rounded-lg mt-5 h-20 mb-5' src={selectedBeat ? selectedBeat.beatimg : ""} alt={user.email}/>
                      <h3 className="text-xl">{selectedBeat ? selectedBeat.beatname : ""}</h3>
                      <hr className="mt-5 mb-5"/>
                      <div className="flex justify-between"><h5>{selectedBeat ? selectedBeat._id : ""}</h5> <span>$74</span></div>
                      </div>
                      <div>
                      </div>
                        <div className='flex justify-center mt-5 mb-24'>
                        </div>
                      <div>
                      </div>
                      </div>
        )
      }
}


export default withAuthenticationRequired(Singlepurchase, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
