import { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { AppContext } from './stateManagement/appContext';
import Slider from '@mui/material/Slider';

export default function SliderRender() {
  const AppState = useContext(AppContext);
  const [playNow, setplayNow] = useState(0.0);
  const rafRef = useRef(null);

  const updateSlider = useMemo(() => () => {
    if (AppState.audioRef.current.ended) {
      AppState.playnext();
    } else {
      setplayNow(AppState.audioRef.current.currentTime);
    }
  }, [AppState.audioRef.current, AppState.playnext]);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(updateSlider);
    const intervalId = setInterval(updateSlider, 50);

    return () => {
      clearInterval(intervalId);
      cancelAnimationFrame(rafRef.current);
    }
  }, [updateSlider]);

  return (
    <Slider
      sx={{padding : "0px !important"}}
      size="small"
      value={playNow}
      aria-label="Small"
      onChange={(e) => AppState.onScrub(e.target.value)}
      onMouseUp={AppState.onScrubEnd}
      onKeyUp={AppState.onScrubEnd}
      max={AppState.dura ? AppState.dura : `${AppState.dura}`}
    />
  )
}



// import { useContext, useState, useEffect } from 'react';
// import { AppContext } from './stateManagement/appContext';
// import Slider from '@mui/material/Slider';


// export default function SliderRender() {
//     const AppState = useContext(AppContext)
//     const [playNow, setplayNow] = useState(0.0)

//     useEffect(() => {
//         setInterval(() => {
//             if (AppState.audioRef.current.ended) {
//                 AppState.playnext();
//             } else {
//               setplayNow(AppState.audioRef.current.currentTime);
//             }
//           }, [100]);
//     }, [])

//     return(
//       <>
//           <Slider
//             sx={{padding : "0px !important"}}
//           size="small"
//           defaultValue={0}
//           value={playNow}
//           aria-label="Small"
//           onChange={(e) => AppState.onScrub(e.target.value)}
//         onMouseUp={AppState.onScrubEnd}
//         onKeyUp={AppState.onScrubEnd}
//           max={AppState.dura ? AppState.dura : `${AppState.dura}`}
//         />
//       </>
//     )
//   }