import mainstyles from "./mainstyle.module.scss"
import { Typography, Stack, Avatar, Snackbar, Alert, IconButton } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../utils/stateManagement/appContext";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from "axios";
import {HiHashtag} from "react-icons/hi"
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useAuth0 } from '@auth0/auth0-react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Listbeat from "../../utils/BeatList/ListBeat";

export default function Alltracks() {
    const AppState = useContext(AppContext)
    const beatlist = [...AppState.beatContext].reverse();
    const [likeload, setlikeload] = useState("")
    const Navigate = useNavigate()
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
    const [open, setOpen] = useState({
        set : false,
        message : "",
        status : ""
      });
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen({set : false});
      };
      const [anchorEl, setAnchorEl] = useState({
        EL : null,
        element : null
      });
      const openselector = Boolean(anchorEl);
      const handleselectorClick = (event, value) => {
        setAnchorEl({
          EL : value,
          element : event.currentTarget
        })
      };
      const handleselectorClose = () => {
        setAnchorEl({
          EL : null,
          element : null
        })
        console.log(anchorEl)
      };
    
      useEffect(() => {
        window.scrollTo(0,0)
      },[])
      const TagButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(grey[900]),
        textTransform : "none",
        backgroundColor: grey[900],
        '&:hover': {
          backgroundColor: grey[800],
        },
      }));
      return(
        <>
          <Listbeat data={beatlist}/>
        </>
      )

    function processDownload(Val) {
          Navigate("/download?" + Val);
      }

    function LikedSort( children ) {
        if (AppState.LoggedIn.loggedIn === true) {
          if(AppState.LoggedIn.LikedBeats === undefined) {
            return(
              <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
              <FavoriteBorderIcon />
              </IconButton>
              )
          }
          else{
            let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
            if(obj) {
              return(
              <IconButton color="success" aria-label="delete">
              <FavoriteIcon />
              </IconButton>
              )
            }else{
              return(
              <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
              <FavoriteBorderIcon />
              </IconButton>
              )
            }
          }
        
        }
        else{
          return(
            <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
            <FavoriteBorderIcon />
            </IconButton>
          )
        }
          }
          async function addLikedBeat(data) {
            if(AppState.LoggedIn.loggedIn === true) {
              setlikeload(data)
              await axios.post( `/oauth/users/likedbeat`, {
                  beatID : data,
                  }, {withCredentials: true, credentials: 'include'})
                  .then((response) => {
                    console.log(response)
                    AppState.setisLoggedIn({...AppState.LoggedIn, LikedBeats: response.data.updatedData})
                    setOpen({set : true, message : "Beat Added to LikedBeats!", status : "success"})
                      const storagetech = localStorage.getItem("sp_user_init");
                      const DecodeTech = JSON.parse(storagetech);
                      var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData, "Downloads": DecodeTech.DownloadBeats, "OfferValid" : DecodeTech.OfferValid, "isVerified" : DecodeTech.isVerified };
                      localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                      setlikeload("")
                      AppState.updateUserFunc().then((docs) => {
                        console.log("done")
                      })
                      .catch((err) => {
                        console.log("done")
                      })
                  })
                  .catch((err) => {
                    console.log(err)
                    setOpen({set : true, message : "Something Went Wrong", status : "error"})
                    setlikeload("")
                  })
          
            }
            else{
              Navigate("/login")
          }
            };
}

