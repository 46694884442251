import React from "react";
import mainstyles from "./mainstyle.module.scss"
import DownloadingIcon from '@mui/icons-material/Downloading';
import { AppContext } from "../../utils/stateManagement/appContext";
import { useContext, useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import CachedIcon from '@mui/icons-material/Cached';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';

function Downloads() {
    const AppState = useContext(AppContext)
    const [loading, setloading] = useState(true)
    const [beatList, setbeatList] = useState([])
    const Navigate = useNavigate()
    const [paymentprocessmodel, setpaymentprocessmodel] = useState(false)
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [paymentSnackbar, setpaymentSnackbar] = useState(false)

    useEffect(() => {
        console.log(paymentSnackbar)
    }, [paymentSnackbar])

    useEffect(() => {
        var aColl = document.getElementsByClassName('MainContent');
        changeColor(aColl, "#7f53ac")
}, [])


    useEffect(() => {
        console.log(user)
    }, [user])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setpaymentSnackbar(false);
      };
// useEffect(() => {
//     if(AppState.LoggedIn.loggedIn) {
//       return
//     }
//     else{
//       Navigate("/login")
//     }
// }, [])

  function changeColor(coll, color){
    for(var i=0, len=coll.length; i<len; i++)
    {
        coll[i].style["background-color"] = color
    }
  }

    useEffect(() => {
        console.log(AppState.LoggedIn)
        if (AppState.LoggedIn.Downloads.length === 0){
            return
        }
        else{
            var objmn = []
            AppState.LoggedIn.Downloads.map((plbtoz, myIID) => {
                console.log(AppState.LoggedIn.Downloads, AppState.beatContext)
                var obj = AppState.beatContext.find(o => o._id === plbtoz.beatID);
                if (obj === undefined) {
                    return
                }
                else{
                    objmn.push(obj)
                }
            })
            setbeatList(objmn)
            console.log(objmn)
        }
       
    }, [])
    useEffect(() => {
        if (beatList === []) {
            return
        }
        else{
            setloading(false)
        }
    }, [beatList])

    if(loading) {
        return(
            <div className='Ldr' >
                <div className='dot-flashing'></div>
            </div>
        )
    }
    else{
        if (beatList.length === 0) {      
            return(
                <>
                          <Modal
  open={paymentprocessmodel}
  aria-labelledby="Payment Initiation Waiter"
  aria-describedby="New Transacton Creation"
>
      <div className=" flex justify-center items-center w-screen h-screen">
      <CircularProgress color="secondary" />
      </div>
</Modal>


                 <section>
                    <div className={mainstyles.TitleMain}>
                    <div className={mainstyles.TitleImgD}>
                        <DownloadingIcon/>
                    </div>
                    <div className={mainstyles.TitleInfo}>
                    <h5>Playlist</h5>
                    <h2>Downloads</h2>
                    <h6> {user.name} &middot; {AppState.LoggedIn.Downloads.length} Beats</h6>
                    </div>
                    </div>
                    </section>
                    <div className='Ldr' >
                       <h6>No Data</h6>
                    </div>  
                </>
            )
        }
        else{
            return(
                <>
                          <Modal
  open={paymentprocessmodel}
  aria-labelledby="Payment Initiation Waiter"
  aria-describedby="New Transacton Creation"
>
      <div className=" flex justify-center items-center w-screen h-screen">
      <CircularProgress color="secondary" />
      </div>
</Modal>
    <Snackbar open={paymentSnackbar} anchorOrigin={{vertical: 'top',
          horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="warning">Service Currently Under Maintainence!</Alert>
      </Snackbar>
                <section>
                   <div className={mainstyles.TitleMain}>
                   <div className={mainstyles.TitleImgD}>
                       <DownloadingIcon/>
                   </div>
                   <div className={mainstyles.TitleInfo}>
                   <h5 className="text-sm">Collection</h5>
                   <h2>Downloads</h2>
                   <h6> {AppState.LoggedIn.userName} &middot; {beatList.length} Beats</h6>
                   </div>
                   </div>
                   </section>

 <>

 <div className={mainstyles.b_li_rgt_tpc}>
                <div className={mainstyles.beatInfo}>
                <h5 className="text-sm">BEAT NAME</h5>
                </div>
                <div className={mainstyles.beatbpm}>
                <h5  className="text-sm">BPM</h5>
                </div>
                <div className={mainstyles.beatop}>
                <h5  className="text-sm">OPTIONS</h5>
                </div>
            </div>
              <div className={mainstyles.beaListWrap}>
              {beatList.map((beat, MYID) => (
                  <div className={mainstyles.beatInternal}>
                <div className={mainstyles.beatInfo}>
                <Stack direction="row" spacing={2}>
                <Avatar src={beat.beatimg} alt={beat.beatname} variant="square" />
                  <div className={mainstyles.beatName}>
                  <Typography noWrap gutterBottom component="div">
                {beat.beatname}
                <h5 className="text-xs">SpaceNTime</h5>
                </Typography>
                  </div>
              </Stack>
                </div>
                <div className={mainstyles.beatbpmlist}>
                <h5 className="text-sm">{"#" + beat.beatbpm + "BPM"}</h5>
                  </div>
                  <div className={mainstyles.beatoplist}>
                    {renderDownloadButtons(beat, MYID)}
                  </div>
              </div>
                ))}
              </div>
          
              </>
               </>
            )
        }
    }

    function renderDownloadButtons(beat, MYID) {
        console.log(AppState.LoggedIn.Downloads)
        console.log(AppState.LoggedIn.Downloads[MYID].mode)
        if(AppState.LoggedIn.Downloads[MYID].mode !== undefined ) {
            if(AppState.LoggedIn.Downloads[MYID].mode === "single") {
                return(
                    <Stack direction="row" spacing={1}>
                    <IconButton  color="primary" 
                    onClick={() => {
                        const beatID = beat._id; // Replace with the actual beat ID
                        const trackingID = AppState.LoggedIn.Downloads[MYID].trackingID; // Replace with the actual transaction ID
                        Navigate(`/download-handler-single?beatID=${beatID}&transactionID=${trackingID}`);
                      }}
                    aria-label="delete"><DownloadForOfflineIcon /></IconButton>
                  </Stack>
                )
            }
            else if (AppState.LoggedIn.Downloads[MYID].mode === "subscription"){
                return(
                    <Stack direction="row" spacing={1}>
                    <IconButton  color="primary" 
                                        onClick={() => {
                                            const beatID = beat._id; // Replace with the actual beat ID
                                            const trackingID = AppState.LoggedIn.Downloads[MYID].trackingID; // Replace with the actual transaction ID
                                            Navigate(`/download-handler-sub?beatID=${beatID}&transactionID=${trackingID}`);
                                          }}
                    aria-label="delete"><DownloadForOfflineIcon /></IconButton>
                  </Stack>
                )
            }
            else{
                return(
                    <>
                    </>
                )
            }
        }
        else{
            return(<></>
            // `                        <Stack direction="row" spacing={1}>
            //                         <Button startIcon={<DownloadIcon />} onClick={() => handleContract(beat.beatname, MYID)} size="small">Contract</Button>
            //                         <IconButton  color="primary" onClick={() => Navigate("/downloadprocessback?" + beat._id)} aria-label="delete"><DownloadForOfflineIcon /></IconButton>
            //                     </Stack>`
                            )
        }
    }

    async function runRefresher() {
        setpaymentprocessmodel(true)
        const token = await getAccessTokenSilently()
        axios.post( `/requesthandler/download/gettransactions`, {
          Intent : "list",
          }, { withCredentials: true, 
            credentials: 'include', 
            headers: {
              'Authorization' : `Bearer ${token}`
          }})
        .then((tran) => {
            setpaymentprocessmodel(false)
            window.location.reload()
        })
        .catch((err) => {
            setpaymentprocessmodel(false)
            window.location.reload()
        })
    }

    function handleContract(beatid, id) {
        setpaymentSnackbar(true)
    }
}


export default withAuthenticationRequired(Downloads, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
  });