import featuredstyles from "./mainstyle.module.scss"
import YouTubeIcon from '@mui/icons-material/YouTube';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../utils/stateManagement/appContext";
import mainstyles from "./mainstyle.module.scss"
import Paper from '@mui/material/Paper';

export default function Featured() {
    const AppState = useContext(AppContext)
    const [likeload, setlikeload] = useState("")

    useEffect(() => {
        var aColl = document.getElementsByClassName('MainContent');
        changeColor(aColl, "#080808")
}, [])

  function changeColor(coll, color){
    for(var i=0, len=coll.length; i<len; i++)
    {
        coll[i].style["background-color"] = color
    }
        
}

    return(
        <>
        <Typography mt={1} variant="h6" style={{fontWeight : 700, marginBottom:"30px"}} gutterBottom component="div">
        Featured :
        </Typography>
        {/* <div className={mainstyles.scrollbanner1}>
            <ul className={mainstyles.clientlist1}>
                <li><a href="https://www.youtube.com/watch?v=SbN6NNcNlaQ&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/pacman.png" alt="ref"/><span class="text-white">Pacman Da Gunman </span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=2J8YsMsjB0w&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/ot.png" alt="ref"/><span class="text-white">O.T. Genasis</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=EHNwDTc9D38&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/mozzy.png" alt="ref"/><span class="text-white">Mozzy</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=_bBEAw2wcFA&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Dom.png" alt="ref"/><span class="text-white">Dom Kennedy</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=zngKpeeGdB0&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Boosie.png" alt="ref"/><span class="text-white">Boosie</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=kxJpBdCNtXo&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/G+perico.png" alt="ref"/><span class="text-white">G Perico</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=JO0214lmSNY&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/kurk.png" alt="ref"/><span class="text-white">Kruk One</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=jVgnHkkvAVY&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/ralfy.png" alt="ref"/><span class="text-white">Ralfy The Plug</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=ONKUBClRuQk&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/slim.png" alt="ref"/><span class="text-white">Slim 400</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=tKABToQXxm8&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/the+game.png" alt="ref"/><span class="text-white">The Game</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=dpjusl8aGwk&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/wale.png" alt="ref"/><span class="text-white">Wale</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=x3FA-L8q16M&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/yb.png" alt="ref"/><span class="text-white">Young Buck</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=-_cgr7d0hrw&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/young+to.png" alt="ref"/><span class="text-white">Yhung T.O.</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=dpF8GFrE6II&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/rem.png" alt="ref"/><span class="text-white">Icewear Vezzo</span></div></a></li>
                <li><a href="https://www.youtube.com/watch?v=OUj0mhfQz1s&authuser=0"><div class="artistlist"><img src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/joe+moses.png" alt="ref"/><span class="text-white">Joe Moses</span></div></a></li>
            </ul>
        </div> */}
            <Grid sx={{ flexGrow: 1, paddingBottom: "100px" }} container spacing={2}>
      <Grid item xs={18}>
        <Grid container justifyContent='space-around' spacing={2}>
           {AppState.featuredVids.map((item , btID) => (
               <Grid key={btID} item>
               <Paper style={{width : "83vw", maxWidth: "350px", marginBottom: "20px"}} elevation={0} className={mainstyles.VideoIem} key={btID} onClick={() => {window.open("https://www.youtube.com/watch?v=" + item.Youtube_ID );}}>
        <img src={"https://img.youtube.com/vi/"+ item.Youtube_ID +"/hqdefault.jpg"} alt={item.Youtube_ID} />
        <Typography noWrap variant="h6" style={{fontSize : "16px"}} gutterBottom component="div">
        {item.Name}
        </Typography>
        <Typography noWrap variant="subtitle1" style={{fontSize : "14px"}} gutterBottom component="div">
        {item.views} Views
        </Typography>
        </Paper>
               </Grid>

           ))}
           </Grid>
      </Grid>
</Grid>
        </>
    )
    
}

