import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import { useContext, useState } from 'react';
import { AppContext } from '../utils/stateManagement/appContext';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Button from '@mui/material/Button';
import SliderRender from '../utils/slider';
import { useAuth0 } from '@auth0/auth0-react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export default function MusicPlayer () {
  const {loginWithRedirect, isAuthenticated} = useAuth0();
    const AppState = useContext(AppContext)
    const Navigate = useNavigate()
    const APILINK = process.env.REACT_APP_APIDOMAIN
    var minutes = "0" + Math.floor(AppState.dura / 60);
    var seconds = "0" +  Math.floor(AppState.dura - minutes * 60);
    var dur = minutes.substr(-2) + ":" + seconds.substr(-2);

    const [likeload, setlikeload] = useState("")

    const [open, setOpen] = useState({
      set : false,
      message : "",
      status : "success"
    });

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen({set : false});
    };

    

    function beatplay() {
        if (AppState.currentBeat.isPlaying) {
          AppState.setnowplaying({...AppState.currentBeat, isPlaying : false})
        }else{
          AppState.setnowplaying({...AppState.currentBeat, isPlaying : true})
        }
      }
      
    return(
        <>
        <div className='mscplrtrack'>
        <div  className='InternalPlaySlider'>
        <SliderRender/>
        </div>
        </div>
        <div className="mscplrstack">
        <Stack sx={{cursor : "pointer", padding : "5px", alignItems: "center", height : "100%"}} direction="row" spacing={2}>
      <Avatar src={AppState.currentBeat.beatimage} alt={AppState.currentBeat.beatname} variant="square" sx={{ width: 50, height: 50 }}/>
        <div>
        <div className="StackTypography">
        <Typography noWrap gutterBottom component="div">
      {AppState.currentBeat.beatname}
      <h5 className="text-sm text-gray-400">{AppState.currentBeat.beatbpm + "Bpm"}</h5>
      </Typography>
        </div>

        </div>
    </Stack>
        <div className='InternalPlayer'>
          <IconButton onClick={() => AppState.playbefore()} color='secondary' aria-label="previous">
             <SkipPreviousIcon/>
          </IconButton>
          <IconButton onClick= { () => beatplay()} color='secondary' aria-label="play/pause">
          {AppState.currentBeat.isPlaying ? pausefilledCirc() : playfilledCirc() }
          </IconButton>
          <IconButton onClick={() => AppState.playnext()} color='secondary' aria-label="next">
              <SkipNextIcon />
          </IconButton>
        </div>
          <Plbeat/>
        </div>
        </>
    )

    function Plbeat () {
      if (isAuthenticated) {
        if(AppState.LoggedIn.AccessControl === "Guest") {
          return(
            <Stack sx={{cursor : "pointer", padding : "5px", display : "flex", alignItems : "center"}} direction="row" spacing={2}>
            {/* <LikedSort>{AppState.currentBeat.beatID}</LikedSort> */}
            {/* <Tooltip title="Lyric Pad" placement="top">
            <IconButton color="secondary" onClick={() => AppState.setlyricpad({...AppState.LYRPad, state : true, beatID : AppState.currentBeat.beatID, beatInd : AppState.currentBeat.Ind})} sx={{height : "40px"}}>
            <TextFormatIcon/>
          </IconButton>
          </Tooltip> */}
    
          <Button size="small" startIcon={<AddShoppingCartIcon/>} variant="contained" onClick={() => AppState.handlePurchaseOpen(AppState.currentBeat , [])}>Download Beat</Button>      
            </Stack>
          )
        }
        else{
          return(
            <Stack sx={{cursor : "pointer", padding : "5px", display : "flex", alignItems : "center"}} direction="row" spacing={2}>
            {/* <LikedSort>{AppState.currentBeat.beatID}</LikedSort> */}
            {/* <Tooltip title="Lyric Pad" placement="top">
            <IconButton color="secondary" onClick={() => AppState.setlyricpad({...AppState.LYRPad, state : true, beatID : AppState.currentBeat.beatID, beatInd : AppState.currentBeat.Ind})} sx={{height : "40px"}}>
            <TextFormatIcon/>
          </IconButton>
          </Tooltip> */}
    
          <Button size="small" startIcon={<AddShoppingCartIcon/>} variant="contained" onClick={() => loginWithRedirect({screen_hint: 'signup'})}>Download</Button>      
            </Stack>
          )
        }
      }
      else{
        return(
          <Stack sx={{cursor : "pointer", padding : "5px", display : "flex", alignItems : "center"}} direction="row" spacing={2}>
          {/* <LikedSort>{AppState.currentBeat.beatID}</LikedSort> */}
          {/* <Tooltip title="Lyric Pad" placement="top">
          <IconButton color="secondary" onClick={() => AppState.setlyricpad({...AppState.LYRPad, state : true, beatID : AppState.currentBeat.beatID, beatInd : AppState.currentBeat.Ind})} sx={{height : "40px"}}>
          <TextFormatIcon/>
        </IconButton>
        </Tooltip> */}
  
        <Button size="small" startIcon={<AddShoppingCartIcon/>} variant="contained" onClick={() => {
          AppState.handlePurchaseOpen({
            beatimg : AppState.currentBeat.beatimage,
            _id : AppState.currentBeat.beatID,
          } , []); 
          console.log(AppState.currentBeat)
          }}>Download Beat</Button>      
          </Stack>
        )
      }

    }
    function pausefilledCirc() {
        if (AppState.beatloadingplayer === true) {
          if (AppState.currentBeat.beatID === "0"){
            return(<CloseIcon sx={{ height: 38, width: 38 }}/>)
          }else{
            return(
                <CircularProgress size={38}/>
            )
          }
        }
        else{
          return(<PauseIcon sx={{ height: 38, width: 38 }} />)  
        }
      }
      
      function playfilledCirc() {
        if (AppState.beatloadingplayer === true) {
          if (AppState.currentBeat.beatID === "0"){
            return(<CloseIcon sx={{ height: 38, width: 38 }}/>)
          }else{
            return(
                <CircularProgress size={38}/>
            )
          }
        }
        else{
          return(<PlayArrowIcon sx={{ height: 38, width: 38 }} />)  }
      }

function LikedSort( children ) {
if (AppState.LoggedIn.loggedIn === true) {
if (children.children === "0") {
    return(
        <IconButton sx={{height : "40px"}}  color="secondary" aria-label="delete">
        <FavoriteBorderIcon />
        </IconButton>
    )
}
else{
    if(AppState.LoggedIn.LikedBeats === undefined) {
        return(
          <IconButton sx={{height : "40px"}} color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
          <FavoriteBorderIcon />
          </IconButton>
          )
      }
      else{
        let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
        if(obj) {
          return(
          <IconButton sx={{height : "40px"}} color="success" aria-label="delete">
          <FavoriteIcon />
          </IconButton>
          )
        }else{
          return(
          <IconButton sx={{height : "40px"}} color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
          <FavoriteBorderIcon />
          </IconButton>
          )
        }
      }
}

}
else{
  return(
    <IconButton sx={{height : "40px"}} color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
    <FavoriteBorderIcon />
    </IconButton>
  )
} 
       }

       async function addLikedBeat(data) {
        if(AppState.LoggedIn.loggedIn === true) {
          setlikeload(data)
          await axios.post( `/oauth/users/likedbeat`, {
              beatID : data,
              }, {withCredentials: true, credentials: 'include'})
              .then((response) => {
                console.log(response)
                AppState.setisLoggedIn({...AppState.LoggedIn, LikedBeats: response.data.updatedData})
                setOpen({set : true, message : "Beat Added to LikedBeats!", status : "success"})
                  const storagetech = localStorage.getItem("sp_user_init");
                  const DecodeTech = JSON.parse(storagetech);
                  var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData, "Downloads": DecodeTech.DownloadBeats, "OfferValid" : DecodeTech.OfferValid, "isVerified" : DecodeTech.isVerified };
                  localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                  setlikeload("")
                  AppState.updateUserFunc().then((docs) => {
                    console.log("done")
                  })
                  .catch((err) => {
                    console.log("done")
                  })
              })
              .catch((err) => {
                console.log(err)
                setOpen({set : true, message : "Something Went Wrong", status : "error"})
                setlikeload("")
              })
      
        }
        else{
          Navigate("/login")
      }
        };
    }