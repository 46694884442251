import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../utils/stateManagement/appContext"
import mainstyles from "../../components/MainPages/mainstyle.module.scss"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/material/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useRef } from "react";
import TagIcon from '@mui/icons-material/Tag';
import {HiHashtag} from "react-icons/hi"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useAuth0 } from '@auth0/auth0-react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Lottie from 'react-lottie';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Tab, Tabs, List, ListItem, Paper, Skeleton} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReorderIcon from '@mui/icons-material/Reorder';
import MicIcon from '@mui/icons-material/Mic';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';

const listItemStyle = {
  fontSize: '14px',
  color: 'gray',
};

export default function Listbeat({data}) {
    const [isLoading1, setisLoading] = useState(true)
    const [beatlist, setbeatlist] = useState([])
    const [likeload, setlikeload] = useState("")
    const [dialogPrice, setdialogPrice] = useState("$74.99")
    const AppState = useContext(AppContext)
    const Navigate = useNavigate()
    const [selectedPLAN, setselectedPLAN] = useState(0);
    const { loginWithRedirect, logout, user, getAccessTokenSilently,  isAuthenticated, isLoading } = useAuth0();
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageUrl = AppState.purchaseSelector ? AppState.purchaseSelector.beatimg : '';
    const productID = AppState.ActivePlans[selectedPLAN].ID;

     const handleSubscriptionClick = () => {
      AppState.handlePurchaseClose();
    Navigate(`/cart?checkouttype=subscription&productid=${productID}`);
  };
    console.log(AppState.LoggedIn)
    const handleImageLoad = () => {
      console.log("image Loaded")
      setImageLoaded(true);
    };
    const [anchorEl, setAnchorEl] = useState({
      EL : null,
      element : null
    });
    const openselector = Boolean(anchorEl);
    const [selectedTab, setSelectedTab] = useState(1);

    const handleChange = (event, newValue) => {
      setImageLoaded(false);
      setSelectedTab(newValue);
    };
    const handleselectorClick = (event, value) => {
      setAnchorEl({
        EL : value,
        element : event.currentTarget
      })
    };
    const handleselectorClose = () => {
      setAnchorEl({
        EL : null,
        element : null
      })
      console.log(anchorEl)
    };
  
    const migrateOnClick = (beat, MYID) => {
      AppState.EntryNow(beat._id);
      AppState.setwasplayingrec([...AppState.was, beat._id]);
      AppState.setnowplaying({
        ...AppState.currentBeat,
        Ind: MYID,
        beatID: beat._id,
        beatPrice: beat.beatPrice,
        beatname: beat.beatname,
        beatbpm: beat.beatbpm,
        beatimage: beat.beatimg,
        beatpreview: beat.beatpreview,
        tags: beat.tags,
        beatcolor: beat.color
      });
      if (!AppState.audioRef.current) {
        AppState.audioRef.current = new Audio(beat.beatpreview);
        AppState.audioRef.current.play()
      }
      else{
        AppState.audioRef.current.pause();
        AppState.audioRef.current = new Audio(beat.beatpreview);
        AppState.audioRef.current.play()
      }
    };
  
  
    const TagButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText(grey[900]),
      textTransform : "none",
      backgroundColor: grey[900],
      '&:hover': {
        backgroundColor: grey[800],
      },
    }));
  
    const handleAddToCart = () => {
      AppState.handlePurchaseClose(); setImageLoaded(false);
      const itemToAdd = selectedTab === 0
        ? AppState.purchaseSelector
        : AppState.purchaseSelector;
    
      AppState.addToCart(itemToAdd);
    };

    if (data.length === 0) {
      return(
          <>
            <h5>No Data</h5>
          </>
      )
  }
  else{
      return(
        <>
       <Modal
      open={AppState.purchaseModal}
      onClose={AppState.handlePurchaseClose}
      aria-labelledby="purchase-options-modal-title"
      aria-describedby="purchase-options-modal-description"
      sx={{ zIndex: 12000 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 1800,
          transform: 'translate(-50%, -50%)',
          width: '100%', // Take up maximum horizontal space
          maxWidth: 600, // Limit to a maximum width of 600px
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'white',
          overflowY: 'auto', // Allow scrollbars
          maxHeight: '100vh', // Limit the height to the viewport height
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" id="purchase-options-modal-title" sx={{ display: "flex", justifyContent: "space-between", p: 2, }} gutterBottom>
          Purchase Options
          <Button onClick={() => { AppState.handlePurchaseClose(); setImageLoaded(false); }} color="primary">
            <CloseIcon />
          </Button>
        </Typography>
        <Paper>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="One-Time Purchase" />
            <Tab label="Subscribe" />
          </Tabs>
        </Paper>

        {selectedTab === 0 && (
          <Box p={3} display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              You Only Get This Beat
            </Typography>
            <Box maxWidth={200} maxHeight={200} overflow="hidden">
              {imageLoaded ? (
                <></>
              ) : (
                <Skeleton variant="rectangular" width={200} height={200} />
              )}

              <img
                src={AppState.purchaseSelector ? AppState.purchaseSelector.beatimg : ""}
                alt="SelectedIMG"
                style={{ display: imageLoaded ? 'block' : 'none', width: '100%', height: '100%', objectFit: 'cover' }}
                onLoad={() => handleImageLoad()}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: "100%" }}>
              <Typography variant="subtitle1" sx={{ pt: 2 }}>
                {AppState.purchaseSelector ? AppState.purchaseSelector.beatname : ""}
              </Typography>
              <Accordion sx={{ width: "100%", marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="accordion-content"
                  id="accordion-header"
                >
                  <Typography variant="body1">Usage Terms</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <List>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <MicIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>USED FOR MUSIC RECORDING</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <ReorderIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>UNLIMITED DISTRIBUTION</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <SettingsInputAntennaIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>UNLIMITED ONLINE AUDIO STREAMS</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <EmergencyRecordingIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>UNLIMITED MUSIC VIDEOS</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <MicExternalOnIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>UNLIMITED LIVE PERFORMANCES</Typography>} />
                    </ListItem>
                    {/* Add more list items as needed */}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        )}

{selectedTab === 1 && (
  <Box p={3}>
    {/* Content for "Subscribe" tab */}
    <div className="grid-container">
      {AppState.ActivePlans.map((plan, index) => (
        <div key={index} onClick={() => setselectedPLAN(index)} className={selectedPLAN === index ? "grid-item-active" : "grid-item"}>
          <div>
          <Typography variant="h6">
            {plan.Plan}
          </Typography>
          <Typography variant="subtitle2">
            {plan.Downloads + " DOWNLOADS / M"}
          </Typography>
          </div>

          <Typography variant="body1">
            {`$${plan.Price}/m`}
          </Typography>
        </div>
      ))}
    </div>
     <Accordion sx={{ width: "100%", marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="accordion-content"
                  id="accordion-header"
                >
                  <Typography variant="body1">Usage Terms - {AppState.ActivePlans[selectedPLAN].Plan} </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <List>
                  <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <SettingsInputAntennaIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText 
    primary={
        <Typography style={listItemStyle}>
            { 
                (AppState.ActivePlans && AppState.ActivePlans[selectedPLAN] && AppState.ActivePlans[selectedPLAN].Downloads) 
                    ? AppState.ActivePlans[selectedPLAN].Downloads+ " DOWNLOADS PER MONTH"
                    : "0 DOWNLOADS PER MONTH"
            }
        </Typography>
    } 
/>

                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <MicIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>USED FOR MUSIC RECORDING</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <ReorderIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>{AppState.ActivePlans[selectedPLAN].Distribution_Copies.toUpperCase()} DISTRIBUTION COPIES / BEAT</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <SettingsInputAntennaIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>{AppState.ActivePlans[selectedPLAN].Audio_Streams.toUpperCase()} ONLINE AUDIO STREAMS / BEAT</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <EmergencyRecordingIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}>{AppState.ActivePlans[selectedPLAN].Music_Videos.toUpperCase()} MUSIC VIDEO / BEAT</Typography>} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="!min-w-[20px]">
                        <MicExternalOnIcon style={listItemStyle} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography style={listItemStyle}> LIVE PERFORMANCES</Typography>} />
                    </ListItem>
                    {/* Add more list items as needed */}
                  </List>
                </AccordionDetails>
              </Accordion>
  </Box>
)}        {/* Fixed footer */}
        <Box pl={3} pr={3} pt={2} pb={2} borderTop="1px solid #222222" display="flex" justifyContent="space-between" alignItems="center">
          <div>
            <Typography color="gray" variant="caption">Total</Typography>
            <Typography fontWeight={500} variant="h5">
  {selectedTab === 0
    ? AppState.purchaseSelector ? `$${AppState.purchaseSelector.beatPrice}` : ""
    : `$${AppState.ActivePlans[selectedPLAN].Price}/m`
  }
</Typography>
          </div>
          {selectedTab === 0
    ? <Button variant="contained" color="primary" onClick={handleAddToCart}>Add to Cart</Button> : <Button variant="contained"  onClick={handleSubscriptionClick} color="primary">Checkout</Button> }
 
        </Box>

      </Box>
    </Modal>
          <div className={mainstyles.b_li_rgt_tpc}>
          <div className={mainstyles.beatInfo}>
          <Typography variant="subtitle2" style={{fontWeight : 300, fontSize : "12px"}} gutterBottom component="div">
          BEAT NAME
          </Typography>
          </div>
  
          <div className={mainstyles.beatop}>
          <Typography variant="subtitle2" style={{fontWeight : 300, fontSize : "12px"}} gutterBottom component="div">
          OPTIONS
          </Typography>
          </div>
      </div>
      <div className={mainstyles.beaListWrap}>
      {data.map((beat, MYID) => {
        const myArray = beat.tags.split(",");
          return(
            <div className={mainstyles.beatInternal} key={MYID}>
        <div className={mainstyles.beatInfo}>
        <Stack
  sx={{ cursor: "pointer" }}
  onClick={() => migrateOnClick(beat, MYID)}
  direction="row"
  spacing={2}
>

        <div style={{height : "32px", width : "32px"}} >
        {AppState.currentBeat.beatID === beat._id ? (
<>
<Lottie 
style={{ height: 32 , width: 32, marginTop: "3px"}}
  options={{
    loop: true, 
    path: 'https://assets5.lottiefiles.com/packages/lf20_VE06RZ.json',
  }}/>
</>
        ) : (
<>
<IconButton color="success" aria-label="delete">
        <PlayCircleOutlineIcon />
        </IconButton>
</>
        )}


        </div>
        <LazyLoadImage
  src={beat.beatimg}
  alt={"Beat Image - " + beat.beatname}
  height={40}
  width={40}
/>
          <div className={mainstyles.beatName}>
          <Typography noWrap gutterBottom component="div">
        {beat.beatname}
        <h5 className="!text-sm">{"#" + beat.beatbpm + "BPM"}</h5>
        </Typography>

          </div>
      </Stack>
        </div>
  
          <div className={mainstyles.beatoplist}>
          {RenderOut(beat, myArray)}
          </div>
      </div>
          )
      })}
      </div>
      </>
      )

      function RenderOut(beat, myArray) {
        if(isAuthenticated) {
          if(AppState.LoggedIn.AccessControl === "Guest") {
            return(
              <Stack direction="row" spacing={1}>
              <div className="listcenter tagmain">
                <Stack direction="row" spacing={1}>
                {/* <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[4])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[4]}</Typography></TagButton> */}
            {/* <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[5])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[5]}</Typography></TagButton> */}
            <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[4])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[6]}</Typography></TagButton>
                </Stack>
            </div>
      
              {likeload === beat._id ? <div className="flexon1"><CircularProgress style={{width : "24px", height : "24px"}}/></div> : <LikedSort>{beat._id}</LikedSort> }
              {AppState.LoggedIn.OfferValid && !!beat.offerValid ? (
                <>
                          <div className="listcenter">
            <Button size="small" variant="contained" onClick={() => processDownload(beat._id)}>Download</Button>
            </div>
            <div className="listcentermob">
           <IconButton  color="primary" variant="contained" onClick={() => processDownload(beat._id)}><DownloadForOfflineIcon/></IconButton>
            </div>
                </>
              ) : (
                <>
                              {/* Mobile Version */}
              <div className="listcentermob">
              { AppState.LoggedIn.Downloads.some(download => download.beatID === beat._id) ? 
  <IconButton size="small" variant="outlined" color="success" onClick={() => Navigate("/downloads")}><DownloadForOfflineIcon/></IconButton>: 
  <IconButton size="small" aria-label="delete" variant="contained" color="primary" onClick={() => AppState.handlePurchaseOpen(beat, myArray)}>
  <ShoppingCartIcon/> 
  </IconButton>
}
          </div>
            {/* Desktop Version */}
            <div className="listcenter">
            { AppState.LoggedIn.Downloads.some(download => download.beatID === beat._id) ? 
  <Button size="small" startIcon={<DownloadForOfflineIcon/>} variant="outlined" onClick={() => Navigate("/downloads")}>Purchased</Button> : 
<Button size="small" startIcon={<ShoppingCartIcon/>} variant="contained" onClick={() => AppState.handlePurchaseOpen(beat, myArray)}>download beat</Button>
}
            
          </div>
                </>
              )}

            </Stack>
            )
          }
          else{
            return(
              <Stack direction="row" spacing={1}>
              <div className="listcenter tagmain">
                <Stack direction="row" spacing={1}>
                {/* <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[4])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[4]}</Typography></TagButton> */}
            {/* <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[5])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[5]}</Typography></TagButton> */}
            <TagButton size="small" startIcon={<HiHashtag/>} variant="contained" onClick={() => Navigate("/search?" + myArray[4])}><Typography noWrap gutterBottom component="div" sx={{fontSize : "0.8125rem !important", marginBottom : "0px !important" }}>{myArray[6]}</Typography></TagButton>
                </Stack>
            </div>
      
              {likeload === beat._id ? <div className="flexon1"><CircularProgress style={{width : "24px", height : "24px"}}/></div> : <LikedSort>{beat._id}</LikedSort> }
            
            <div className="listcenter">
            <Button size="small" variant="contained" onClick={() => processDownload(beat._id)}>Download</Button>
            </div>
            <div className="listcentermob">
           <IconButton  color="primary" variant="contained" onClick={() => processDownload(beat._id)}><DownloadForOfflineIcon/></IconButton>
            </div>
            </Stack>
            )
          }
        }
        else{
          return(
            <>
            <Stack direction="row" spacing={1} sx={{paddingTop : "5px"}}>
            <div className="listcentermob">
            <IconButton size="small" aria-label="delete" variant="contained" color="primary" onClick={() => AppState.handlePurchaseOpen(beat, myArray)}>
      <ShoppingCartIcon/> 
      </IconButton>
            {/* <Button size="small" startIcon={} variant="contained" onClick={() => loginWithRedirect({screen_hint: 'signup'})}></Button> */}
          </div>
            <div className="listcenter">
              
            <Button size="small" startIcon={<ShoppingCartIcon/>} variant="contained" onClick={() => AppState.handlePurchaseOpen(beat, myArray)}>download beat</Button>
          </div>
           </Stack>
            </>
          )
        }
    
      }  

  }
    function LikedSort( children ) {
  if (isAuthenticated) {
    if(AppState.LoggedIn.LikedBeats === undefined) {
      return(
        <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
        <FavoriteBorderIcon />
        </IconButton>
        )
    }
    else{
      let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
      if(obj) {
        return(
        <IconButton color="success" aria-label="delete">
        <FavoriteIcon />
        </IconButton>
        )
      }else{
        return(
        <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
        <FavoriteBorderIcon />
        </IconButton>
        )
      }
    }
  
  }
  else{
    return(
      <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
      <FavoriteBorderIcon />
      </IconButton>
    )
  }
  
    }
    function processDownload(Val) {
        Navigate("/download?" + Val);
    }
  
    async function addLikedBeat(data) {
      if(isAuthenticated) {
        console.log(data)   
        setlikeload(data)
        const token = await getAccessTokenSilently();
        
        await axios.post( `/oauth/users/likeaction`, {
            beatID : data,
            }, 
        { withCredentials: true, 
          credentials: 'include', 
          headers: {
            'Authorization' : `Bearer ${token}`
        }})
            .then((response) => {
              console.log(response)
              AppState.updateUserFunc().then((docs) => {
                console.log("done")
              })
              .catch((err) => {
                console.log("done")
              })
              //   const storagetech = localStorage.getItem("sp_user_init");
              //   const DecodeTech = JSON.parse(storagetech);
              //   var userdt = { 'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData };
              //   localStorage.setItem("sp_user_init", JSON.stringify(userdt));
                setlikeload("")
            })
            .catch((err) => {
              console.log(err)
              setlikeload("")
            })
    
      }
      else{
        return
    }
      };
  
}