import { AppContext } from "../stateManagement/appContext"
import { useState, useContext, useEffect } from "react"
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate , useLocation} from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Avatar from '@mui/material/Avatar';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import mainstyles from "../../components/MainPages/mainstyle.module.scss"
// import {Logout as LogoutIcon} from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios";
import Styles from "./topbar.module.scss"
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import { useAuth0 } from '@auth0/auth0-react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { LazyLoadImage } from "react-lazy-load-image-component";
import CloseIcon from '@mui/icons-material/Close';

export default function TopBar() {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
  const AppState = useContext(AppContext)
    const Navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth < 768);
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

      function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: name.charAt(0),
        };
      }

    return(
        <>
        <section className={Styles.Topbar}>
        <TopbarNav/>
        <div className={Styles.TopbarSignin}>
        <MainNavSwitch/>
        {/* <Button  size="small" variant="contained">Log Out</Button> */}
        </div>
        </section>
        </>
    )

    function MainNavSwitch() {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
  
      const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };


      if(isAuthenticated) {
        if (user.email === undefined) {
          return
        }
        else{
          return(
            <>
            <Stack spacing={1} direction="row">
            {isMobile ? (
    <IconButton onClick={() => Navigate("/recentlyplayed")} color="secondary">
      <HistoryToggleOffRoundedIcon />
    </IconButton>
  ) : (<></>)}
            {/* <IconButton color="secondary" onClick={() => history("/notifications")}>
              <NotificationsActiveRoundedIcon />
            </IconButton> */}
            
             <div>
             <Button onClick={handleClick}  variant="text" size="small" endIcon={<ArrowDropDownIcon />}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
        <Avatar {...stringAvatar(user.name)}  sx={{ width: 24, height: 24 }}/>
        </Button>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor : "#080808",
            overflow: 'visible',
            border : "1px solid #141414",
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& svg': {
                color : "white",
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
                content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#080808',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
              <MenuItem onClick={() => Navigate("/account")}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
             </div>
             <CartView/>
            </Stack>
            </>
          )
        };
      }else{
        return(
          <>
        <Stack  spacing={2} direction="row">
        <Button onClick={() => loginWithRedirect()} size="small" variant="text">Sign In</Button>
        <Button onClick={() => loginWithRedirect({screen_hint: 'signup'})} size="small" variant="contained">Sign Up</Button>
        <CartView className="mr-2" />
        </Stack>
          </>
        )
      }
    }


  
  //   async function logout() {
  //     localStorage.removeItem("sp_user_init");
  //     await axios.delete( `/oauth/users/logout`, {}, {withCredentials: true, credentials: 'include'})
  //     .then(res => {
  //       history("/")
  //         window.location.reload();
  //     })
  //     .catch(err => {
  //       history("/")
  //         window.location.reload();
  //     })
  // }

}

function CartView() {
  const [cartOpen, setCartOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const AppState = useContext(AppContext);
  const Navigate = useNavigate();
  const handleClick = (event) => {
    setCartOpen(!cartOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCartOpen(false);
    setAnchorEl(null);
  };


  return(
    <>
<IconButton color="secondary" size="small" onClick={handleClick}>
  {AppState.cartCont.length > 0 ? (
    <Badge badgeContent={String(AppState.cartCont.length)} color="primary">
      <ShoppingCartIcon fontSize="inherit" size="small" />
    </Badge>
  ) : (
    <ShoppingCartIcon  fontSize="small"/>
  )}
</IconButton>

<Menu
      open={cartOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          bgcolor : "#080808",
          overflow: 'visible',
          border : "1px solid #141414",
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& svg': {
              color : "white",
          },
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
              content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: '#080808',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box
        sx={{
          minWidth: 280,
          maxWidth: 400,
          minHeight: 100,
          maxHeight: 500,
          overflowY: 'auto',
          padding: 2,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h6">Your Cart ({AppState.cartCont.length})</Typography>
        </div>
        <ul>
          {AppState.cartCont.length === 0 ? (
            <Typography sx={{ marginTop: '20px' }}>No items in the cart</Typography>
          ) : (
            AppState.cartCont.map((item, index) => (
              <li key={index} className="mt-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <LazyLoadImage
                      src={item.image}
                      alt={"Beat Image - " + item.name}
                      height={40}
                      width={40}
                    />
    <div className="ml-2 w-[80px]">
  <Typography variant="body1" className="truncate">
    {item.name}
  </Typography>
  <Typography variant="caption" className="!m-0">TRACK</Typography>
</div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <Typography variant="subtitle1" className="!m-0 !font-bold">{`$${item.price}`}</Typography>
                    <IconButton color="secondary" onClick={() => AppState.removeFromCart(item.id)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
        <div className="mt-4">
          <Button
            disabled={AppState.cartCont.length === 0}
            size="small"
            fullWidth
            variant="contained"
            onClick={() => Navigate("/cart")}
          >
            Checkout
          </Button>
        </div>
      </Box>
    </Menu>
{/* <Popover
  open={cartOpen}
  anchorEl={anchorEl}
  onClose={handleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
>
  <Box
    sx={{
      minWidth: 300,
      maxWidth: 300,
      minHeight: 100,
      maxHeight: 500,
      overflowY: 'auto', // Enable vertical scrolling
      padding: 2, // Add padding to the content
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Typography variant="h6">Your Cart ({String(AppState.cartCont.length)})</Typography>
      </div>
    </div>
    <ul>
      {AppState.cartCont.length === 0 ? (
        <Typography sx={{marginTop : "20px"}}>No items in the cart</Typography>
      ) : (
        AppState.cartCont.map((item, index) => (
          <li key={index} className="mt-4">
              <div>
                <div className="flex justify-between">
                <div className="flex items-center">
                <LazyLoadImage
  src={item.image}
  alt={"Beat Image - " + item.name}
  height={40}
  width={40}
/>
                </div>
          <div className={mainstyles.beatName}>
          <Typography noWrap >
        <text className="font-bold">{item.name}</text>
        <h6 className="text-xs text-gray-400">TRACK</h6>
        </Typography>

          </div>
          <div>
          <IconButton color="secondary" onClick={() => AppState.removeFromCart(item.id)}>
            <CloseIcon/>
          </IconButton>
          </div>
                </div>
                {/* <Typography variant="subtitle1">{item.name}</Typography>
                <Typography variant="body2">Price: ${item.price}</Typography>
                <Typography variant="body2">Quantity: {item.quantity}</Typography> */}
              {/* </div> */}
              {/* Add any additional actions or buttons here */}
            {/* </li>
        ))
      )}
    </ul>
    <div  className="mt-4"><Button disabled={AppState.cartCont.length === 0} size="small" fullWidth variant="contained" >Checkout</Button></div>
  </Box>
</Popover> */}
    </>
  )

}

function TopbarNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      <div className={isHomePage ? Styles.deskIcon : Styles.Icon}>
        {location.pathname !== '/' && (
          <IconButton
            onClick={() => navigate(-1)} // Go back
            size="small"
            color="primary"
            sx={{ marginLeft: '10px' }}
            aria-label="go-back"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </div>
      {isHomePage && (
        <img
          onClick={() => navigate("/")} // Navigate to the homepage
          src="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png"
          alt="Mobile Logo"
        />
      )}
    </>
  );
}