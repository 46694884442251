import Container from '@mui/material/Container';
import { useEffect, useContext } from 'react';
import { AppContext } from '../../utils/stateManagement/appContext';

export default function SwitchtoAuthenticate() {
const AppState = useContext(AppContext)
useEffect(() => {
    console.log("Auth Enabled")
    AppState.setAuthEnable(true)
}, [])

    return(
        <>
<section className='containerMain'>
        <Container>
          <div className='Ldr' >
          <div id='MainLoader' className='LoaderInternal'>
          <img style={{cursor : "pointer"}} src="https://spacetrumentalsbeatinfo.s3.us-west-1.amazonaws.com/spacetrumentals-logo-Transparent-Background.png" alt="Spacetrumentals Logo" width={250}/>
          <h5>Loading...</h5>
            <div className='dot-flashing'></div>
          </div>
          </div>
        </Container>
      </section>
        </>
    )
}