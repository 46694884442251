



import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../utils/stateManagement/appContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Typography, TextField, Grid, Paper, Divider, Button, IconButton, Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemIcon, ListItemText, List, Avatar, CircularProgress } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Subscriptions as SubscriptionsIcon, Reorder as ReorderIcon, Mic as MicIcon, SettingsInputAntenna as SettingsInputAntennaIcon, EmergencyRecording as EmergencyRecordingIcon, MicExternalOn as MicExternalOnIcon } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SellIcon from '@mui/icons-material/Sell';
import { useNavigate } from "react-router-dom";
import { loadStripe} from "@stripe/stripe-js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PaymentForm from "../paymentForm/paymentForm"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {PaymentElement, CardElement, Elements } from '@stripe/react-stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  useStripe,
  useElements,

} from "@stripe/react-stripe-js";
import axios from 'axios';
import StripeInput from '../../utils/stripeInput';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const listItemStyle = {
    fontSize: '14px',
    color: 'white',
};

const cardStyles = {
    base: {
        fontSize: '18px',
        color: '#000',
        backgroundColor: '#fff',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
    },
};

const appearance = {
  theme: 'flat',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: '1.5',
    borderRadius: '10px',
    colorBackground: '#F6F8FA',
    colorPrimaryText: '#262626'
  },
  rules: {
    '.Block': {

      boxShadow: 'none',
      padding: '12px'
    },
    '.Input': {
      padding: '12px'
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray'
    },
    '.Tab': {
      padding: '10px 12px 8px 12px',
      border: 'none'
    },
    '.Tab:hover': {
      border: 'none',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      border: 'none',
      backgroundColor: '#fff',
      boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Label': {
      fontWeight: '500'
    }
  }
};


const stripePromise = loadStripe('pk_live_NUQgQ3DOngDlZxd8ks8YwfBK006vG1b6K1');

//  const stripePromise = loadStripe('pk_test_vWafKtWl03aXpR9zg4qPh2xa00Syvcmbut');

function Cart() {
  const Navigate = useNavigate()
    const {getAccessTokenSilently} = useAuth0();
    const location = useLocation();
    const {user, isAuthenticated, loginWithRedirect } = useAuth0();
    const AppState = useContext(AppContext);
    const [subscription, setSubscription] = useState(null);
    const [checkoutType, setCheckoutType] = useState(null);
    const [CLIENTsec, setCLIENTsec] = useState(null)
    const [isProcessing, setisProcessing] = useState(false)
    const [retryCount, setRetryCount] = useState(0);
    const [emailVerified, setemailVerified] = useState(false)
    const [email, setEmail] = useState('');
    const [emopen, setemOpen] = useState(false)
    const [total, settotal] = useState(0)
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };

    useEffect(() => {
      const totalPrice = AppState.cartCont.reduce((sum, item) => sum + item.price, 0);
      const roundedTotalPrice = parseFloat(totalPrice.toFixed(2));
      settotal(roundedTotalPrice)


    }, [AppState.cartCont])
  
    const handleemailClose = () => {
      setEmail("")
      setemOpen(false)
    };
    const [isLoadingEmail, setisLoadingEmail] = useState(false);
    function handleemailSubmit() {
      // You can add your logic here to handle the email input
      if (email !== "") {
        console.log('Email submitted:', email);
  
        console.log()
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        if(emailRegex.test(email)) {
          setisLoadingEmail(true);
          const itemsArr = AppState.cartCont.map(item => ({ id: item.id }));
          axios.post("/handle/payment/create-payment-intent", 
          { items: itemsArr,  email : email  , type : "guest" })
              .then(response => {
                  if (response.status === 200 && response.data.clientSecret) {
                      console.log("done successfully")
                      setCLIENTsec(response.data.clientSecret);
                      setemailVerified(true)
                      setemOpen(false)
                  } else {
                    setisLoadingEmail(false);
                      console.error("Failed to fetch clientSecret.");
                  }
              })
              .catch(error => {
                setisLoadingEmail(false);
                  // Handle the error gracefully, maybe show a user-friendly message.
                  console.error("Error fetching clientSecret:", error.message);
              });
        }else{
          AppState.setNotification({ type: 'error', message: 'Invalid Email Address' });
          return
        }
      } else{
        return
      }

    };

    useEffect(() => {
      setCLIENTsec(null)
      handleemailSubmit()
    }, [AppState.cartCont])

    const [tranStatus, settranStatus] = useState("");
    const options = {
        // passing the client secret obtained from the server
        clientSecret: CLIENTsec,
        appearance,
        business: "Spacey Music",
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: false,
          spacedAccordionItems: true
        }
      };
      const [open, setOpen] = React.useState({
        state : false,
        type : ""
      });

      useEffect(() => {
        if (checkoutType === "single") {
           setCLIENTsec(null)
          if (isAuthenticated){
            const itemsArr = AppState.cartCont.map(item => ({ id: item.id }));
            console.log("single transaction running")
            if (itemsArr.length > 0) {
              console.log(itemsArr)
                axios.post("/handle/payment/create-payment-intent", 
                { items: itemsArr,  email : user.email  , type : "user" })
                    .then(response => {
                      
                        if (response.status === 200 && response.data.clientSecret) {
                            console.log("done successfully")
                            setCLIENTsec(response.data.clientSecret);
                        } else {
                            // Handle this case based on your application's need.
                            console.error("Failed to fetch clientSecret.");
                        }
                    })
                    .catch(error => {
                        // Handle the error gracefully, maybe show a user-friendly message.
                        console.error("Error fetching clientSecret:", error.message);
                    });
            }
          }
        }
    }, [checkoutType, isAuthenticated, AppState.cartCont]);
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen({state : false, type : ""});
      };
    
      function redopaYMENT() {
        settranStatus("")
        setisProcessing(false)
      }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const checkouttype = searchParams.get('checkouttype');
        const productid = searchParams.get('productid');

        if (checkouttype && productid && checkouttype === 'subscription') {
            const foundPlan = AppState.ActivePlans.find(plan => plan.ID === productid);
            if (foundPlan) {
                setSubscription(foundPlan);
                setCheckoutType(checkouttype);
            }
        }
        else{
          setCheckoutType("single");
        }
    }, [location.search, AppState.ActivePlans]);

    const handleRegisterClick = () => {
        loginWithRedirect({
            screen_hint: 'signup',
            redirectUri: window.location.href
        });
    };

  const renderSubscriptionDetails = () => {

    function CheckoutForm({subscription}) {

    const cardsLogo = [
        "amex",
        "cirrus",
        "diners",
        "dankort",
        "discover",
        "jcb",
        "maestro",
        "mastercard",
        "visa",
        "visaelectron",
    ];
      // collect data from the user
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [priceId, setPriceId] = useState("");
      
      // stripe items
      const stripe = useStripe();
      const elements = useElements();
          function RenderButton() {
        return(
          <>
                    <Button startIcon={<CreditScoreIcon/>} onClick={createSubscription} variant='contained' disabled={!stripe || isProcessing}>
             Subscribe
            </Button></>
        )
      }
      useEffect(() => {
        console.log(tranStatus)
      }, [tranStatus])
      // main function
      const createSubscription = async () => {
        
        try {
          if (isProcessing) {
            return;
          }

          // create a payment method
          const paymentMethod = await stripe?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardNumberElement),
            billing_details: {
              name,
              email : user.email,
            },
          });  



          setisProcessing(true);
          const token = await getAccessTokenSilently()
          // call the backend to create subscription
          const response = await axios.post('/handle/payment/create-subscription', {
            paymentMethod: paymentMethod?.paymentMethod?.id,
            name,
            email: user.email,
            priceId: subscription.ID,
            // priceId : "price_1PAapMDXF6w43UCcoBCwYZjR"
          }, { withCredentials: true, 
            credentials: 'include', 
            headers: {
              'Authorization' : `Bearer ${token}`
          }});

          console.log("RESPONSE", response);
    
          const confirmPayment = await stripe?.confirmCardPayment(
            response.data.clientSecret
          );
    
          if (confirmPayment?.error) {
            settranStatus("error")
            setOpen({state : true, type : "error"});

          } else {
            await axios.post('/handle/payment/subscription-complete', {
              subID: response.data?.subscriptionId,
            }, { withCredentials: true, 
              credentials: 'include', 
              headers: {
                'Authorization' : `Bearer ${token}`
            }}).then((data) => {
              settranStatus("success")
              setOpen({state : true, type : "success"});
              console.log(confirmPayment)
              setTimeout(() => {
                Navigate("/");
                window.location.reload()
              }, 3000)
            })
            .catch((err) => {
              console.log(err)
              settranStatus("error")
              setOpen({state : true, type : "error"});
            })
            

    
          }
        } catch (error) {
          settranStatus("error")
          console.log(error);
          setOpen({state : true, type : "error"});

        }
      };
      const textFieldStyle = {
        backgroundColor: 'white', // Set the background color to white
        color: 'black', // Set the text color to black
      };
      const placeholderStyle = {
        color: 'gray', // Set the placeholder text color to gray
      };
      const labelStyle = {
        color: 'gray', // Set the label text color to gray
      };


function PaymentStatus() {
  if(tranStatus === "") {
    return (
      <>
      <span><CircularProgress size="40px"/></span> Processing Payment
      </>
    )
  }
  else if(tranStatus === "success") {
    return (
      <>
      <span><CheckCircleOutlineIcon sx={{color : "green"}} size="40px"/></span> Payment Successful
      <h6 className='text-xs'>Redirecting....</h6>
      </>
    )
  }
  else if(tranStatus === "error") {
    return (
      <>
      <div className='w-full'>
      <Button onClick={() => redopaYMENT()} startIcon={<ArrowBackIcon size="small" />}  size="small">Retry Payment</Button>
      </div>
      <span><CloseIcon sx={{color : "red"}} size="40px"/></span> Payment Failed
      </>
    )
  }

}
      
      return (
        <>
               <div className='mb-2 text-sm text-gray-700'><Divider>Subscribe with your card</Divider></div>
               {isProcessing ? <>
                <div className='flex items-center justify-center'>
                     <div className='bg-[#F2F2F2] flex-col transition-all p-4 w-full rounded-lg flex items-center gap-4 text-black'>
                     <PaymentStatus/>
</div>
      </div>
               </> : <>
        <div className="grid mt-4 gap-4 m-auto transition-all bg-[#F2F2F2] p-4 w-full rounded-lg">

            <div className='pl-3'>
            <h6 className="text-black text-xs">Email: </h6>
          <h6 className='text-black font-bold'>{user.email}</h6>
            </div>
          <TextField id="Card Name" 
          label="Name On Card" placeholder="Name On Card" value={name} onChange={(e) => setName(e.target.value)} 
          style={textFieldStyle}       
          InputProps={{
        style: placeholderStyle, // Apply the custom style for the placeholder text
      }}
      InputLabelProps={{
        style: labelStyle, // Apply the custom style for the label text
      }}
      />

            <TextField
                label="Card Number"
                name="ccnumber"
                variant="outlined"
                required
                fullWidth

                style={textFieldStyle}       
                InputLabelProps={{ shrink: true, style: labelStyle, }}
                InputProps={{
                    inputComponent: StripeInput,
                    style: placeholderStyle,
                    inputProps: {
                        component: CardNumberElement
                    },
                }}
            />
            <Grid container item>
        <Grid item xs={6} sm={6}>
            <TextField
                label="Expiration Date"
                name="ccexp"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true, style: labelStyle, }}
                InputProps={{
                    inputComponent: StripeInput,
                    style: placeholderStyle,
                    inputProps: {
                        component: CardExpiryElement
                    },
                }}
            />
        </Grid>
        <Grid item xs={6} sm={6}>
            <TextField
                label="CVC"
                name="cvc"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true, style: labelStyle, }}
                InputProps={{
                    inputComponent: StripeInput,
                    style: placeholderStyle,
                    inputProps: {
                        component: CardCvcElement
                    },
                }}
            />
        </Grid>
        </Grid>
              <RenderButton/>
                <div className='text-purple-900 underline flex gap-2 justify-between text-xs'>
                <a href='https://www.spaceymusic.com/terms'>Terms and Conditions</a>
                <Divider orientation="vertical" flexItem/>
              <a href='https://www.spaceymusic.com/privacy'>Privacy Policy</a>
                </div>
                <div className='flex gap-4 items-center justify-center'>
                {/* <h6 className='text-xs text-black'>Accepted Cards:</h6> */}
              <img className='w-[150px]' src='https://exr-systems.s3.amazonaws.com/rapid_digital/Accepted+Cards+1.png' alt='Accepted Cards' />
                </div>

        </div>
        <p className='text-xs text-gray-400 mt-2 pl-4 pr-4 text-justify'>By confirming your subscription, you allow Spacey Music to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.</p>
        </>}
        </>
      );
    }


    return (
        <>
                <Grid item xs={12} md={12} lg={7}>
                  <h5 className='mt-4 mb-2'>Subscribe to SpaceyMusic</h5>
                  <div className='flex items-center gap-2 leading-tight mb-8'>
                    <h1 className='font-medium'>$ {subscription.Price}.00</h1>
                    <h6 className='text-sm leading-tight'>Per<br/>Month</h6>
                  </div>
                  {/* <div className='p-1 pl-2 pr-2 flex justify-end'> <Button fullWidth startIcon={<SellIcon/>}  variant="outlined">Apply Coupons</Button> </div> */}
                  <Accordion expanded={true} sx={{ width: "100%", marginTop: '5px', display: 'flex', flexDirection: 'column'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                
                aria-controls="accordion-content"
                id="accordion-header"
              >
                <Typography variant="body1">Usage Terms - {subscription.Plan} </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {/* <h6 className='p-2 text-yellow-700'>While our subscription offers unlimited downloads, we enforce a brief time-based download restriction to safeguard our resources and maintain optimal service quality</h6> */}
                <List>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <SettingsInputAntennaIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}>{subscription.Downloads} DOWNLOADS / MONTH</Typography>} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <MicIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}>USED FOR MUSIC RECORDING</Typography>} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <ReorderIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}>{subscription.Distribution_Copies.toUpperCase()} DISTRIBUTION COPIES / BEAT</Typography>} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <SettingsInputAntennaIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}>{subscription.Audio_Streams.toUpperCase()} ONLINE AUDIO STREAMS / BEAT</Typography>} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <EmergencyRecordingIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}>{subscription.Music_Videos.toUpperCase()} MUSIC VIDEO / BEAT</Typography>} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="!min-w-[20px]">
                      <MicExternalOnIcon style={listItemStyle} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={listItemStyle}> LIVE PERFORMANCES</Typography>} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
      </Grid>
      <Grid item xs={12} md={12} lg={5}>
        
        <Paper style={{ minHeight: 100, backgroundColor: 'white', height: "100%" }} className="p-4">
          {isAuthenticated ? (
    <div>
    <Elements stripe={stripePromise}  >
      <CheckoutForm subscription={subscription}/>
    </Elements>
  </div>
) : (
  <div className="mt-2">
    <Button variant="contained" fullWidth onClick={handleRegisterClick}>
      Step 1: Create an Account
    </Button>
  </div>
)}
        </Paper>
        </Grid>
        </>
    );
  };


  const renderStandardSummary = () => {
    if(AppState.cartCont.length <= 0) {
      return(
        <>
                        <h6 className='text-lg leading-tight pl-2 md:pl-4 p-2 font-bold'>No Items in Cart</h6>
        </>
      )
    }
    else{
      return (
        <>
            <Grid item xs={12} md={12} lg={7}>
              {/* <h5 className='mt-4 mb-2'>Subscribe to {subscription.Plan}</h5> */}
              <div className='leading-tight mb-8 bg-black p-1 md:p-4 rounded-lg'>
                {/* <h1 className='font-medium'>$ {subscription.Price}.00</h1> */}
                <h6 className='text-lg leading-tight pl-2 md:pl-4 p-2 font-bold'>Cart Summary</h6>
              <div className='flex flex-col list-none md:p-2'>
              {AppState.cartCont.map((item, index) => {
                  return(
                    <>
                                            <li key={index} className="mt-2 md:mt-4 rounded-lg p-2">
            <div className="flex justify-between gap-4 items-center">
              <div className="flex items-center">
                <LazyLoadImage
                  src={item.image}
                  alt={"Beat Image - " + item.name}
                  height={40}
                  width={40}
                />
<div className="ml-2">
<Typography variant="body1" >
{item.name}
</Typography>
</div>
              </div>
              <div className="flex gap-2 items-center">
                <Typography variant="subtitle1" className="!m-0 !font-bold">{`$${item.price}`}</Typography>
                <IconButton color="secondary" onClick={() => AppState.removeFromCart(item.id)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </li></>
                  )
                })}
              </div>
              <Divider sx={{marginTop : "20px"}}/>
              <div className='pr-6 p-2'>
              <h4 className='text-right text-sm mt-4 text-gray-400'>SubTotal : ${total}</h4>
              <h4 className='text-right text-sm mt-1 text-gray-400'>Tax : $0</h4>
                <h4 className='text-right mt-4 font-bold'>Total : ${total}</h4>
              </div>
              </div>
              {/* <div className='p-1 pl-2 pr-2 flex justify-end'> <Button fullWidth startIcon={<SellIcon/>}  variant="outlined">Apply Coupons</Button> </div> */}
  </Grid>
  <Grid item xs={12} md={12} lg={5}>
    
    <Paper style={{ minHeight: 100, backgroundColor: 'white',  }} className="p-4">
      {isAuthenticated ? (
<div>
  <div className='p-4'>
{CLIENTsec && (
    <Elements options={options} stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  )}      </div>
</div>
) : (
<GuestCheck/>
)}
    </Paper>
    </Grid>
    </>

)
    }
}


function GuestCheck() {
  const [isloading, setisloading] = useState(false)
  if(emailVerified) {
    return(
      <div>
      <div className='p-2'>
    {CLIENTsec ? (
<>
        <h6 className='text-black font-bold'>Payment Methods</h6>
        <div className='p-2 pt-4'>
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm type="guest" />
        </Elements>
        </div>
</>
      ) : (
        <TextField
          label="Enter Email"
          variant="outlined"
          fullWidth
          // You can add more props and event handlers as needed
        />
      )}      </div>
    </div>
    )
  }
  else{
    return(
      <>
      <Button disabled={isloading} variant='contained' onClick={() => {setisloading(true); setemOpen(true)}} fullWidth>Checkout as Guest</Button>
      <h6 className='text-black text-sm mt-4'>
        Please Note: <br/><br/>
        <span className='text-red-400'>Utilizing our Guest Checkout option grants you a one-time download access to your purchased resources. </span><br/><br/>
        <span>To gain access to your comprehensive download history, we kindly invite you to either <span className='text-blue-600 cursor-pointer' onClick={() => handleRegisterClick()}>create an account or log in.</span></span>
      </h6>
      </>
    )
  }
}


    return (
        <>
            <div className='pt-2'>
            <Snackbar open={open.state} autoHideDuration={6000} onClose={handleClose}>
            {open.type === "success" ? <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Payment Successfull
            </Alert> : <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Payment Method Error
            </Alert>}
      </Snackbar>
                <Typography variant="h4" component="h4" gutterBottom>
                    {checkoutType === "subscription" ? "Checkout" : "Cart"}
                </Typography>
                <Grid container spacing={2} className='pt-2'>
                    {checkoutType === "subscription" ? renderSubscriptionDetails() : renderStandardSummary()}
                </Grid>
            </div>
            <Dialog  open={emopen} onClose={handleemailClose}>
        <div className='bg-[#080808] min-w-[150px] max-w-[400px] w-[80vw]'>
        <DialogTitle>Enter Email Address</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          variant='filled'
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
        <div className='mt-4 text-xs'>
          This email will be used to deliver transactional emails.
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleemailClose} color="primary">
          Cancel
        </Button>
        <Button disabled={isLoadingEmail} onClick={() => handleemailSubmit()} color="primary">
          Submit
        </Button>
      </DialogActions>
        </div>
    </Dialog>
        </>
    );






    

}

export default Cart;
