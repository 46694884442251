import './App.scss';
import React, {useState, useEffect, useContext} from 'react';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {AppContext } from './utils/stateManagement/appContext';
import AppInterface from './App_Interface';
import axios from 'axios';
import 'typeface-roboto';
import { gsap } from "gsap";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useContent } from './utils/stateManagement/contentContext';
import GoogleAnalytics from './components/GoogleAnalytics';
import { SnackbarProvider, useSnackbar } from 'notistack'
const primaryColor = '#8c14fc ';
const secondaryColor = '#FFFFFF'; // A light color for secondary elements

// Create the MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    mode: 'dark', // Enable dark mode for the entire app
  },
});

export default function App() {
  const Navigate = useNavigate()
  const AppContent = useContent()
  const AppState = useContext(AppContext)
  const APILINK = process.env.REACT_APP_APIDOMAIN
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, handleRedirectCallback } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  //UseEffect Loader//
  useEffect(() => {
    loadbeats()
    // console.log("eeco")
    handleRedirectCallback().then((result) => {
      Navigate(result.appState.target) // Logs the state passed in the loginWithRedirect function
    }).catch((error) => {
      return
    });
  }, [])

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        console.log('SW registered: ', registration);
      }).catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }

  useEffect(() => {
    console.log("tylogss ", isLoading)
    const getUserMetadata = async () => {
      const domain = "oauth-spacetrumentals.us.auth0.com";
      
  
      try {
        console.log("tylogs ");
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
        console.log("tylog ", user_metadata);
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log("tylogs ", e);
      }
    };
    if(isLoading==true) {getUserMetadata();}
  }, [isLoading]);


  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
  
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
  
        const response = await axios.get(`/oauth/users/getnewdata`, {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        console.log("userDetails", response.data);
  
        AppState.setisLoggedIn({
          ...AppState.LoggedIn,
          userID: response.data._id,
          userName: response.data.username,
          userEmail: response.data.useremail,
          AccessControl: response.data.userRole,
          Downloads: response.data.DownloadsTotal,
          LikedBeats: response.data.LikedTotal,
          OfferValid : response.data.OfferValid,
          isVerified : response.data.isVerified
        });
      } catch (err) {
        console.error("userData Load Failed")
      }

    };
  
    fetchData();
  }, [isAuthenticated]);

  // useEffect( () => {
  //   const fetchData = async () => {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       await axios.get( `/oauth/users/getnewdata`, 
  //       { withCredentials: true, 
  //         credentials: 'include', 
  //         headers: {
  //           'Authorization' : `Bearer ${token}`
  //       }})
  //       .then((response) =>{
  //         console.log("userDetails", response.data)

  //         AppState.setisLoggedIn({
  //           ...AppState.LoggedIn,     
  //           userID : response.data._id,
  //           userName: response.data.username,
  //           userEmail: response.data.useremail,
  //           AccessControl: response.data.userRole,
  //           Downloads: response.data.DownloadsTotal,
  //           LikedBeats: response.data.LikedTotal,
  //         })
  //         console.log("iasdasdadssslo",response)
  //       })
  //       .catch((err) => {
  //         console.log("somethingwentwrong",err)
  //       })

  //       // const previouslink = await handleRedirectCallback()
  //       // console.log("callbackReg" , previouslink)
  //       // if (previouslink.target !== undefined) {
  //       //   Navigate(previouslink.target)
  //       // }
  //     }
  //     else{
  //       return
  //     }
  //   } 
  //   fetchData()
  // }, [isAuthenticated])
  //Display State Function//
  if(AppState.InitialLoad) {
    return(
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
      <section className='containerMain'>
          {/* <div className='Ldr' >
          <div id='MainLoader' className='LoaderInternal'>
          <Lottie 
style={{ height: 150 , width: 150, marginTop: "3px"}}
  options={{
    loop: true, 
    path: 'https://assets6.lottiefiles.com/packages/lf20_Z2LLb9.json',
  }}/>
  {isLoading ? <h6>Logging You In</h6> : <h6>Launching Web Player</h6>}
          </div>
          </div> */}
      </section>
      </SnackbarProvider>
      </ThemeProvider>
    )
  }
  else{
    return(
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
  <GoogleAnalytics trackingId="AW-11371796458" />
      <section className='containerMain'>
      <Snackbar open={AppState.notif !== null} autoHideDuration={3000} onClose={() => AppState.setNotification(null)}anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchor origin
  transformOrigin={{ vertical: 'top', horizontal: 'center' }} // Set transform origin
>
        <MuiAlert
        anchorOrigin={{  vertical: 'top',
        horizontal: 'center'}}
          elevation={6}
          variant="filled"
          onClose={() => AppState.setNotification(null)}
          severity={AppState.notif ? AppState.notif.type : 'info'}
        >
          {AppState.notif ? AppState.notif.message : ''}
        </MuiAlert>
      </Snackbar>
          <AppInterface/>

      </section>
      </SnackbarProvider>
      </ThemeProvider>
    )
  }
  

  //Internal Functions
      //Load Beats
//       async function loadbeats() {
//         await axios.get(`/apipath/beatlist/find`)
//         .then ((response) => {
//           console.log("bt good")
//           const databeatListtemp =response.data;
//           databeatListtemp.reverse();
//           AppContent.setbeatList(databeatListtemp)
//           AppState.setbeatlist(databeatListtemp)
//           })
//         .catch((error) => {
//         });
//         console.log(process.env)
//         await axios.get(`/apipath/beatlist/findplaylists`)
//         .then ((response) => {
//           console.log(process.env)
//             const statplaylisttemp = response.data;
//             AppContent.setplaylist(statplaylisttemp);
//             AppState.setplaylist(statplaylisttemp);
//             AppState.setInitializeMain(false)
//         })
//         .catch((error) => {
//         });   
// };

// async function loadbeats() {
//   try {
//     const beatResponse = await axios.get('/apipath/beatlist/find');
//     const databeatListtemp = beatResponse.data;
//     databeatListtemp.reverse();
//     AppContent.setBeatList(databeatListtemp);
//     AppState.setbeatlist(databeatListtemp)
//   } catch (error) {
//     console.error('Error fetching beat data', error);
//     AppContent.setError(error);
//   }

//   try {
//     const playlistResponse = await axios.get('/apipath/beatlist/findplaylists');
//     const statplaylisttemp = playlistResponse.data;
//     AppContent.setPlaylist(statplaylisttemp);
//     AppState.setplaylist(statplaylisttemp);
//   } catch (error) {
//     console.error('Error fetching playlist data', error);
//     AppContent.setError(error);
//   }

//   try {
//     const planResponse = await axios.get('/apipath/beatlist/findplans');
//     const planResponsetemp = planResponse.data;
//     AppState.SetAllPlans(planResponsetemp);
//     AppState.setInitializeMain(false);
//   } catch (error) {
//     console.error('Error fetching Plans data', error);
//     AppContent.setError(error);
//   }

// }

async function fetchDataWithRetry(url, maxRetries = 3) {
  for (let i = 0; i < maxRetries; i++) {
      try {
          const response = await axios.get(url);
          return response.data;
      } catch (error) {
          console.error(`Attempt ${i + 1} failed for ${url}`);
          if (i === maxRetries - 1) {
              throw error;
          }
      }
  }
}

async function loadbeats() {
  const results = await Promise.allSettled([
      fetchDataWithRetry('/apipath/beatlist/find'),
      fetchDataWithRetry('/apipath/beatlist/findplaylists'),
      fetchDataWithRetry('/apipath/beatlist/findplans')
  ]);

  let allSuccess = true;

  results.forEach((result, index) => {
      if (result.status === 'rejected') {
          allSuccess = false;
          console.error(`Error fetching data for request ${index + 1}`, result.reason);
          AppContent.setError(result.reason);
          return;
      }

      if (index === 0) {
          const beatData = result.value;
          beatData.reverse();
          AppContent.setBeatList(beatData);
          AppState.setbeatlist(beatData);
      } else if (index === 1) {
          const playlistData = result.value;
          AppContent.setPlaylist(playlistData);
          AppState.setplaylist(playlistData);
      } else if (index === 2) {
          const planData = result.value;
          console.log(planData , "plans")
          AppState.setAllPlans(planData);
      }
  });

  if (allSuccess) {
      AppState.setInitializeMain(false);
  }
}

}


