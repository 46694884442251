
export default function ErrorHandle404() {
    return(
        <>
        <div className="h-[80vh] flex justify-center items-center">
            <div>
            <h5 className="text-center text-sm">The page you're trying to visit does not exist</h5>
            <h1 className="text-7xl text-center font-bold p-8">404</h1>
            <h3 className=" text-center font-bold">PAGE NOT FOUND</h3>
            </div>
        </div>
        </>
    )
}