import React, { useEffect } from 'react';

const GoogleAnalytics = ({ trackingId }) => {
  useEffect(() => {
    // Initialize the Google Analytics tag (gtag)
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(scriptTag);

    // Configure the gtag function
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', trackingId);

    // Remove scriptTag on cleanup
    return () => {
      window.gtag = null;
      document.head.removeChild(scriptTag);
    };
  }, [trackingId]);

  return null; // This component does not render anything
};

export default GoogleAnalytics;