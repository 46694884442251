import React, {useEffect, useState, useContext, useRef} from 'react'
import { AppContext } from '../../utils/stateManagement/appContext';
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Lottie from 'react-lottie';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import download from 'downloadjs';
import { useFormControlUnstyledContext } from '@mui/base';

function SingleDownload() {
    const AppState = useContext(AppContext)
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      const beatID = searchParams.get("beatID");
      const transactionID = searchParams.get("transactionID");
      const TrackingID = transactionID
      const {loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
      const [status, setstatus] = useState("Loading")
      const [downloadUrl, setdownloadUrl] = useState("")
      const [downloadFilename, setdownloadFilename] = useState("")
  
      useEffect(() => {
            (async () => {
              console.log("is details found?" + transactionID && beatID)
                if(transactionID && beatID) {
                    const token = await getAccessTokenSilently()
                    axios.post( `/requesthandler/download/getsignedlink`, {
                      TrackingID : transactionID,
                      beatID : beatID,
                      }, { withCredentials: true, 
                        credentials: 'include', 
                        headers: {
                          'Authorization' : `Bearer ${token}`
                      }})
                    .then(async (signed) => {
                      setdownloadUrl(signed.data.url)
                      setdownloadFilename(signed.data.filename)
                      setstatus("Processed")
                      AppState.updateUserFunc()
                    })
                    .catch((err) => {
                      console.log(err)
                      setstatus("Failed")
                      // setTimeout(() => {
                      //   Navigate("/downloads")
                      // }, 3000)
                    })
                }
                else{
                    return
                }
              })();
            
              return () => {
                
                // this now gets called when the component unmounts
              };
        }, [transactionID , beatID])

        if(status === "Loading") {
            return(
              <div className='flex justify-center mt-10 h-[70vh] items-center'>
                Processing Request.... Please Wait
              </div>
            )
          }
          else if (status === "Processed") {
            return(
              <div>
              <div className='flex flex-col justify-center mt-10 h-[70vh] items-center'>
                <span className='pb-10 text-xl font-bold text-center'>Request Sucessfully Processed. Please download the file below</span> <a href={downloadUrl} target='_blank'>
                    <Button variant='contained'>
                        Download "{downloadFilename}"
                    </Button>
                </a>
              </div>
              </div>
            )
          }
          else {
            return(
              <div>
                Something Went Wrong, Please try again Later.
              </div>
            )
          }
}

export default withAuthenticationRequired(SingleDownload, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Please Log In to use this link - Redirecting....</div>,
  });
