import React, { useContext, useEffect, useState} from "react" 
import { AppContext } from "../../utils/stateManagement/appContext"
import mainstyles from "./mainstyle.module.scss"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useNavigate, Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Listbeat from "../../utils/BeatList/ListBeat";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/material/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useRef } from "react";
import TagIcon from '@mui/icons-material/Tag';
import {HiHashtag} from "react-icons/hi"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useAuth0 } from '@auth0/auth0-react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import { useContent } from "../../utils/stateManagement/contentContext";
import { gsap } from 'gsap';


export default function Home() {
    const AppState = useContext(AppContext)
    const AppContent = useContent()
    const Navigate = useNavigate()
    // const [isLoading, setisLoading] = useState(true)
    const [beatlist, setbeatlist] = useState([])
    const [likeload, setlikeload] = useState("")
    const [cachedData, setCachedData] = useState(null);
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
  // State to keep track of the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);
    const APILINK = process.env.REACT_APP_APIDOMAIN
    const vdref = useRef(null);
    const plref = useRef(null);
    const ColorButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText(grey[800]),
      backgroundColor: grey[800],
      '&:hover': {
        backgroundColor: grey[900],
      },
    }));

    useEffect(() => {
      if (AppContent.isPlaylistLoaded) {
        console.log("playlist loaded",)
      }
      else{
        console.log("not loaded")
      }
    }, [AppContent.isPlaylistLoaded])


    // useEffect(() => {
    //   const cached = JSON.parse(localStorage.getItem('cachedImages'));
    //   if (cached) {
    //     setCachedData(cached);
    //   } else {
    //     const newCacheData = AppContent.playlist.map((item) => {
    //       console.log("collectplItems", item)
    //       const uniqueRandomIndices = [];
    //       while (uniqueRandomIndices.length < 4) {
    //         const randIndex = Math.floor(Math.random() * item.selected.length);
    //         if (!uniqueRandomIndices.includes(randIndex)) {
    //           uniqueRandomIndices.push(randIndex);
    //         }
    //       }
    //       return { ...item, uniqueRandomIndices };
    //     });
    //     localStorage.setItem('cachedImages', JSON.stringify(newCacheData));
    //     setCachedData(newCacheData);
    //   }
    // }, []);

  //   function PlaylistMap() {
  //     if (!AppContent.isPlaylistLoaded) {
  //       return(
  //         <>
  //           <Stack   
  //           direction="row"
  // justifyContent="space-between">
  //           <Skeleton variant="rectangular" width={250} height={250} />
  //      <Skeleton variant="rectangular" width={250} height={250} />
  //      <Skeleton variant="rectangular" width={250} height={250} />
  //      <Skeleton variant="rectangular" width={250} height={250} />
  //      <Skeleton variant="rectangular" width={250} height={250} />
  //           </Stack>
  //       </>
  //       )
  //     }
  //     else{
  //       return(
  //         <ImageList
  //         sx={{
  //           marginBottom: "10px",
  //           gridAutoFlow: "column",
  //           gridTemplateColumns: "repeat(auto-fit, minmax(250px,260px)) !important",
  //           gridAutoColumns: "minmax(250px, 260px)",
  //           gridColumnGap: "20px",
  //           gap: "20px !important",
  //         }}
  //         className={mainstyles.scrollablesection}
  //         ref={plref}
  //       >
  //         {cachedData && cachedData.map((item, PLID) => (
  //           <React.Fragment key={PLID}>
  //             <Link to={`/playlist/${item.id}`} style={{ textDecoration: "none" }}>
  //               <div className="plElement">
  //                 <div className="plImg">
  //                   {item.uniqueRandomIndices.map((randIndex, index) => (
  //                     <LazyLoadImage
  //                       key={index}
  //                       src={item.selected[randIndex].beatimg}
  //                       width={125}
  //                       height={125}
  //                       alt={`Beat Image - ${item.Name}`}
  //                       className={`plImg${index + 1}`}
  //                       PlaceholderSrc="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Placeholder+Image+(2).png"
  //                     />
  //                   ))}
  //                 </div>
  //                 <h2>{item.Name}</h2>
  //                 <h3>{item.selected.length} Beats</h3>
  //               </div>
  //             </Link>
  //             </React.Fragment>
  //         ))}
  //       </ImageList>
  //       )
  //     }
  //   }
    const ogUrl = window.location.href;
    return(
      
        <>
<Helmet>
  <title>Home | Spacey Music</title>
  <meta name="description" content="Discover and lease high-quality beats from producer SpaceNTime (@spacey6) on SpaceyMusic. Join our subscription-based platform and access a wide range of genre-specific beats for your music projects." />
  <link rel="canonical" href="https://open.spaceymusic.com" />
  <meta property="og:title" content="SpaceyMusic - High-Quality Beat Leasing Platform by SpaceNTime (@spacey6)" />
  <meta property="og:description" content="SpaceyMusic is a subscription-based beat leasing platform created by SpaceNTime (@spacey6). Discover and access high-quality beats for your projects. Join SpaceyMusic today and elevate your music production." />
  <meta property="og:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <meta property="og:url" content="https://www.spaceymusic.com" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="SpaceyMusic - Subscription-Based Beat Leasing Platform" />
  <meta name="twitter:description" content="SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects. Join now and elevate your music production game with SpaceyMusic!" />
  <meta name="twitter:image" content="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png" />
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "SpaceyMusic",
        "url": "https://www.spaceymusic.com",
        "logo": "https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Spaceymusic+Logo+Draft+Round+-+White.png",
        "description": "SpaceyMusic is a subscription-based beat leasing platform created by producer SpaceNTime (@spacey6). Discover high-quality beats, lease licenses, and fuel your creative projects.",
        "sameAs": [
          "https://twitter.com/SpaceNTime6",
          "https://www.instagram.com/spacey6",
        ]
      }
    `}
  </script>
</Helmet>
      <div className="sliderWrap">
       <CTASlider/>
      </div>
      <PlaylistMap isPlaylistLoaded={AppContent.isPlaylistLoaded} cachedData={cachedData} />
              {/* <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent='space-around' spacing={2}>
          {AppState.PlaylistContext.map((Playlist, PLID) => (
            <Grid key={PLID} item>
              <div className={mainstyles.PlaylistWrap}
              style={{ backgroundImage: `url(${Playlist.Img})`,}}
                 onClick={() => { 
                     const PlaylistURL = "/playlist?" + Playlist._id + "=" + PLID 
                     Navigate(PlaylistURL) 
                     }}
              >
                  <div className={mainstyles.PlaylistWrapperInt}>
                  <div className={mainstyles.PlaylistWrapperIntTitle}>
                  <Stack direction="row" spacing={2}>
                  <Avatar
        alt={Playlist.Name}
        src={Playlist.Img}
        sx={{ width: 40, height: 40 }}
        variant="rounded"
      />
      <div>
      <Typography noWrap variant="body2" gutterBottom component="div">
                    {Playlist.Name}
                    </Typography>
                    <p><span><AudiotrackIcon/></span>{Object.keys(Playlist.Content).length} Beats</p>
      </div>
                  </Stack>
                  </div>
                  <div className={mainstyles.PlaylistPlayicon}>
                      <PlayCircleIcon/>
                  </div>
                  </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
</Grid> */}
<section>
<Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
  height="70px"
  marginTop="20px"
  marginBottom="20px"
  marginLeft="10px"
  marginRight="10px"
>
            <Typography mt={1} variant="h5" style={{fontWeight : 700}} gutterBottom component="div">
             {AppState.LoggedIn.OfferValid ? "Beats Available for free download" : "Suggested New Tracks" } 
            </Typography>
</Stack>
            <ListBeatHome/>
</section>
{/* <section>
<Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
  height="70px"
  marginBottom="20px"
  marginTop="20px"
  marginLeft="10px"
  marginRight="10px"
>
            <Typography mt={1} variant="subtitle1" style={{fontWeight : 700}} gutterBottom component="div">
              Videos
            </Typography>
            <Stack direction="row" spacing={1} >
            <ColorButton variant="contained" onClick={() => scrollVid(-350)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
            <NavigateBeforeIcon/>
            </ColorButton>
            <ColorButton variant="contained" onClick={() => scrollVid(350)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
            <NavigateNextIcon/>
            </ColorButton>
            <ColorButton variant="contained" onClick={() => Navigate("/featured")} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", }} size="small">
            VIEW ALL
            </ColorButton>
            </Stack>
</Stack>
<ImageList       sx={{
                marginBottom: "50px",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(auto-fit, minmax(360px,360px)) !important",
        gridAutoColumns: "minmax(350px, 360px)",
        gridColumnGap: "20px"

      }}
      ref={vdref}
      className={mainstyles.scrollablesection}
      >
      {AppState.featuredVids.map((item, PLID) => (
        <>
        <Paper elevation={0} className={mainstyles.VideoIem} key={PLID} onClick={() => {window.open("https://www.youtube.com/watch?v=" + item.Youtube_ID );}}>
        <img src={"https://img.youtube.com/vi/"+ item.Youtube_ID +"/hqdefault.jpg"} alt={item.Youtube_ID} />
        <Typography noWrap variant="h6" style={{fontSize : "16px"}} gutterBottom component="div">
        {item.Name}
        </Typography>
        <Typography noWrap variant="subtitle1" style={{fontSize : "14px"}} gutterBottom component="div">
        {item.views} Views
        </Typography>
        </Paper>
        </>
      ))}
      </ImageList>
</section> */}
        </>
    )

    function CTASlider() {
      const { loginWithRedirect, isAuthenticated } = useAuth0();
    
      const slides = [
        {
          key: 'slide3',
          className: 'slide3',
          content: (
            <div className="slide-content">
              <div className="slide-inner-content">
                <h3>Join the Waitlist for SpaceyMusic Producers</h3>
                <p>Showcase and sell your beats on Spacey Music.</p>
                <a
                  href="https://forms.gle/gu1Mzmi9aLKnEX658"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                  >
                    Join Now!
                  </Button>
                </a>
              </div>
            </div>
          ),
          video: 'https://videos.pexels.com/video-files/7087627/7087627-uhd_2732_1440_25fps.mp4'
        },
        {
          key: 'slide2',
          className: 'slide2',
          content: (
            <div className="slide-content">
              <div className="slide-inner-content">
                <h3>Claim Your Free Beat</h3>
                <p>
                  Register now and get a free beat to kickstart your musical journey.
                </p>
                {!isAuthenticated && (
                  <Button
                    onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                    sx={{ marginTop: '10px' }}
                    size="small"
                    variant="contained"
                    fullWidth
                  >
                    Sign Up
                  </Button>
                )}
              </div>
            </div>
          ),
          video: 'https://videos.pexels.com/video-files/7955111/7955111-uhd_2732_1440_25fps.mp4'
        },
        {
          key: 'slide1',
          className: 'slide1',
          content: (
            <div className="slide-content">
              <div className="slide-inner-content">
                <h3>Get Our Free Ebook</h3>
                <p>
                  Insights from industry veterans, crucial tips for independent artists.
                </p>
                <a
                  href="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/community/How+To+Get+Clout+From+Your+First+Song+by+%40spacey6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ marginTop: '10px' }}
                  >
                    Download
                  </Button>
                </a>
              </div>
            </div>
          ),
          video: 'https://videos.pexels.com/video-files/7055339/7055339-uhd_2560_1440_30fps.mp4'
        },
      ];
    
      const [currentSlide, setCurrentSlide] = useState(0);
      const slideRefs = useRef([]);
    
      useEffect(() => {
        slideRefs.current.forEach((slide, index) => {
          if (slide) {
            gsap.set(slide, { autoAlpha: index === currentSlide ? 1 : 0 });
          }
        });
      }, [currentSlide]);
    
      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentSlide(prevSlide => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
        }, 4000);
    
        return () => clearInterval(interval);
      }, [slides.length]);
    
      const goLeft = () => {
        const nextSlide = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
        setCurrentSlide(nextSlide);
      };
    
      const goRight = () => {
        const nextSlide = currentSlide === slides.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(nextSlide);
      };
    
      return (
        <div className="cta-slider">
          <button className="slider-control slider-control-left" onClick={goLeft}>
            &lt;
          </button>
          <div className="slide-container">
            {slides.map((slide, index) => (
              <div
                key={slide.key}
                ref={el => (slideRefs.current[index] = el)}
                className={`slide ${slide.className} ${index === currentSlide ? 'active' : ''}`}
              >
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="background-video"
                >
                  <source
                    src={slide.video}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="overlay"></div>
                {slide.content}
              </div>
            ))}
          </div>
          <button className="slider-control slider-control-right" onClick={goRight}>
            &gt;
          </button>
        </div>
      );
    }
    
}


function ListBeatHome() {
  const [isLoading1, setisLoading] = useState(true)
  const [page1, setpage1] = useState(0) 
  const [beatlist, setbeatlist] = useState([])
  const [likeload, setlikeload] = useState("")
  const AppState = useContext(AppContext)
  const Navigate = useNavigate()
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
  const [anchorEl, setAnchorEl] = useState({
    EL : null,
    element : null
  });
  const openselector = Boolean(anchorEl);
  const handleselectorClick = (event, value) => {
    setAnchorEl({
      EL : value,
      element : event.currentTarget
    })
  };
  const handleselectorClose = () => {
    setAnchorEl({
      EL : null,
      element : null
    })
    console.log(anchorEl)
  };


  const TagButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    textTransform : "none",
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[800],
    },
  }));

  const getShuffledArr = arr => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
};
function changeColor(coll, color){
  for(var i=0, len=coll.length; i<len; i++)
  {
      coll[i].style["background-color"] = color
  }
      
  }
  useEffect(() => {
    var aColl = document.getElementsByClassName('MainContent');
    changeColor(aColl, "#171717")
  },[])
  useEffect(() => {
    if(AppState.beatContext.length === 0) {
      return
    }else{
      const slicedArray = getShuffledArr(AppState.beatContext);
      const perChunk = 10
      const result = slicedArray.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)
      
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)
      
        return resultArray
      }, [])
      console.log("asdasdasdasdasdsdas",result[0])
      setbeatlist(result)
    }
  }, [AppState.beatContext])

  useEffect(() => {
    console.log("asdasdasdasdasdsdas",beatlist[0])
    if (beatlist.length === 0){
      return
    }else{
      setisLoading(false)
    }
  }, [beatlist])

  if(isLoading1) {
    return(
      <>
        Loading
      </>
    )
  }
  else{
    if(AppState.LoggedIn.OfferValid) {
      // Assuming AppState.beatContext is an array of objects
const validOffers = AppState.beatContext.filter(beat => beat.offerValid === true);

      return(
        <>
        <Listbeat data={validOffers}/></>
      )
    }
    else {
      return(
        <>
          <Listbeat data={beatlist[page1]}/>
          <Stack 
              sx={{marginTop : "20px", marginBottom : "100px"}}
                direction="row"
    justifyContent="flex-end"
    alignItems="center"
    spacing={2}>
    <Pagination siblingCount={1} count={beatlist.length} onChange={(event, value) => {setpage1(value - 1)}} variant="outlined" color="secondary" shape="rounded"  size="small"/>
      </Stack>
        </>
      )
    }

  }

  function LikedSort( children ) {
if (AppState.LoggedIn.loggedIn === true) {
  if(AppState.LoggedIn.LikedBeats === undefined) {
    return(
      <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
      <FavoriteBorderIcon />
      </IconButton>
      )
  }
  else{
    let obj = AppState.LoggedIn.LikedBeats.find(o => o.beatID === children.children);
    if(obj) {
      return(
      <IconButton color="success" aria-label="delete">
      <FavoriteIcon />
      </IconButton>
      )
    }else{
      return(
      <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
      <FavoriteBorderIcon />
      </IconButton>
      )
    }
  }

}
else{
  return(
    <IconButton color="success" onClick={() => addLikedBeat(children.children)} aria-label="delete">
    <FavoriteBorderIcon />
    </IconButton>
  )
}

  }
  function processDownload(Val) {
      Navigate("/download?" + Val);
  }

  async function addLikedBeat(data) {
    if(AppState.LoggedIn.loggedIn === true) {
      setlikeload(data)
      await axios.post( `/oauth/users/likedbeat`, {
          beatID : data,
          }, {withCredentials: true, credentials: 'include'})
          .then((response) => {
            console.log(response)
            AppState.setisLoggedIn({...AppState.LoggedIn, LikedBeats: response.data.updatedData})
              const storagetech = localStorage.getItem("sp_user_init");
              const DecodeTech = JSON.parse(storagetech);
              var userdt = {'isLoggedin': true, 'userID' : DecodeTech.userID, 'userName': DecodeTech.userName, 'AccessControl': DecodeTech.AccessControl, 'LikedBeats': response.data.updatedData, "Downloads": DecodeTech.DownloadBeats, "OfferValid" : DecodeTech.OfferValid, "isVerified" : DecodeTech.isVerified};
              localStorage.setItem("sp_user_init", JSON.stringify(userdt));
              setlikeload("")
              AppState.updateUserFunc().then((docs) => {
                console.log("done")
              })
              .catch((err) => {
                console.log("done")
              })
          })
          .catch((err) => {
            console.log(err)
          })
  
    }
    else{
      Navigate("/login")
  }
    };


}

function PlaylistMap({ isPlaylistLoaded, cachedData }) {
     const ColorButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText(grey[800]),
      backgroundColor: grey[800],
      '&:hover': {
        backgroundColor: grey[900],
      },
    }));

  const AppContent = useContent()
  const plref = useRef(null);
  const scroll = (scrollOffset) => {
    gsap.to(plref.current, {
      scrollLeft: plref.current.scrollLeft + scrollOffset,
      duration: 0.5, // Adjust the duration as needed
      ease: 'power2.inOut', // Use the ease function of your choice
    });
  };

  if (!isPlaylistLoaded) {
    return (
      <>
                 <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
  height="70px"
  marginBottom="20px"
  marginLeft="10px"
  marginRight="10px"
>
            <Typography mt={1} variant="h5" style={{fontWeight : 700}} gutterBottom component="div">
              Playlists For You
            </Typography>
            <Stack direction="row" spacing={1} >
            <ColorButton variant="contained" onClick={() => scroll(-200)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
            <NavigateBeforeIcon/>
            </ColorButton>
            <ColorButton variant="contained" onClick={() => scroll(200)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
            <NavigateNextIcon/>
            </ColorButton>
            </Stack>
</Stack>
        <Stack direction="row" justifyContent="space-between">
          <Skeleton variant="rectangular" width={250} height={250} />
          <Skeleton variant="rectangular" width={250} height={250} />
          <Skeleton variant="rectangular" width={250} height={250} />
          <Skeleton variant="rectangular" width={250} height={250} />
          <Skeleton variant="rectangular" width={250} height={250} />
        </Stack>
      </>
    );
  } else {
    return (
<>
<Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      height="70px"
      marginBottom="20px"
      marginLeft="10px"
      marginRight="10px"
    >
                <Typography mt={1} variant="h5" style={{fontWeight : 700}} gutterBottom component="div">
                  Playlists For You
                </Typography>
                <Stack direction="row" spacing={1} >
                <ColorButton variant="contained" onClick={() => scroll(-200)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
                <NavigateBeforeIcon/>
                </ColorButton>
                <ColorButton variant="contained" onClick={() => scroll(200)} style={{ textTransform: "none", padding: "2px 0px", fontSize : "10px", minWidth: "30px",}} size="small">
                <NavigateNextIcon/>
                </ColorButton>
                </Stack>
    </Stack>
      <ImageList
        sx={{
          marginBottom: "10px",
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px,260px)) !important",
          gridAutoColumns: "minmax(250px, 260px)",
          gridColumnGap: "20px",
          gap: "20px !important",
        }}
        ref={plref}
        className={mainstyles.scrollablesection}
      >
        {AppContent.cachedPlaylistData && AppContent.cachedPlaylistData.map((item, PLID) => (
          <React.Fragment key={PLID}>
            <Link to={`/playlist/${item.id}`} style={{ textDecoration: "none" }}>
              <div className="plElement">
                <div className="plImg">
                  {item.uniqueRandomIndices.map((randIndex, index) => (
                    <LazyLoadImage
                      key={index}
                      src={item.selected[randIndex].beatimg}
                      width={125}
                      height={125}
                      alt={`Beat Image - ${item.Name}`}
                      className={`plImg${index + 1}`}
                      PlaceholderSrc="https://spacetrumentals-resources.s3.us-west-1.amazonaws.com/Placeholder+Image+(2).png"
                    />
                  ))}
                </div>
                <Typography noWrap variant="h6" className="!mt-2" gutterBottom component="div">{item.Name}</Typography>
                <Typography noWrap variant="body1" className="text-gray-500" gutterBottom component="div">{item.selected.length} Beats</Typography>
                {/* <h2></h2> */}
              </div>
            </Link>
          </React.Fragment>
        ))}
      </ImageList></>
    );
  }
}

// Memoize the PlaylistMap component
